import React, {useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {
    ArrowDownCircleIcon,
    ArrowUpCircleIcon,
    ExclamationTriangleIcon,
    DocumentDuplicateIcon,
    EllipsisHorizontalCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    ArrowPathIcon,
    ClipboardDocumentListIcon,
    BuildingOffice2Icon,
    ClipboardDocumentCheckIcon, DocumentCheckIcon, ClockIcon
} from "@heroicons/react/24/outline";
import {Pie} from "react-chartjs-2";
import NutrientCard from "./NutrientCard";
import {
    Button, Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle, Input,
    Popover,
    PopoverButton,
    PopoverPanel
} from "@headlessui/react";
import {db} from "../../firebase/firebase";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import {useAuth} from "../../contexts/authContext";
import {convertUnit} from "../GlobalFunctions";

import logo from "../../IconBare.png"

import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from '@headlessui/react'
import {
    CalendarDaysIcon,
    FaceFrownIcon,
    FaceSmileIcon,
    FireIcon,
    HandThumbUpIcon,
    HeartIcon,
    PaperClipIcon,
    XMarkIcon as XMarkIconMini,
} from '@heroicons/react/20/solid'
import UsageCard from "./UsageCard";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



const ChemCard = ({chem, onDelete, onDuplicate, onEdit, onSuccess, userName}) => {
    const [expanded, setExpanded] = useState(false); // State to track expansion

    const { currentUser } = useAuth();

    const [metric] = useState(() => {
        const storedSettings = localStorage.getItem("metric");
        return storedSettings ? JSON.parse(storedSettings).metric : false;
    });

    const [chemQuantity, setChemQuantity] = useState(chem.quantity);
    const [chemVolume, setChemVolume] = useState(chem.volume);
    const [chemExcess, setChemExcess] = useState(chem.excess ?? 0); // Ensure it's at least 0

    const volume = ((chem.quantity * chem.volume) + (chem.excess ?? 0)).toFixed(2);
    const newVolume = ((Number(chemQuantity) * Number(chemVolume)) + Number(chemExcess)).toFixed(2);

    const price = (volume * chem.unitprice).toFixed(2);
    const adjustedExcess = (chem.excess * 1).toFixed(2);
    const adjustedVolume = (chem.volume * 1).toFixed(2);
    const adjustedPrice = (chem.unitprice * 1).toFixed(2);

    const rate = chem.rate;
    const nutrients = chem.nutrients;

    const { darkMode } = useDarkMode();

    const Rate = (rate * 1).toFixed(2);
    const galRate = (((rate / 128) / 1000) * 43560).toFixed(2);
    const mlRate = (((rate * 29.5735) / 1000) * 1076.39).toFixed(2);
    const lRate = (((rate / 33.814) / 1000) * 107639).toFixed(2);

    const Weight = (chem.weight * 1).toFixed(2);
    const containerWeight = (chem.weight * chem.volume).toFixed(2);
    const totalWeight = (volume * chem.weight).toFixed(2);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDuplicationConfirmation, setShowDuplicationConfirmation] = useState(false);

    const [update, setUpdate] = useState(false);


    const toggleUpdate = () => {
        setUpdate(!update)
    }
    // Your existing code...

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const toggleDuplicationConfirmation = () => {
        setShowDuplicationConfirmation(!showDuplicationConfirmation);
    };

    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        onDelete(chem.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };

    const handleDuplicate = () => {
        // Call the onDelete function passed from the parent component
        onDuplicate(chem.id);
        // Close the delete confirmation popup
        setShowDuplicationConfirmation(false);
    };

    const nutrientChart = {
        labels: nutrients.map(nutrient => nutrient.name),
        datasets: [
            {
                label: '%',
                data: nutrients.map(nutrient => nutrient.percentage),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderWidth: 1,
            },
        ],
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }




    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const totalContainerValue = chem.nutrients.reduce((accumulator, current) => {
        return accumulator + ((current.percentage / 100) * chem.volume * chem.weight);
    }, 0).toFixed(2);

    const totalNutrientValue = chem.nutrients.reduce((accumulator, current) => {
        return accumulator + ((current.percentage / 100) * volume * chem.weight);
    }, 0).toFixed(2);

    const resetValues = () => {
        setChemExcess(chem.excess);
        setChemVolume(chem.volume);
        setChemQuantity(chem.quantity);
    }

    const updateValues = async (chemId, onSuccess) => {

        if (!chemId) {
            console.error("No chemId provided!");
            return;
        }

        try {
            const chemRef = doc(db, "Users", currentUser.uid, "chems", chemId);  // Reference to the Firestore document

            // Create the update entry to push into the updates array
            const updateEntry = {
                timestamp: new Date().getTime().toString(),
                userName: userName,  // Use current user's display name
                type: "Update"  // Action type
            };

            // Update the document with new values and push to the updates array
            await updateDoc(chemRef, {
                excess: chemExcess,
                volume: chemVolume,
                quantity: chemQuantity,

                lastUp: new Date().getTime().toString(),

                // Push the update entry to the updates array
                updates: arrayUnion(updateEntry)
            });

            console.log("Updated excess, volume, and quantity, and pushed to updates array!");

            toggleUpdate();

            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    // const unitConversionMap = {
    //     gal: { metricUnit: "L", conversionRate: 3.78541 },
    //     oz: { metricUnit: "mL", conversionRate: 29.5735 },
    //     lbs: { metricUnit: "kg", conversionRate: 0.453592 },
    //     ft: { metricUnit: "m", conversionRate: 0.3048 },
    //     in: { metricUnit: "cm", conversionRate: 2.54 },
    //     yd: { metricUnit: "m", conversionRate: 0.9144 },
    //     mi: { metricUnit: "km", conversionRate: 1.60934 }
    // };
    //
    // const convertUnit = (value, unit, metric) => {
    //     if (metric && unitConversionMap[unit]) {
    //         const { metricUnit, conversionRate } = unitConversionMap[unit];
    //         return { value: (value * conversionRate).toFixed(2), unit: metricUnit };
    //     }
    //     return { value: parseFloat(value).toFixed(2), unit };
    // };
    //
    // const { value, unit: convertedUnit } = convertUnit(volume, chem.unit, metric);


    const invoice = {
        subTotal: '$8,800.00',
        tax: '$1,760.00',
        total: '$10,560.00',
        items: [
            {
                id: 1,
                title: 'Logo redesign',
                description: 'New logo and digital asset playbook.',
                hours: '20.0',
                rate: '$100.00',
                price: '$2,000.00',
            },

        ],
    }

    const moods = [
        { name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
        { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
        { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
        { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
        { name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
        { name: 'I feel nothing', value: null, icon: XMarkIconMini, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },
    ]

    const [selected, setSelected] = useState(moods[5])

    const formatTimestamp = (timestamp) => {
        const date = new Date(Number(timestamp));

        const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

        return `${formattedDate} at ${formattedTime}`;
    };

    return (
        <div className={`rounded-t-md p-3 relative border-b-2 ${darkMode ? "bg-bgDark border-neutral-700" : "bg-white"} overflow-hidden p-2 items-center justify-center shadow-gray-900 mb-2 w-full animate-cascade`}>


            <Dialog open={showDeleteConfirmation} onClose={setShowDeleteConfirmation} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-bgDark text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                                    <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold">
                                        Delete | {chem.name}
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to delete this chemical? All of its data will be permanently removed from
                                            our servers forever. This action cannot be undone.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={handleDelete}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setShowDeleteConfirmation(false)}
                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-bgpage' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>


            <Dialog open={showDuplicationConfirmation} onClose={setShowDuplicationConfirmation} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-bgDark text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <DocumentDuplicateIcon aria-hidden="true" className="size-6 text-green-600" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold">
                                        Duplicate | {chem.name}
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to duplicate this chemical? This is going to create an exact clone of the chemical in your inventory including all values.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={handleDuplicate}
                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                >
                                    Duplicate
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowDuplicationConfirmation(false)}
                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-bgpage' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>


            {/*Header*/}
            <div className="z-20 flex items-center justify-center">

                <div className="z-10 mr-2">
                    <Button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                        <TrashIcon className="w-5 text-red-700 hover:text-red-500 hover:animate-pulse"/>
                    </Button>
                </div>


                {/*Section 1*/}
                <div className={`z-10 ${darkMode ? " " : "drop-shadow"} bg-green-600/15 border-2 border-green-600 h-full rounded-lg flex flex-col items-center justify-center p-2 w-full shadow-gray-900 mr-5`}>
                    <div className="text-lg text-center line-clamp-1">{chem.name}</div>
                    <div className="text-xs text-gray-500 text-center line-clamp-1">{chem.manufacturer}</div>
                </div>


                {/*Section 2*/}
                <div className={`z-10 ${darkMode ? "bg-bgcard border border-neutral-700/50" : "bg-bgcolorLight drop-shadow border border-neutral-300"} h-full rounded-lg flex p-2 w-full shadow-gray-50 mr-5`}>

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Containers:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Volume Per:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>Excess:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className={`rounded-lg p-1.5 ${darkMode ? 'bg-bgDark' : 'border border-1 bg-white'}`}>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>{chem.quantity}</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>
                                {/*{adjustedVolume + " " + chem.unit}*/}
                                <span>
                                    {numberWithCommas(convertUnit(adjustedVolume, chem.unit, metric).value)}{" "}
                                    {convertUnit(adjustedVolume, chem.unit, metric).unit}
                                </span>
                            </div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>
                                {/*{adjustedExcess + " " + chem.unit}*/}
                                <span>
                                    {numberWithCommas(convertUnit(adjustedExcess, chem.unit, metric).value)}{" "}
                                    {convertUnit(adjustedExcess, chem.unit, metric).unit}
                                </span>
                            </div>
                        </div>

                    </div>

                </div>


                {/*Section 3*/}
                <div className={`z-10 ${darkMode ? "bg-bgcard border border-neutral-700/50" : "bg-bgcolorLight drop-shadow border border-neutral-300"} h-full rounded-lg flex p-2 w-full shadow-gray-900 mr-5`}>

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Classification:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Consistency:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>Unit Price:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className={`rounded-lg p-1.5 ${darkMode ? 'bg-bgDark' : 'bg-white border border-1'}`}>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>{chem.classification}</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>{chem.type}</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>${adjustedPrice}</div>
                        </div>

                    </div>

                </div>


                {/*Section 4*/}
                <div className="z-10 h-full rounded-lg flex items-center justify-center w-full">

                    <div className="flex flex-col items-center justify-center w-full">

                        <div className="text-lg text-green-600 text-center line-clamp-1">Total Volume:</div>
                        {/*<div className={`${darkMode ? "text-white" : "text-black"} text-md text-center mb-2 line-clamp-1`}>{numberWithCommas((metric ? (volume * 3.78541) : volume).toFixed(2)) + " " + chem.unit}</div>*/}
                        {/*<div className={`${darkMode ? "text-white" : "text-black"} text-md text-center mb-2 line-clamp-1`}>{numberWithCommas(value)} {convertedUnit}</div>*/}
                        <div className={`${darkMode ? "text-white" : "text-black"} text-md text-center mb-2 line-clamp-1`}>
                            <span>
                                {numberWithCommas(convertUnit(volume, chem.unit, metric).value)}{" "}
                                {convertUnit(volume, chem.unit, metric).unit}
                            </span>
                        </div>

                        <div className={`${darkMode ? " " : ""} rounded-lg border-2 px-4 py-0.5 border-solid border-green-600 bg-green-600/15 shadow-gray-900`}>
                            <div className="text-lg text-green-600 text-center line-clamp-1">Total Price:</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-md text-center line-clamp-1`}>{"$" + numberWithCommas(price)}</div>
                        </div>

                    </div>


                </div>


                {/*Edit and Expand*/}
                <div className="z-10 flex flex-col items-center relative">

                    <Popover className=" relative mb-8 flex">
                        <PopoverButton className="inline-flex items-center gap-x-1 text-sm/6 font-semibold hover:scale-110 hover:animate-heartbeat">
                            <EllipsisHorizontalCircleIcon aria-hidden="true" className={`size-6 hover:text-green-600 ${darkMode ? "text-white" : "text-gray-500"}`} />
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute z-10 pr-56 flex w-screen max-w-min -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className={`w-48 flex flex-col space-y-3 py-3 shrink rounded-xl border ${darkMode ? 'bg-bgDark drop-shadow-2xl border-neutral-700' : 'bg-white'} p-2 text-sm/6 font-semibold text-gray-900 shadow-lg ring-1 ring-gray-900/5`}>

                                <Button
                                    onClick={() => onEdit(chem)}
                                    className={`flex text-sm items-center ${darkMode ? "text-white" : "text-black"} px-2 hover:text-opacity-50`}
                                >
                                    <PencilSquareIcon className={`w-5 mr-2`}/>
                                    Edit Item
                                </Button>

                                <Button
                                    onClick={toggleUpdate}
                                    className={`flex text-sm items-center ${darkMode ? "text-white" : "text-black"} px-2 hover:text-opacity-50`}
                                >
                                    <ClipboardDocumentListIcon className={`w-5 mr-2`}/>
                                    Update Stock
                                </Button>

                                <Button
                                    onClick={toggleDuplicationConfirmation}
                                    className={`flex text-sm items-center ${darkMode ? "text-white" : "text-black"} px-2 hover:text-opacity-50`}
                                >
                                    <DocumentDuplicateIcon className={`w-5 mr-2`}/>
                                    Duplicate Item
                                </Button>

                            </div>
                        </PopoverPanel>
                    </Popover>

                    <Button className={` ${darkMode ? "text-white" : "text-gray-500"} text-xxl font-bold mt-auto mb-auto hover:text-green-600 hover:scale-110 hover:animate-heartbeat`}
                            onClick={toggleExpansion}>
                        {expanded ?
                            <ArrowUpCircleIcon className={`w-6`}/>
                            :
                            <ArrowDownCircleIcon className={`w-6`}/>
                        }
                    </Button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <div className={`p-2 animate-fadeIn`}>


                    <svg
                        aria-hidden="true"
                        className={`absolute inset-0 size-full ${darkMode ? 'stroke-white/10' : 'stroke-black/10'} [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]`}
                    >
                        <defs>
                            <pattern
                                x="50%"
                                y={-1}
                                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                                width={200}
                                height={200}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none"/>
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/0">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" width="100%" height="100%"
                              strokeWidth={0}/>
                    </svg>

                    <div
                        aria-hidden="true"
                        className="absolute inset-x-0 -top-40 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#488A4F] to-[#164A1B] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        />
                    </div>

                    <div
                        aria-hidden="true"
                        className="absolute -top-80 left-[max(6rem,33%)] transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)',
                            }}
                            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#488A4F] to-[#164A1B] opacity-30"
                        />
                    </div>


                    <main>

                        {/*Header*/}
                        <header className="relative isolate z-10">

                            <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 lg:px-8">
                                <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">

                                    {/*Logo*/}
                                    <div className="flex items-center gap-x-6">
                                        <div className={`size-16 hover:scale-105 bg-bgDark flex items-center justify-center rounded-full border ${darkMode ? 'border-neutral-700' : ''}`}>
                                            <img
                                                alt=""
                                                src={logo}
                                                className={`size-11`}
                                            />
                                        </div>
                                        <h1>
                                            <div className="text-sm/6 text-gray-500">
                                                Product History View
                                            </div>
                                            <div className="mt-1 text-base font-semibold">{chem.name}</div>
                                        </h1>
                                    </div>

                                    {/*Buttons*/}
                                    <div className="flex items-center gap-x-4 sm:gap-x-6">
                                        <Button onClick={toggleDuplicationConfirmation} type="button"
                                                className="hidden text-sm/6 font-semibold sm:block hover:text-green-600 hover:tracking-widest">
                                            Duplicate
                                        </Button>
                                        <Button onClick={() => onEdit(chem)} className="hidden text-sm/6 font-semibold sm:block hover:text-green-600 hover:tracking-widest">
                                            Edit
                                        </Button>
                                        <Button
                                            onClick={toggleUpdate}
                                            className="rounded-lg bg-green-600 hover:scale-105 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Update Stock
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </header>

                        <div className="mx-auto max-w-8xl px-4 py-4 sm:px-6 lg:px-8">
                            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">

                                {/* Inventory Value */}
                                <div className="z-10 lg:col-start-3 lg:row-end-1">
                                    <div className={`rounded-lg ${darkMode ? 'bg-bgcard' : 'bg-bgcolorLight'} shadow-sm ring-1 ring-gray-900/5`}>
                                        <dl className="flex flex-wrap">


                                            <div className="flex-auto pl-6 pt-6">
                                                <dt className="text-sm/6 font-semibold text-gray-500">Inventory Value</dt>
                                                <dd className="mt-1 text-base font-semibold flex items-center">
                                                    <span className={`text-green-600 text-sm mr-2`}>$</span>
                                                    {numberWithCommas(price)}
                                                    <span className={`text-gray-500 text-xs ml-2`}>USD</span>
                                                </dd>
                                            </div>
                                            <div className="flex-none self-end px-6 pt-4">
                                                <dd className="rounded-md bg-green-600/30 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                                                    In Stock
                                                </dd>
                                            </div>


                                            <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                                <dt className="flex-none">
                                                    <BuildingOffice2Icon aria-hidden="true" className="h-6 w-5 text-green-600"/>
                                                </dt>
                                                <dd className="text-sm/6 font-medium tracking-widest">{chem.manufacturer}</dd>
                                            </div>
                                            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                <dt className="flex-none">
                                                    <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-green-600"/>
                                                </dt>
                                                <dd className="text-sm/6 flex w-full text-gray-500">
                                                    <div>{chem.added ? formatTimestamp(chem.added) : "Mar 14, 2002"}</div>
                                                    <div className={`ml-auto`}>Added</div>
                                                </dd>
                                            </div>
                                            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                <dt className="flex-none">
                                                    <ClipboardDocumentCheckIcon aria-hidden="true" className="h-6 w-5 text-green-600"/>
                                                </dt>
                                                <dd className="text-sm/6 flex w-full text-gray-500">
                                                    <div>{chem.lastUp ? formatTimestamp(chem.lastUp) : "Mar 14, 2002"}</div>
                                                    <div className={`ml-auto`}>Last Updated</div>
                                                </dd>
                                            </div>
                                        </dl>
                                        <div className={`mt-6 border-t 0 ${darkMode ? 'border-neutral-700' : ''} px-6 py-6`}>
                                            <a href="#" className="text-sm/6 font-semibold flex items-center">
                                                <DocumentCheckIcon className={`size-5 stroke-2 mr-2 text-green-600`}/>
                                                Download Product Profile <span aria-hidden="true">&rarr;</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {/* Breakdown Analytics */}
                                <div className="z-10 lg:col-start-3 lg:row-end-2">
                                    <div className={`rounded-lg ${darkMode ? 'bg-bgcard' : 'bg-bgcolorLight'} shadow-sm ring-1 ring-gray-900/5 py-6 space-y-2`}>


                                        <dl className="flex flex-wrap ">


                                            <div className="flex-auto pl-6">
                                                <dt className="text-sm/6 font-semibold text-gray-500">Breakdown Analytics</dt>
                                                <dd className="mt-1 text-sm font-semibold flex items-center">
                                                    <span className={`text-green-600 text-sm mr-2`}>$</span>
                                                    {numberWithCommas(price)}
                                                    <span className={`text-gray-500 text-xs ml-2`}>USD</span>
                                                </dd>
                                            </div>


                                            <div className="flex-none self-end px-6 pt-4">
                                                <dd className="rounded-md bg-green-600/30 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                                                    per {chem.unit}
                                                </dd>
                                            </div>


                                        </dl>
                                        <dl className="flex flex-wrap items-center">

                                            <div className="flex-auto pl-6">
                                                <dd className="mt-1 text-sm font-semibold flex items-center">
                                                    <span className={`text-green-600 text-sm mr-2`}>$</span>
                                                    {numberWithCommas(price)}
                                                    <span className={`text-gray-500 text-xs ml-2`}>USD</span>
                                                </dd>
                                            </div>


                                            <div className="flex-none self-end px-6">
                                                <dd className="rounded-md bg-green-600/30 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                                                    per {chem.unit}
                                                </dd>
                                            </div>


                                        </dl>

                                    </div>
                                </div>

                                {/* Main */}
                                <div className={`z-10 -mx-4 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} px-4 py-8 shadow-sm sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-3 lg:row-end-3 xl:px-16 xl:pb-20 xl:pt-16`}>
                                    <h2 className="text-base font-semibold tracking-widest">Application Rate</h2>
                                    <div className={`mt-6 grid grid-cols-4 text-sm/6 divide-x-2 ${darkMode ? 'divide-neutral-700' : 'divide-neutral-300'}`}>

                                        <div className={`col-span-1 gap-x-3 px-3 py-1 text-center hover:-translate-y-2 ${darkMode ? 'hover:bg-bgcard hover:rounded-l-lg' : 'hover:bg-bgcolorLight'}`}>
                                            <div className={`text-xs tracking-widest`}>Oz/1000 sqft.</div>
                                            <div className={`text-xs bg-green-600/50 w-fit mx-auto px-2 mt-1.5 rounded-lg`}>{Rate} oz</div>
                                        </div>

                                        <div className={`col-span-1 gap-x-3 px-3 py-1 text-center hover:-translate-y-2 ${darkMode ? 'hover:bg-bgcard' : 'hover:bg-bgcolorLight'}`}>
                                            <div className={`text-xs tracking-widest`}>Gal/1 acre</div>
                                            <div className={`text-xs bg-green-600/50 w-fit mx-auto px-2 mt-1.5 rounded-lg`}>{galRate} gal</div>
                                        </div>

                                        <div className={`col-span-1 gap-x-3 px-3 py-1 text-center hover:-translate-y-2 ${darkMode ? 'hover:bg-bgcard' : 'hover:bg-bgcolorLight'}`}>
                                            <div className={`text-xs tracking-widest`}>Ml/100 sq. m.</div>
                                            <div className={`text-xs bg-green-600/50 w-fit mx-auto px-2 mt-1.5 rounded-lg`}>{mlRate} ml</div>
                                        </div>

                                        <div className={`col-span-1 gap-x-3 px-3 py-1 text-center hover:-translate-y-2 ${darkMode ? 'hover:bg-bgcard hover:rounded-r-lg' : 'hover:bg-bgcolorLight'}`}>
                                            <div className={`text-xs tracking-widest`}>L/HA</div>
                                            <div className={`text-xs bg-green-600/50 w-fit mx-auto px-2 mt-1.5 rounded-lg`}>{lRate} L</div>
                                        </div>
                                    </div>

                                    {/*Table*/}
                                    <table className="mt-16 w-full whitespace-nowrap text-left text-sm/6">
                                        <colgroup>
                                            <col className="w-full"/>
                                            <col/>
                                            <col/>
                                            <col/>
                                        </colgroup>
                                        <thead className="border-b border-gray-500 text-gray-500">
                                        <tr>
                                            <th scope="col" className="px-0 py-3 font-semibold">
                                                Projects
                                            </th>
                                            <th scope="col"
                                                className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                Hours
                                            </th>
                                            <th scope="col"
                                                className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                Rate
                                            </th>
                                            <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                                                Price
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {invoice.items.map((item) => (
                                            <tr key={item.id} className="border-b border-gray-500">
                                                <td className="max-w-0 px-0 py-5 align-top">
                                                    <div
                                                        className="truncate font-medium">{item.title}</div>
                                                    <div className="truncate text-gray-500">{item.description}</div>
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                                    {item.hours}
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                                    {item.rate}
                                                </td>
                                                <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">{item.price}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th scope="row"
                                                className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden">
                                                Subtotal
                                            </th>
                                            <th
                                                scope="row"
                                                colSpan={3}
                                                className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
                                            >
                                                Subtotal
                                            </th>
                                            <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums">{invoice.subTotal}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="pt-4 font-normal text-gray-700 sm:hidden">
                                                Tax
                                            </th>
                                            <th
                                                scope="row"
                                                colSpan={3}
                                                className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                                            >
                                                Tax
                                            </th>
                                            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums">{invoice.tax}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="pt-4 font-semibold text-gray-900 sm:hidden">
                                                Total
                                            </th>
                                            <th
                                                scope="row"
                                                colSpan={3}
                                                className="hidden pt-4 text-right font-semibold sm:table-cell"
                                            >
                                                Total
                                            </th>
                                            <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums">
                                                {invoice.total}
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>

                                </div>

                                {/* History */}
                                <div className={`z-10 p-4 rounded-lg lg:col-start-3 lg:row-end-3 ${darkMode ? 'bg-bgcard' : 'bg-bgcolorLight'}`}>
                                    {/* Activity feed */}
                                    <h2 className="text-sm/6 font-semibold text-gray-500">Product History</h2>
                                    <ul role="list" className="mt-6 space-y-6">

                                        {chem.updates && chem.updates.length > 0 ? (
                                            // Display UsageCard components if updates array exists and has items
                                            chem.updates
                                                .sort((a, b) => Number(b.timestamp) - Number(a.timestamp))  // Sort by most recent first
                                                .map((usage, index) => (
                                                    <UsageCard
                                                        key={index}
                                                        usage={usage}
                                                        index={index}
                                                        history={chem.updates}
                                                    />
                                                ))
                                        ) : (
                                            // Display custom dialog if updates array doesn't exist or is empty
                                            <div className="text-center flex justify-center items-center flex-col">
                                                <div className={`flex justify-center items-center flex-col border-2 px-8 py-2 rounded-xl border-dashed ${darkMode ? 'border-neutral-700' : ''}`}>
                                                    <ClockIcon className={`size-12 text-green-600`}/>
                                                    <h3 className="mt-2 text-sm font-semibold">No History</h3>
                                                </div>
                                            </div>
                                        )}


                                    </ul>

                                    {/* New comment form */}
                                    <div className="mt-6 flex gap-x-3">
                                        <img
                                            alt=""
                                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                            className="size-6 flex-none rounded-full bg-gray-50"
                                        />
                                        <form action="#" className="relative flex-auto">
                                            <div
                                                className="overflow-hidden rounded-lg pb-12 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
                                                <label htmlFor="comment" className="sr-only">
                                                Add your comment
                                                </label>
                                                <textarea
                                                    id="comment"
                                                    name="comment"
                                                    rows={2}
                                                    placeholder="Add your comment..."
                                                    className="block w-full resize-none bg-transparent px-3 py-1.5 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
                                                    defaultValue={''}
                                                />
                                            </div>

                                            <div
                                                className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                                                <div className="flex items-center space-x-5">
                                                    <div className="flex items-center">
                                                        <button
                                                            type="button"
                                                            className="-m-2.5 flex size-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PaperClipIcon aria-hidden="true" className="size-5"/>
                                                            <span className="sr-only">Attach a file</span>
                                                        </button>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <Listbox value={selected} onChange={setSelected}>
                                                            <Label className="sr-only">Your mood</Label>
                                                            <div className="relative">
                                                                <ListboxButton
                                                                    className="relative -m-2.5 flex size-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                              <span className="flex items-center justify-center">
                                {selected.value === null ? (
                                    <span>
                                    <FaceSmileIcon aria-hidden="true" className="size-5 shrink-0"/>
                                    <span className="sr-only">Add your mood</span>
                                  </span>
                                ) : (
                                    <span>
                                    <span
                                        className={classNames(
                                            selected.bgColor,
                                            'flex size-8 items-center justify-center rounded-full',
                                        )}
                                    >
                                      <selected.icon aria-hidden="true" className="size-5 shrink-0 text-white"/>
                                    </span>
                                    <span className="sr-only">{selected.name}</span>
                                  </span>
                                )}
                              </span>
                                                                </ListboxButton>

                                                                <ListboxOptions
                                                                    transition
                                                                    className="absolute bottom-10 z-10 -ml-6 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:ml-auto sm:w-64 sm:text-sm"
                                                                >
                                                                    {moods.map((mood) => (
                                                                        <ListboxOption
                                                                            key={mood.value}
                                                                            value={mood}
                                                                            className="relative cursor-default select-none bg-white px-3 py-2 data-[focus]:bg-gray-100"
                                                                        >
                                                                            <div className="flex items-center">
                                                                                <div
                                                                                    className={classNames(
                                                                                        mood.bgColor,
                                                                                        'flex size-8 items-center justify-center rounded-full',
                                                                                    )}
                                                                                >
                                                                                    <mood.icon
                                                                                        aria-hidden="true"
                                                                                        className={classNames(mood.iconColor, 'size-5 shrink-0')}
                                                                                    />
                                                                                </div>
                                                                                <span
                                                                                    className="ml-3 block truncate font-medium">{mood.name}</span>
                                                                            </div>
                                                                        </ListboxOption>
                                                                    ))}
                                                                </ListboxOptions>
                                                            </div>
                                                        </Listbox>
                                                    </div>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                >
                                                    Comment
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </main>


                    <div
                        className={`hidden ${darkMode ? "text-white" : "text-black"} text-md font-bold text-center mt-5 border-b-4 ${darkMode ? 'border-neutral-700' : ''} mx-6 pb-1 border-dotted`}>APPLICATION
                        RATE
                    </div>


                    {/*App rates*/}
                    <div className={`hidden ${darkMode ? "bg-bgDark border-neutral-700 border-2" : "border-1"} border h-full rounded-lg flex p-2 w-full shadow-gray-900 mt-5`}>

                        <div
                            className={` ${darkMode ? "bg-bgcolor border border-neutral-700/50" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs font-bold text-center mb-1 line-clamp-1`}>Fluid
                                oz / 1000 sq ft.:
                            </div>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs text-center rounded-lg border-2 py-0.5 px-1 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{Rate + " oz"}</div>
                        </div>

                        <div
                            className={` ${darkMode ? "bg-bgcolor border border-neutral-700/50" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs font-bold text-center mb-1 line-clamp-1`}>Gal
                                / 1 acre:
                            </div>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs text-center rounded-lg border-2 py-0.5 px-1 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{galRate + " gal"}</div>
                        </div>

                        <div
                            className={` ${darkMode ? "bg-bgcolor border border-neutral-700/50" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs font-bold text-center mb-1 line-clamp-1`}>ML
                                / 100 sq. m:
                            </div>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs text-center rounded-lg border-2 py-0.5 px-1 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{mlRate + " ml"}</div>
                        </div>

                        <div
                            className={` ${darkMode ? "bg-bgcolor border border-neutral-700/50" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs font-bold text-center mb-1 line-clamp-1`}>L
                                / HA:
                            </div>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs text-center rounded-lg border-2 py-0.5 px-1 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{lRate + " L"}</div>
                        </div>

                    </div>


                    <div
                        className={`hidden ${darkMode ? "text-white border-neutral-700" : "text-black"} text-md font-bold text-center mt-5  border-b-4 mx-6 pb-1 border-dotted`}>NUTRIENT
                        DATA
                    </div>


                    {/*Nutrient*/}
                    <div className={`hidden ${darkMode ? "bg-bgDark border-2 border-neutral-700" : "bg-white border drop-shadow"} flex items-center justify-center rounded-lg p-2 shadow-gray-900 mt-5 mr-24 ml-24`}>

                        <div className=" mr-auto ml-auto">
                            <div className="flex items-center justify-center">
                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-sm mr-2 line-clamp-1`}>Weight
                                    per gal:
                                </div>
                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>

                                    {/*{Weight + " " + chem.unitweight}*/}

                                    <span>
                                        {convertUnit(Weight, chem.unitweight, metric).value}{" "}
                                        {convertUnit(Weight, chem.unitweight, metric).unit}
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div className=" mr-auto">
                            <div className="flex items-center justify-center">
                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-sm mr-2 line-clamp-1`}>Container
                                    Weight:
                                </div>
                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>

                                    {/*{containerWeight + " " + chem.unitweight}*/}

                                    <span>
                                        {numberWithCommas(convertUnit(containerWeight, chem.unitweight, metric).value)}{" "}
                                        {convertUnit(containerWeight, chem.unitweight, metric).unit}
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div className=" mr-auto">
                            <div className="flex items-center justify-center">
                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-sm mr-2 line-clamp-1`}>Total
                                    Weight:
                                </div>
                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>

                                    {/*{numberWithCommas(totalWeight) + " " + chem.unitweight}*/}

                                    <span>
                                        {numberWithCommas(convertUnit(totalWeight, chem.unitweight, metric).value)}{" "}
                                        {convertUnit(totalWeight, chem.unitweight, metric).unit}
                                    </span>

                                </div>
                            </div>
                        </div>

                    </div>


                    <div className={`hidden ${darkMode ? "bg-bgDark border-2 border-neutral-700" : "bg-white border drop-shadow"} h-full rounded-lg  p-3 w-full shadow-gray-900 mt-5`}>


                        {/*Nutrients Map*/}
                        <div className=" w-full flex flex-row">

                            <div className={` w-2/3 rounded-lg ${darkMode ? 'border-neutral-700' : ''}`}>

                                <div
                                    className="items-center justify-center rounded-lg overflow-y-auto max-h-[calc(100vh-28rem)] scrollbar-none">

                                    {chem.nutrients.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex items-center justify-center">
                                            <div
                                                className={`${darkMode ? "bg-bgcolor  " : "bg-white drop-shadow"} p-4 rounded-lg shadow-gray-900 flex items-center justify-center flex-col m-5`}>
                                                <p className="text-xl text-darkgreen">This Chem Has No Nutrients</p>
                                                <p className="text-lg text-litegreen mt-1">Adding a Nutrient</p>

                                                <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1]
                                                    Press the Edit Button to the right of the Chem Card</p>
                                                <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>2]
                                                    This will fill the panel to the right with your chem's data</p>
                                                <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>3]
                                                    Navigate to the Nutrients Section and add in your nutrients and then
                                                    press Update Chem</p>
                                            </div>
                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        chem.nutrients.map((nutrient, index) => (
                                            <NutrientCard
                                                key={index}
                                                nutrient={nutrient}
                                                Vol={volume}
                                                Weight={chem.weight}
                                                galVol={chem.volume}
                                                index={index}
                                                metric={metric}
                                            />
                                        ))
                                    )}

                                </div>

                            </div>

                            <div className=" w-1/3 max-w-screen-md h-full">

                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-sm font-bold mr-2 text-center line-clamp-1`}>NUTRIENT
                                    MAKEUP:
                                </div>

                                <div className=" flex justify-center items-center">
                                    <Pie data={nutrientChart} className=" mr-auto ml-auto"/>
                                </div>

                            </div>

                        </div>

                        {/*Nutrients Data*/}
                        <div
                            className={`${darkMode ? "bg-bgDark border-2 border-neutral-700" : "bg-white drop-shadow border-1 border"} flex items-center justify-center rounded-lg p-1 shadow-gray-900 mt-2.5 w-full`}>

                            <div className="mr-auto ml-auto">
                                <div className="flex items-center justify-center">
                                    <div
                                        className={`${darkMode ? "text-white" : "text-black"} text-sm mr-2 line-clamp-1`}>Nutrients:
                                    </div>
                                    <div
                                        className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{chem.nutrients.length}</div>
                                </div>
                            </div>

                            <div className="mr-auto">
                                <div className="flex items-center justify-center">
                                    <div
                                        className={`${darkMode ? "text-white" : "text-black"} text-sm mr-2 line-clamp-1`}>Container
                                        Nutrients:
                                    </div>
                                    <div
                                        className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{totalContainerValue + " lbs"}</div>
                                </div>
                            </div>

                            <div className="mr-auto">
                                <div className="flex items-center justify-center">
                                    <div
                                        className={`${darkMode ? "text-white" : "text-black"} text-sm mr-2 line-clamp-1`}>Total
                                        Nutrients:
                                    </div>
                                    <div
                                        className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{numberWithCommas(totalNutrientValue) + " lbs"}</div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            )}


            <Dialog open={update} onClose={setUpdate} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-bgDark text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div
                                    className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <ClipboardDocumentListIcon aria-hidden="true" className="size-6 text-green-600"/>
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold flex items-center">
                                        Update Stock of | {chem.name}

                                        <Button className={`ml-auto`}
                                                onClick={resetValues}
                                        >
                                            <ArrowPathIcon
                                                className={`w-5 text-gray-500 hover:scale-105 hover:rotate-45`}/>
                                        </Button>
                                    </DialogTitle>
                                    <div className="w-full mt-6">
                                        <div className="grid grid-cols-3 gap-x-3 w-full">

                                            {/*Containers*/}
                                            <div className="col-span-1 relative flex items-center justify-center">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Containers
                                                </label>
                                                <Input
                                                    type="number"
                                                    placeholder="Containers"
                                                    required
                                                    value={chemQuantity}
                                                    onChange={(e) => setChemQuantity(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Excess*/}
                                            <div className="col-span-1 relative flex items-center justify-center">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Excess
                                                </label>
                                                <Input
                                                    type="number"
                                                    placeholder="Excess"
                                                    required
                                                    value={chemExcess}
                                                    onChange={(e) => setChemExcess(Number(e.target.value) || 0)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Volume*/}
                                            <div className="col-span-1 relative flex items-center justify-center">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Volume
                                                </label>
                                                <Input
                                                    type="number"
                                                    placeholder="Volume"
                                                    required
                                                    value={chemVolume}
                                                    onChange={(e) => setChemVolume(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                        </div>

                                        <div className="grid grid-cols-4 gap-x-3 w-full mt-6">

                                            {/*Label*/}
                                            <div className="col-span-2 relative flex items-center text-sm">
                                                <div className={``}>New Total Volume</div>
                                                <div
                                                    className={`ml-auto ${newVolume - volume < 0 ? 'text-red-500' : 'text-green-500'}`}
                                                >
                                                    {newVolume - volume > 0 ? `+${(newVolume - volume).toFixed(2)}` : (newVolume - volume).toFixed(2)}
                                                </div>
                                            </div>

                                            {/*Total Volume*/}
                                            <div className="col-span-2 relative flex items-center justify-center">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    New Total Volume
                                                </label>
                                                <Input
                                                    type="number"
                                                    placeholder="Volume"
                                                    required
                                                    disabled={true}
                                                    value={newVolume}
                                                    onChange={(e) => setChemVolume(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <Button
                                    type="button"
                                    onClick={() => updateValues(chem.id, onSuccess)}
                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                >
                                    Update
                                </Button>
                                <Button
                                    type="button"
                                    onClick={toggleUpdate}
                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-bgpage' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>


        </div>
    );
};

export default ChemCard;
