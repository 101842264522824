import React, { useState, useEffect } from 'react';
import Dashboard from "./SideBar";
import {useAuth} from "../contexts/authContext";
import {db} from "../firebase/firebase";
import { Chart, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import {Pie, Bar, Doughnut} from 'react-chartjs-2';
import {useDarkMode} from "../contexts/darkModeContext";
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import {BeakerIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";

Chart.register(ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);

export const chemOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Chemical Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Volume [Gal]',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

export const priceOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Chemical Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Unit Price',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

const ChemsDash = () => {

    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    const [noChems, setNoChems] = useState(false);

    // eslint-disable-next-line
    const [chems, setChems] = useState([]);
    const [classificationCounts, setClassificationCounts] = useState({});
    const [nutrientCounts, setNutrientCounts] = useState({});
    const [consistCounts, setConsistCounts] = useState({});
    const [manufacturerCounts, setManufacturerCounts] = useState({});
    const [chemsBarChartData, setChemsBarChartData] = useState(null);
    const [priceBarChartData, setPriceBarChartData] = useState(null);

    useEffect(() => {
        const fetchChems = async () => {
            if (currentUser) {
                try {
                    const storedChemsData = localStorage.getItem(`chemsData_${currentUser.uid}`);

                    if (storedChemsData) {
                        setNoChems(false);
                        const chemsData = JSON.parse(storedChemsData);
                        setChems(chemsData);

                        const barChartData = {
                            labels: chemsData.map(chem => chem.name), // Using document IDs as labels
                            datasets: [
                                {
                                    label: 'Total Volume [Gal]',
                                    backgroundColor: 'rgba(75, 205, 129, 0.2)',
                                    borderColor: 'rgba(75, 205, 129, 0.8)',
                                    borderWidth: 2,
                                    data: chemsData.map(chem => calculateTotalVolume(chem)) // Calculating total price for each document
                                }
                            ]
                        };
                        setChemsBarChartData(barChartData);

                        const priceBarChartData = {
                            labels: chemsData.map(chem => chem.name), // Using document IDs as labels
                            datasets: [
                                {
                                    label: 'Unit Price',
                                    backgroundColor: 'rgba(122, 197, 158, 0.2)',
                                    borderColor: 'rgba(122, 197, 159, 0.8)',
                                    borderWidth: 2,
                                    data: chemsData.map(chem => chem.unitprice) // Calculating total price for each document
                                }
                            ]
                        };
                        setPriceBarChartData(priceBarChartData);

                        const counts = {};
                        chemsData.forEach(doc => {
                            const classification = doc.classification;
                            counts[classification] = (counts[classification] || 0) + 1;
                        });
                        setClassificationCounts(counts);

                        const counts2 = {};
                        chemsData.forEach(doc => {
                            const nutrients = doc.nutrients;
                            nutrients.forEach(nutrient => {
                                const name = nutrient.name;
                                counts2[name] = (counts2[name] || 0) + 1;
                            });
                        });
                        setNutrientCounts(counts2);

                        const counts3 = {};
                        chemsData.forEach(doc => {
                            const consist = doc.type;
                            counts3[consist] = (counts3[consist] || 0) + 1;
                        });
                        setConsistCounts(counts3);

                        const counts4 = {};
                        chemsData.forEach(doc => {
                            const manu = doc.manufacturer;
                            counts4[manu] = (counts4[manu] || 0) + 1;
                        });
                        setManufacturerCounts(counts4);


                    } else {
                        console.log('No chems found');
                        setNoChems(true);
                    }
                } catch (error) {
                    console.error('Error fetching chems:', error);
                }
            }
        };

        fetchChems();
    }, [currentUser]);


    const chemChart = {
        labels: Object.keys(classificationCounts),
        datasets: [
            {
                label: '# of Chems',
                data: Object.values(classificationCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],


                borderWidth: 1,
            },
        ],
    };

    const chemChart2 = {
        labels: Object.keys(nutrientCounts),
        datasets: [
            {
                label: '# of Instances',
                data: Object.values(nutrientCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],


                borderWidth: 1,
            },
        ],
    };

    const chemChart3 = {
        labels: Object.keys(consistCounts),
        datasets: [
            {
                label: '# of Instances',
                data: Object.values(consistCounts),
                backgroundColor: [
                    'rgba(100, 140, 180, 0.2)', // Lighter blue
                    'rgba(70, 220, 130, 0.2)', // Bright green
                    'rgba(190, 225, 150, 0.2)', // Light lime green
                    'rgba(140, 100, 200, 0.2)', // Lavender
                    'rgba(220, 160, 160, 0.2)', // Light coral
                    'rgba(60, 100, 170, 0.2)', // Lighter navy blue
                    'rgba(110, 160, 200, 0.2)' // Lighter sky blue
                ],
                borderColor: [
                    'rgba(100, 140, 180, 1)', // Lighter blue
                    'rgba(70, 220, 130, 1)', // Bright green
                    'rgba(190, 225, 150, 1)', // Light lime green
                    'rgba(140, 100, 200, 1)', // Lavender
                    'rgba(220, 160, 160, 1)', // Light coral
                    'rgba(60, 100, 170, 1)', // Lighter navy blue
                    'rgba(110, 160, 200, 1)' // Lighter sky blue
                ],


                borderWidth: 1,
            },
        ],
    };

    const chemChart4 = {
        labels: Object.keys(manufacturerCounts),
        datasets: [
            {
                label: '# of Instances',
                data: Object.values(manufacturerCounts),
                backgroundColor: [
                    'rgba(100, 140, 180, 0.2)', // Lighter blue
                    'rgba(70, 220, 130, 0.2)', // Bright green
                    'rgba(190, 225, 150, 0.2)', // Light lime green
                    'rgba(140, 100, 200, 0.2)', // Lavender
                    'rgba(220, 160, 160, 0.2)', // Light coral
                    'rgba(60, 100, 170, 0.2)', // Lighter navy blue
                    'rgba(110, 160, 200, 0.2)' // Lighter sky blue
                ],
                borderColor: [
                    'rgba(100, 140, 180, 1)', // Lighter blue
                    'rgba(70, 220, 130, 1)', // Bright green
                    'rgba(190, 225, 150, 1)', // Light lime green
                    'rgba(140, 100, 200, 1)', // Lavender
                    'rgba(220, 160, 160, 1)', // Light coral
                    'rgba(60, 100, 170, 1)', // Lighter navy blue
                    'rgba(110, 160, 200, 1)' // Lighter sky blue
                ],


                borderWidth: 1,
            },
        ],
    };

    const calculateTotalVolume = (chem) => {
        return ((chem.quantity * chem.volume) + chem.excess);
    };

    return (
        <div className={`${darkMode ? "bg-bgpage" : "bg-bgcolorLight"} flex h-screen w-full mt-16`}>

            <Dashboard/>

            <div className={`z-50 animate-fadeIn flex flex-col w-full mt-3 mr-3 overflow-y-auto overflow-x-hidden rounded-lg max-h-[calc(100vh-5.5rem)]`}>


                {/*Grid*/}
                <div className="grid h-screen grid-cols-8 grid-rows-6 gap-3">


                    {/*Row 1*/}


                    {/*Volume Per Chemical*/}
                    <div className={`col-span-4 row-span-3 p-2 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>
                        <h1 className={`text-green-600 text-center`}>Volume Per Chemical</h1>
                        <div className="w-full h-full items-center justify-center flex">
                            {chemsBarChartData && <Bar options={chemOptions} data={chemsBarChartData} className="p-2"/>}
                        </div>
                    </div>

                    {/*Chemical Classifications*/}
                    <div className={`col-span-2 row-span-3 p-2 col-start-5 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>
                        <h1 className={`text-green-600 text-center`}>Chemical Classification</h1>
                        <div className="w-full h-full items-center justify-center flex">
                            {chemChart && <Pie data={chemChart} className="mr-auto ml-auto p-2"/>}
                        </div>
                    </div>

                    {/*Nutrient Makeup*/}
                    <div className={`col-span-2 row-span-3 p-2 col-start-7 h-96 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>
                        <h1 className={`text-green-600 text-center`}>Nutrient Makeup</h1>
                        <div className="w-full h-full items-center justify-center flex">
                            {chemChart2 && <Doughnut data={chemChart2} className="mr-auto ml-auto p-2"/>}
                        </div>
                    </div>



                    {/*Row 2*/}


                    {/*Chemical Consistencies*/}
                    <div className={`col-span-2 row-span-3 p-2 row-start-4 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>
                        <h1 className={`text-green-600 text-center`}>Chemical Consistencies</h1>
                        <div className="w-full h-full items-center justify-center flex">
                            {chemChart3 && <Pie data={chemChart3} className="mr-auto ml-auto p-2"/>}
                        </div>
                    </div>

                    {/*Manufacturer Makeup*/}
                    <div className={`col-span-2 row-span-3 p-2 col-start-3 row-start-4 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>
                        <h1 className={`text-green-600 text-center`}>Manufacture Makeup</h1>
                        <div className="w-full h-full items-center justify-center flex">
                            {chemChart4 && <Doughnut data={chemChart4} className="mr-auto ml-auto p-2"/>}
                        </div>
                    </div>

                    {/*Unit Price per Chemical*/}
                    <div className={`col-span-4 row-span-3 p-2 col-start-5 row-start-4 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>
                        <h1 className={`text-green-600 text-center`}>Unit Price per Chemical</h1>
                        <div className="w-full h-full items-center justify-center flex">
                            {priceBarChartData && <Bar options={priceOptions} data={priceBarChartData} className="p-2"/>}
                        </div>
                    </div>

                </div>


                <Dialog open={noChems} onClose={()=> setNoChems(true)} className="relative z-50 animate-fadeIn">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-bgDark text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                            >
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                        <BeakerIcon aria-hidden="true" className="size-6 text-green-600" />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3" className="text-base font-semibold">
                                            You Have No Chems in your Database
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Since you have no chemicals in your database you will not be able to view any analytics. First go fill out your inventory!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    {/*<a*/}
                                    {/*    href={`/chems`}*/}
                                    {/*    type="button"*/}
                                    {/*    // onClick={handleDelete}*/}
                                    {/*    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"*/}
                                    {/*>*/}
                                    {/*    Add A Chem*/}
                                    {/*</a>*/}
                                    <Link
                                        className={`inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                                        to={'/Chems'}
                                    >
                                        Add A Chem
                                    </Link>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>



            </div>

        </div>
    );
};


export default ChemsDash;
