import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { doSignOut } from '../firebase/auth';
import imageSrc from '../GroLinkz Logo[PNG].png';
import imageSrcDark from '../GroLinkz Logo[PNG] Dark.png';
import {
    SunIcon,
    MoonIcon,
    ArrowRightOnRectangleIcon, ComputerDesktopIcon, CalendarDaysIcon, EyeIcon, AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import {useDarkMode} from "../contexts/darkModeContext";
import {Button} from "@headlessui/react";
import {db} from "../firebase/firebase";
import CyberBackground from "./CyberBackground";

const Header = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useAuth();
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');

    const { currentUser } = useAuth();
    const { darkMode, toggleDarkMode } = useDarkMode(); // Get dark mode state and toggler from context

    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const [profileImage, setProfileImage] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {

        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setProfileImage(userData.profileImage);
                        setName(userData.fname);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setProfileImage(userData.profileImage);
                            setName(userData.fname);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();

    }, [currentUser]);

    const [currentPath] = useState(window.location.pathname); // Initialize currentPath



    // State for active section and spinning effect
    const [activeSection, setActiveSection] = useState(null);
    const [textVisible, setTextVisible] = useState(null);

    const handleMouseEnter = (section) => {
        setActiveSection(section);
        setTimeout(() => {
            setTextVisible(section);
        }, 300); // Ensure text appears only after expansion
        setTextVisible(null);
    };

    const handleMouseLeave = (section) => {
        setTextVisible(null); // Hide text first
        setTimeout(() => {
            setActiveSection(null);
        }, 300); // Delay collapse until text is gone
    };




    return (
        <div className={`w-full ${darkMode ? 'bg-bgcard' : 'bg-bgcolorLight'} z-20 h-16 pr-3 pt-3 pl-3 absolute top-0 left-0`}>

            <CyberBackground/>

            <div className={`flex h-full z-20 flex-row justify-between items-center w-full rounded-md border ${darkMode ? "bg-bgDark text-white border-neutral-700" : "bg-white drop-shadow shadow-gray-900 text-black"}`}>

                {/*Links*/}
                <div className={`w-full flex items-center`}>





                    {/* Job Board Link */}
                    <Link to="/JobBoard"
                        className={`flex items-center p-1 hover:bg-bgDarkGreen ease-in-out hover:scale-105 ml-4 rounded-full 
                        ${currentPath === '/JobBoard' ? 'bg-green-600' : darkMode ? 'bg-bgcard border border-neutral-700 border-opacity-50' : 'bg-bgColor border hover:drop-shadow'} 
                        ${activeSection === 'job' ? 'w-fit' : 'justify-center'}`}
                        onMouseEnter={() => handleMouseEnter('job')}
                        onMouseLeave={() => handleMouseLeave('job')}
                    >
                        <div className={`w-8 h-8 rounded-full drop-shadow shadow-2xl shadow-black flex items-center justify-center transition-all duration-500 ease-in-out 
                        ${activeSection === 'job' ? 'mr-20 bg-bgLightGreen' : ''}`}>
                            <ComputerDesktopIcon className={`w-5 flex-none ${currentPath === '/JobBoard' ? 'text-white' : ''}`} />
                        </div>
                        <div className={`tracking-widest text-xs absolute left-10 transition-opacity duration-300 ease-in-out 
                        ${textVisible === 'job' ? 'animate-fadeIn' : 'opacity-0 invisible'}`}>
                            Job Board
                        </div>
                    </Link>

                    {/* Calendar Link */}
                    <Link to="/Calendar"
                        className={`flex items-center p-1 hover:bg-bgDarkGreen ease-in-out hover:scale-105 ml-4 rounded-full 
                        ${currentPath === '/Calendar' ? 'bg-green-600' : darkMode ? 'bg-bgcard border border-neutral-700 border-opacity-50' : 'bg-bgColor border hover:drop-shadow'} 
                        ${activeSection === 'calendar' ? 'w-fit' : 'justify-center'}`}
                        onMouseEnter={() => handleMouseEnter('calendar')}
                        onMouseLeave={() => handleMouseLeave('calendar')}
                    >
                        <div className={`w-8 h-8 rounded-full drop-shadow shadow-2xl shadow-black flex items-center justify-center transition-all duration-500 ease-in-out 
                        ${activeSection === 'calendar' ? 'mr-20 bg-bgLightGreen' : ''}`}>
                            <CalendarDaysIcon className={`w-5 flex-none ${currentPath === '/Calendar' ? 'text-white' : ''}`} />
                        </div>
                        <div className={`tracking-widest text-xs absolute left-10 transition-opacity duration-300 ease-in-out 
                        ${textVisible === 'calendar' ? 'animate-fadeIn' : 'opacity-0 invisible'}`}>
                            Calendar
                        </div>
                    </Link>

                    {/* Vision Link */}
                    <Link to="/Vision"
                        className={`flex items-center p-1 hover:bg-bgDarkGreen ease-in-out hover:scale-105 ml-4 rounded-full 
                        ${currentPath === '/Vision' ? 'bg-green-600' : darkMode ? 'bg-bgcard border border-neutral-700 border-opacity-50' : 'bg-bgColor border hover:drop-shadow'} 
                        ${activeSection === 'vision' ? 'w-fit' : 'justify-center'}`}
                        onMouseEnter={() => handleMouseEnter('vision')}
                        onMouseLeave={() => handleMouseLeave('vision')}
                    >
                        <div className={`w-8 h-8 rounded-full drop-shadow shadow-2xl shadow-black flex items-center justify-center transition-all duration-500 ease-in-out 
                        ${activeSection === 'vision' ? 'mr-28 bg-bgLightGreen' : ''}`}>
                            <EyeIcon className={`w-5 flex-none ${currentPath === '/Vision' ? 'text-white' : ''}`} />
                        </div>
                        <div className={`tracking-widest text-xs absolute left-10 transition-opacity duration-300 ease-in-out 
                        ${textVisible === 'vision' ? 'animate-fadeIn' : 'opacity-0 invisible'}`}>
                            Greens Vision
                        </div>
                    </Link>





                </div>

                {/*Middle*/}
                <div className="flex w-full justify-center items-center">
                    <div className={`tracking-widest ${darkMode ? "bg-bgcard" : "bg-bgcolorLight drop-shadow"} mr-16 text-sm rounded-lg px-2 py-0.5 shadow-gray-900`}>
                        {time}
                    </div>


                    {/*DarkMode Toggle*/}
                    <div className="">
                        <input
                            type="checkbox"
                            id="spray"
                            name="spray"
                            checked={darkMode}
                            onChange={toggleDarkMode} // Call the toggle function from context
                            className="sr-only"
                        />
                        <label htmlFor="spray" className="cursor-pointer">
                            <div
                                className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${darkMode ? 'bg-bgpage' : 'bg-bgcolorLight drop-shadow shadow-gray-900'}`}>
                                <div
                                    className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${darkMode ? 'bg-white translate-x-full' : 'bg-white'}`}>
                                    {darkMode ? (
                                        <MoonIcon
                                            className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <SunIcon
                                            className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    )}
                                </div>
                            </div>
                        </label>
                    </div>


                    <div className={`tracking-widest ${darkMode ? "bg-bgcard" : "bg-bgcolorLight drop-shadow"} ml-16 text-sm rounded-md px-2 py-0.5 shadow-gray-900`}>
                        {date}
                    </div>
                </div>

                {/*Profile*/}
                <div className="w-full flex justify-end items-center">

                    <a
                        href="/Account"
                    >
                        <img
                            alt=""
                            src={profileImage}
                            className={`size-9 rounded-full border border-gray-500`}
                        />
                    </a>

                    <span className="hidden lg:flex lg:items-center">
                      <span aria-hidden="true" className="ml-4 text-xs">
                        Welcome {name}
                      </span>
                    </span>


                    <Button className={`ml-4`}>
                        <AdjustmentsHorizontalIcon className={`size-6 text-green-600 hover:text-opacity-50`}/>
                    </Button>


                    <nav>
                        {userLoggedIn ? (
                            <Button
                                onClick={() => {
                                    doSignOut().then(() => {
                                        navigate('/login');
                                    });
                                }}
                                className={`${darkMode ? "  " : "drop-shadow"} text-sm text-white h-8 rounded-md hover:animate-pulse p-1 flex items-center justify-center shadow-gray-900 ml-3 mr-3`}>
                                <ArrowRightOnRectangleIcon className={`w-6 text-red-500`}/>
                            </Button>
                        ) : (
                            <>
                                <Link className='text-sm text-white underline mx-2' to={'/login'}>Login</Link>
                                <Link className='text-sm text-white underline mx-2' to={'/register'}>Register New
                                    Account</Link>
                            </>
                        )}
                    </nav>
                </div>

            </div>
        </div>
    );
};

export default Header;
