import React, {useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import logo from "../../PlantFood_SVG.svg"
import {Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import {
    ArrowPathRoundedSquareIcon,
    ExclamationTriangleIcon,
    PencilSquareIcon,
    TrashIcon
} from "@heroicons/react/24/outline";

const EmployeeCard = ({employee, onDelete, onEdit}) => {
    const [expanded, setExpanded] = useState(false); // State to track expansion

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const { darkMode } = useDarkMode();

    // Your existing code...

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        onDelete(employee.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };

    const DateDifference = ({ dateString }) => {
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        const differenceMs = currentDate - inputDate;
        const differenceDate = new Date(differenceMs);

        const years = differenceDate.getUTCFullYear() - 1970;
        const months = differenceDate.getUTCMonth();
        const days = differenceDate.getUTCDate() - 1;

        const formatDifferencePart = (number, label) => (
            <span>
                <span className={`${darkMode ? 'text-white' : 'text-black'}`}>{number}</span>{' '}
                <span className="text-gray-400">{label}{number > 1 ? 's' : ''}</span>
            </span>
        );

        const parts = [];

        if (years > 0) parts.push(formatDifferencePart(years, 'year'));
        if (months > 0) parts.push(formatDifferencePart(months, 'month'));
        if (days > 0) parts.push(formatDifferencePart(days, 'day'));
        if (days === 0 && months === 0 && years === 0) {
            parts.push(
                <span className="text-green-600 font-bold">Hired Today!</span>
            );
        }

        return (
            <div>
                <p className={`${darkMode ? 'text-white' : 'text-black'} tracking-widest text-sm text-center w-full`}>
                    {parts.reduce((prev, curr, index) => (
                        <>
                            {prev}
                            {index > 0 && ', '}
                            {curr}
                        </>
                    ))}
                </p>
            </div>
        );
    };

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [flipped, setFlipped] = useState(false);

    const toggleFlipped = () => {
        setFlipped(!flipped);
    }

    return (


        <div className={`w-full flex flex-col h-[32rem] transition-transform duration-500 ease-in-out relative`}>

            <Dialog open={showDeleteConfirmation} onClose={setShowDeleteConfirmation} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-bgDark text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                                    <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold">
                                        Delete | {employee.name}
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to delete this employee? All of their data will be permanently removed from
                                            our servers forever. This action cannot be undone.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={handleDelete}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setShowDeleteConfirmation(false)}
                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-bgpage' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

            {/*HEADER*/}
            <div className="w-full relative h-full flex items-center flex-col">

                <Button className={`absolute ml-56 mt-16 text-gray-500`} onClick={toggleFlipped}>
                    <ArrowPathRoundedSquareIcon className={`w-6`}/>
                </Button>

                {/*Image*/}
                <div className={`w-48 h-48 z-20 flex items-center justify-center ${darkMode ? 'bg-bgDark border-8 border-bgpage' : 'bg-white border-8 border-bgcolorLight'} rounded-full absolute left-1/2 transform -translate-x-1/2 transition-all duration-500 ease-in-out`}>

                    {/* IMAGE */}
                    <img
                        src={logo}
                        alt={employee.name}
                        className={`${logo ? 'w-32 h-40' : ' w-24 h-28'} hover:animate-pulse`}
                    />

                </div>


                {/* Container for the flip animation */}
                <div className="relative w-full mt-auto h-[26rem]"
                     // cursor-pointer perspective
                     // onClick={toggleFlipped}
                >
                    {/* Flip Card */}
                    <div className={`relative w-full h-full transition-transform duration-700 ease-in-out transform preserve-3d ${flipped ? 'rotate-y-180' : ''}`}>


                        {/* Front Side */}
                        <div className={`absolute w-full h-[26rem] overflow-y-hidden backface-hidden rounded-lg ${darkMode ? 'bg-bgDark border border-gray-900 text-white' : 'bg-white border text-black'} flex flex-col justify-between p-2 transition-all duration-500 ease-in-out`}>

                            {/* Your front-side content goes here */}

                            <div className={``}>

                                {/*Top Button*/}
                                <div className="w-full h-fit flex flex-row p-1.5">
                                    <div className={`w-1/4 p-1`}>
                                        <Button className="text-xl w-fit text-white font-bold"
                                            onClick={toggleDeleteConfirmation}
                                        >
                                            <TrashIcon className="w-6 text-red-700 hover:text-red-500"/>
                                        </Button>
                                        {/*<div className="flex items-center justify-center h-full text-xs line-clamp-1">{"- - - - - - - -"}</div>*/}
                                    </div>

                                    <div className={`w-1/2 p-1 h-8 rounded-full hover:scale-105`}>
                                        <div className="flex items-center justify-center h-full text-xs line-clamp-1">{}</div>
                                    </div>

                                    <div className={`w-1/4 p-1 flex justify-end`}>
                                        <Button onClick={() => onEdit(employee)} className={`${darkMode ? "text-white" : "text-gray-400"} text-md px-2 mb-4 hover:text-litegreen`}>
                                            <PencilSquareIcon className="w-6 hover:text-green-600"/>
                                        </Button>
                                        {/*<div className="flex items-center justify-center h-full text-xs line-clamp-1">{"- - - - - - - -"}</div>*/}
                                    </div>
                                </div>

                                {/*Name*/}
                                <div className="w-full flex mt-6 flex-row justify-center p-1.5">
                                    <div className={`w-fit ${darkMode ? '' : ''} rounded-lg hover:scale-105`}>
                                        <div className="flex items-center justify-center text-xl line-clamp-1 tracking-widest">{employee.name}</div>
                                        {/*<div className="flex items-center text-gray-400 justify-center text-sm line-clamp-1">{employee.position}</div>*/}
                                        <div className="flex items-center text-gray-400 justify-center text-xs line-clamp-1">{employee.type}</div>
                                    </div>
                                </div>

                                {/*Body*/}
                                <div className={`border rounded-lg flex flex-col justify-between py-4 px-4 h-[13.25rem] mb-3 ${darkMode ? 'border-neutral-700' : 'border'}`}>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Start Date:</dt>
                                        <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0 ml-auto">{employee.startDate}</dd>
                                    </div>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Department:</dt>
                                        <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0 ml-auto">{employee.department}</dd>
                                    </div>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Position:</dt>
                                        <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0 ml-auto">{employee.position}</dd>
                                    </div>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Employee Number:</dt>
                                        <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0 ml-auto">{employee.numId}</dd>
                                    </div>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Pay Rate:</dt>
                                        <dd className="mt-1 text-sm/6 sm:col-span-2 sm:mt-0 ml-auto bg-green-600/15 border rounded-lg border-green-600 px-2">${numberWithCommas(employee.payRate) + " " + employee.payType}</dd>
                                    </div>

                                </div>

                                {/*Employee Tenure*/}
                                <div className={`border flex items-center justify-center rounded-lg h-[2rem] border-green-600 bg-green-600/15`}>
                                    <DateDifference dateString={employee.startDate}/>
                                </div>


                            </div>


                        </div>


                        {/* Back Side (Hidden Content) */}
                        <div className={`absolute w-full h-[26rem] overflow-y-hidden backface-hidden rotate-y-180 rounded-lg ${darkMode ? 'bg-bgDark border border-gray-900 text-white' : 'bg-white border text-black'} flex flex-col justify-center items-center p-1 transition-all duration-500 ease-in-out`}>

                            {/* Your back-side content goes here */}

                            <div className={`mt-auto w-full px-2 pb-2`}>

                                {/*Emergency Contact*/}
                                <div className={`border-2 border-red-600 rounded-lg flex flex-col justify-between py-4 px-4 h-[9rem] mb-3`}>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Name:</dt>
                                        <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0 ml-auto">{employee.eName}</dd>
                                    </div>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Phone:</dt>
                                        <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0 ml-auto">{employee.ePhone}</dd>
                                    </div>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Relation:</dt>
                                        <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0 ml-auto">{employee.eRelation}</dd>
                                    </div>

                                </div>

                                {/*Contact Info*/}
                                <div className={`border rounded-lg flex flex-col justify-between py-8 px-4 h-[9rem] ${darkMode ? 'border-neutral-700' : 'border'}`}>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Phone:</dt>
                                        <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0 ml-auto">{employee.phone}</dd>
                                    </div>

                                    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm/6 font-medium">Email:</dt>
                                        <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0 ml-auto">{employee.email}</dd>
                                    </div>

                                </div>


                            </div>

                        </div>


                    </div>
                </div>


            </div>

        </div>

    );
};

export default EmployeeCard;
