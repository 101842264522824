import {useAuth} from "../../contexts/authContext";
import {useDarkMode} from "../../contexts/darkModeContext";
import React, {useEffect, useState} from "react";
import {db} from "../../firebase/firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import imageSrc from "../../FleetID_Tag_Icon.png";
import {Line} from "react-chartjs-2";
import {
    ArrowDownCircleIcon,
    ArrowUpCircleIcon, ExclamationTriangleIcon,
    PencilSquareIcon, TagIcon,
    TrashIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import ServiceCard from "./ServiceCard";
import {Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";

const MachineCard = ({machine, onDelete, onEdit}) => {
    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    const [expanded, setExpanded] = useState(false); // State to track expansion

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showServiceForm, setShowServiceForm] = useState(false);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Operational Usage',
                data: [],
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    });

    useEffect(() => {
        const fetchData = async () => {
            const labels = [];
            const serviceUsage = [];
            machine.service.forEach(item => {
                labels.push(item.serviceDate);
                serviceUsage.push(item.serviceUsage);
            });
            setChartData({
                ...chartData,
                labels: labels,
                datasets: [
                    {
                        ...chartData.datasets[0],
                        data: serviceUsage,
                    },
                ],
            });
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    const chartOptions = {
        scales: {
            x: {
                type: 'category',
                title: {
                    display: true,
                    text: 'Date',
                    color: 'white',
                    font: {
                        size: 16,
                    },
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Operational Usage',
                    color: 'white',
                    font: {
                        size: 16,
                    },
                },
            },
        },
    };

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const [serviceData, setServiceData] = useState({
        // Initialize form fields
        serviceType: '',
        serviceCost: 0,
        serviceUsage: machine.usage,
        serviceDate: new Date().toLocaleDateString('en-US').split('/').join('-'), // Set default value to current date
        serviceProvider: '',
    });

    const toggleServiceForm = () => {
        setShowServiceForm(!showServiceForm);
    };

    const handleServiceChange = (e) => {
        const { name, value } = e.target;
        setServiceData({
            ...serviceData,
            [name]: value,
        });
    };

    const handleServiceSubmit = async (e) => {
        e.preventDefault();

        // Parse serviceUsage and serviceCost to float
        const parsedServiceUsage = parseFloat(serviceData.serviceUsage);
        const parsedServiceCost = parseFloat(serviceData.serviceCost);

        // Create a reference to the user's fleet document
        const fleetRef = db.collection('Users').doc(currentUser.uid).collection('fleet').doc(machine.id);

        try {
            // Fetch the current services array
            const doc = await fleetRef.get();
            if (doc.exists) {
                const { service } = doc.data();
                // Append the new service to the array
                const updatedServices = [...service, { ...serviceData, serviceUsage: parsedServiceUsage, serviceCost: parsedServiceCost }];
                // Update the document with the modified array
                await fleetRef.update({ service: updatedServices });

                // Update machine.usage in the parent document with the new serviceUsage value
                await fleetRef.update({ 'usage': parsedServiceUsage });
            } else {
                // If the document doesn't exist, create it with the new service
                await fleetRef.set({
                    service: [{ ...serviceData, serviceUsage: parsedServiceUsage, serviceCost: parsedServiceCost }],
                });
            }

            // Reset form fields
            setServiceData({
                serviceType: '',
                serviceCost: 0,
                serviceUsage: 0,
                serviceDate: new Date().toLocaleDateString('en-US').split('/').join('-'), // Set service date to current date
                serviceProvider: '',
            });
            // Close the service form
            setShowServiceForm(false);
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };


    const totalServiceCost = machine.service.reduce((accumulator, current) => {
        return accumulator + (current.serviceCost);
    }, 0).toFixed(2);

    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        onDelete(machine.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };


    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
        <div className={`${darkMode ? "bg-bgDark text-white" : "bg-white drop-shadow text-black"} p-2 rounded-md items-center justify-center shadow-gray-900 mb-2 w-full animate-cascade`}>

            <Dialog open={showDeleteConfirmation} onClose={setShowDeleteConfirmation} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-bgDark text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                                    <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold">
                                        Delete | {machine.name}
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to delete this machine? All of its data will be permanently removed from
                                            our servers forever. This action cannot be undone.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={handleDelete}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setShowDeleteConfirmation(false)}
                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-bgpage' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>




            <div className="flex items-center justify-center">


                {/*Delete Button*/}
                <div className="mr-2">
                    <Button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                        <TrashIcon className="w-5 text-red-700 hover:text-red-500 hover:animate-pulse"/>
                    </Button>
                </div>


                {/*Section 1*/}
                <div className={`bg-green-600/15 border-green-600 border h-full rounded-md flex flex-col items-center justify-center p-2 w-full shadow-gray-900 mr-5`}>
                    <div className="flex items-center">
                        <div className="text-xl text-center line-clamp-1">{machine.name}</div>
                        {machine.tag && <TagIcon className={`w-4 ml-2`}/> }
                    </div>

                    <div className="text-xs text-gray-500 text-center line-clamp-1">{machine.type}</div>
                    <div className="text-xs text-gray-500 text-center line-clamp-1">{machine.purchaseDate}</div>
                </div>


                {/*Section 2*/}
                <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} h-full rounded-lg flex p-2 w-full shadow-gray-50 mr-5`}>

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Manufacturer:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Engine Type:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>Model Year:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className={`rounded-lg border border-1 p-1.5 drop-shadow-xl border-neutral-500 ${darkMode ? '' : 'bg-bgcolorLight'}`}>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>{machine.manufacturer}</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>{machine.engineType}</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>{machine.year}</div>
                        </div>

                    </div>

                </div>


                {/*Section 3*/}
                <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} h-full rounded-lg flex items-center justify-center p-2 w-full shadow-gray-50`}>

                    <div className="justify-center items-center">

                        <div className="w-full">
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Operational Usage:</div>
                            <div className={`${darkMode ? "text-white" : "text-black bg-bgcolorLight"} drop-shadow-xl border-neutral-500 text-sm text-center line-clamp-1 rounded-md border p-1 border-solid`}>{numberWithCommas(machine.usage) + " " + machine.usageUnit}</div>
                        </div>

                    </div>

                </div>


                {/*Section 4*/}
                <div className="h-full rounded-md flex items-center justify-center w-full">

                    <div className="flex flex-col items-center justify-center w-full">

                        <div className="text-lg text-darkgreen text-center line-clamp-1">Purchase Price:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-md text-center mb-2 line-clamp-1`}>${numberWithCommas(machine.purchasePrice.toFixed(2))}</div>

                        <div className={`rounded-md border-2 p-1.5 ${totalServiceCost > machine.purchasePrice ? 'border-red-500 bg-red-500/15 animate-pulse' : 'border-green-600 bg-green-600/15'}`}>
                            <div className={`text-lg ${totalServiceCost > machine.purchasePrice ? 'text-red-500' : 'text-green-600'} text-center line-clamp-1`}>Service Cost:</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-md text-center line-clamp-1`}>${numberWithCommas(totalServiceCost)}</div>
                        </div>

                    </div>



                </div>


                {/*Edit and Expand*/}
                <div className="flex flex-col items-center">


                    <Button onClick={() => onEdit(machine)} className={`${darkMode ? "text-white" : "text-gray-400"} text-md px-2 mb-4 hover:text-litegreen`}>
                        <PencilSquareIcon className={`w-6`}/>
                    </Button>

                    <Button className={`${darkMode ? "text-white" : "text-gray-400"} text-xxl font-bold mt-auto mb-auto hover:text-litegreen`}
                            onClick={toggleExpansion}>
                        {expanded ?
                            <ArrowUpCircleIcon className={`w-6`}/>
                            :
                            <ArrowDownCircleIcon className={`w-6`}/>
                        }
                    </Button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <div>

                    {/*Usage Label*/}
                    <div className={`w-full items-center justify-center border-b-4 ${darkMode ? 'border-neutral-700' : ''} mx-6 pb-1 border-dotted`}>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-md font-bold text-center mt-5`}>OPERATIONAL STATISTICS</div>
                        <div className={`${darkMode ? "text-green-600" : "text-gray-400"} text-xs text-center`}>This is a chart of operational usage on the machine over time</div>
                    </div>


                    {/*USage Chart*/}
                    <div className="flex justify-center mt-5">
                        <div className="w-full max-w-screen-md h-96">
                            <Line data={chartData} options={chartOptions} />
                        </div>
                    </div>


                    {/*Service Label*/}
                    <div className={`flex border-b-4 ${darkMode ? 'border-neutral-700' : ''} mx-6 pb-1 border-dotted`}>

                        <div className="w-full items-center justify-center">

                        </div>

                        <div className=" w-full items-center justify-center">
                            <div className={`${darkMode ? "text-white" : "text-black"} text-md font-bold text-center mt-5`}>SERVICES</div>
                            <div className={`${darkMode ? "text-green-600" : "text-gray-400"} text-xs text-center`}>This is all the data logged for each service!</div>
                        </div>

                        <div className="flex flex-col items-end justify-center w-full mt-5">
                            <Button className="text-white text-xs px-2 py-1 rounded-lg bg-green-600 focus:outline-none focus:shadow-outline hover:bg-opacity-50"
                                    onClick={toggleServiceForm}>
                                Log a Service
                            </Button>
                        </div>


                    </div>


                    {/*Service Map*/}
                    <div className={`${darkMode ? "bg-bgDark border-neutral-700 border-2" : "border-1"} border h-full rounded-md p-2 w-full shadow-gray-900 mt-5`}>

                        <div className="items-center justify-center overflow-y-auto max-h-[calc(80vh-20rem)] scrollbar-none">

                        {machine.service.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex items-center justify-center">
                                    <div
                                        className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-5`}>
                                        <p className="text-xl text-darkgreen">This Machine Has No Service</p>
                                        <p className="text-lg text-litegreen mt-1">Logging a Service</p>

                                        <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Press
                                            the Log a Service button to the left of the service title</p>
                                        <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>2] This
                                            will open up the popup in the middle of the screen</p>
                                        <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>3] Fill
                                            out the fields in the popup and then hit Submit at the bottom</p>
                                    </div>
                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                machine.service.map((service, index) => (
                                    <ServiceCard key={index} service={service} unit={machine.usageUnit} id={index}
                                                 machineId={machine.id}/>
                                ))
                            )}

                        </div>

                    </div>


                    {/*Service Total*/}
                    <div className={`${darkMode ? "bg-bgDark border-2 border-neutral-700" : "bg-white drop-shadow border-1 border"} flex items-center justify-center rounded-lg p-1 shadow-gray-900 mt-2.5 w-3/4 mx-auto`}>

                        <div className="mr-auto ml-auto">
                            <div className="flex items-center justify-center">
                                <div className="text-sm text-white mr-2 line-clamp-1">Services:</div>
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{machine.service.length}</div>
                            </div>
                        </div>

                        <div className="mr-auto">
                            <div className="flex items-center justify-center">
                                <div className="text-sm text-white mr-2 line-clamp-1">Total Cost:</div>
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>${numberWithCommas(totalServiceCost)}</div>
                            </div>
                        </div>

                    </div>


                </div>

            )}


            {showServiceForm && (
                <div
                    className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center animate-fadeInFast">
                    <div className="bg-bgcolor p-4 rounded-lg">

                        <button type="button" onClick={toggleServiceForm}
                                className="absolute font-bold py-1 px-1 rounded-md  focus:outline-none focus:shadow-outline">
                            <XCircleIcon className="w-6 hover:text-red-700 flex-none text-white" aria-hidden="true"/>
                        </button>

                        <div className="text-sm text-litegreen font-bold text-center mb-2 mt-4">Log Service For: {machine.name}</div>

                        <form onSubmit={handleServiceSubmit}>

                            <div className="flex items-center justify-center mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceDate">
                                    Date:
                                </label>

                                <input
                                    type="text"
                                    name="serviceDate"
                                    value={serviceData.serviceDate}
                                    onChange={handleServiceChange}
                                    placeholder="Service Date"
                                    className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                                    required
                                />
                            </div>

                            <div className="flex items-center justify-start mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceType">
                                    Type:
                                </label>

                                <select
                                    id="serviceType"
                                    name="serviceType"
                                    value={serviceData.serviceType}
                                    onChange={handleServiceChange}
                                    className="bg-bglite w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                                >
                                    <option value="">Select...</option>

                                    <option value="Oil change">Oil change</option>
                                    <option value="Filter replacement (air, oil, fuel)">Filter replacement (air, oil, fuel)</option>
                                    <option value="Blade sharpening">Blade sharpening</option>
                                    <option value="Belt replacement">Belt replacement</option>
                                    <option value="Tire replacement">Tire replacement</option>
                                    <option value="Battery check/replacement">Battery check/replacement</option>
                                    <option value="Electrical system inspection/repair">Electrical system inspection/repair</option>
                                    <option value="Brake inspection/adjustment">Brake inspection/adjustment</option>
                                    <option value="Carburetor cleaning/adjustment">Carburetor cleaning/adjustment</option>
                                    <option value="Hydraulic fluid change">Hydraulic fluid change</option>
                                    <option value="Fuel system cleaning">Fuel system cleaning</option>
                                    <option value="Engine tune-up">Engine tune-up</option>
                                    <option value="Lubrication of moving parts">Lubrication of moving parts</option>
                                    <option value="Ignition system check/repair">Ignition system check/repair</option>
                                    <option value="Cooling system flush">Cooling system flush</option>
                                    <option value="Clutch adjustment/replacement">Clutch adjustment/replacement</option>
                                    <option value="Steering system inspection/alignment">Steering system inspection/alignment</option>
                                    <option value="Transmission service">Transmission service</option>
                                    <option value="Mower deck leveling">Mower deck leveling</option>
                                    <option value="General inspection and maintenance">General inspection and maintenance</option>


                                </select>
                            </div>

                            <div className="flex items-center justify-center mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceProvider">
                                    Provider:
                                </label>

                                <input
                                    type="text"
                                    name="serviceProvider"
                                    value={serviceData.serviceProvider}
                                    onChange={handleServiceChange}
                                    placeholder="Service Provider"
                                    className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                                    required
                                />
                            </div>

                            <div className="flex items-center justify-center mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceUsage">
                                    Usage:
                                </label>

                                <input
                                    type="number"
                                    name="serviceUsage"
                                    value={serviceData.serviceUsage}
                                    onChange={handleServiceChange}
                                    className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                                    required
                                />

                                <label className="block text-white text-sm ml-5 mr-5" htmlFor="serviceUsage">
                                    {machine.usageUnit}
                                </label>
                            </div>

                            <div className="flex items-center justify-center mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceCost">
                                    Cost:
                                </label>

                                <input
                                    type="number"
                                    name="serviceCost"
                                    value={serviceData.serviceCost}
                                    onChange={handleServiceChange}
                                    placeholder="Service Cost"
                                    className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                                />
                            </div>

                            <button type="submit" className="w-full rounded-lg bg-darkgreen text-white p-1">Submit
                            </button>

                        </form>

                    </div>
                </div>
            )}


        </div>
    );
};

export default MachineCard;
