import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/authContext';
import Dashboard from './SideBar';
import { db } from '../firebase/firebase';
import { utils as xlsxUtils, write as writeXlsx } from 'xlsx';
import { saveAs } from 'file-saver';
import {Bar} from "react-chartjs-2";
import {
    ArrowDownCircleIcon,
    ArrowPathIcon, ArrowRightCircleIcon,
    DocumentArrowDownIcon, MagnifyingGlassIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {useDarkMode} from "../contexts/darkModeContext";
import EmployeeCard from "./Team/EmployeeCard";
import {
    Button,
    Dialog,
    DialogPanel,
    DialogTitle,
    Input,
    Popover,
    PopoverButton,
    PopoverPanel,
    Select
} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/16/solid";

export const employeeOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Hourly',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

export const employeeOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Salary',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

const Team = () => {
    const { currentUser } = useAuth();
    const [employees, setEmployees] = useState([]);
    const [teamSide, setTeamSide] = useState(false);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedPosition, setSelectedPosition] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        numId: '',
        position: '',
        department: '',
        phone: '',
        email: '',
        eName: '',
        ePhone: '',
        eRelation: '',
        startDate: '',
        payRate: 0,
        payType: '',
        type: '',
    });
    const [editingId, setEditingId] = useState(null);

    const { darkMode } = useDarkMode();

    const onUpdate = async () => {
        try {
            const employeeCollectionRef = db.collection('Users').doc(currentUser.uid).collection('team');
            const snapshot = await employeeCollectionRef.get();
            const teamData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setEmployees(teamData);
            setFilteredEmployees(teamData);
            localStorage.setItem(`teamData_${currentUser.uid}`, JSON.stringify(teamData));
        } catch (error) {
            console.error('Error updating employee data:', error);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            if (currentUser) {
                try {
                    const storedTeamData = localStorage.getItem(`teamData_${currentUser.uid}`);
                    if (storedTeamData) {
                        const teamData = JSON.parse(storedTeamData);
                        setEmployees(teamData);
                        setFilteredEmployees(teamData);
                    } else {
                        const teamCollectionRef = db.collection('Users').doc(currentUser.uid).collection('team');
                        const snapshot = await teamCollectionRef.get();
                        if (!snapshot.empty) {
                            const teamData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setEmployees(teamData);
                            setFilteredEmployees(teamData);
                            localStorage.setItem(`teamData_${currentUser.uid}`, JSON.stringify(teamData));
                        } else {
                            console.log('No employees found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching employees:', error);
                }
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setTeamSide(userData.teamSide);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setTeamSide(userData.teamSide);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
        fetchEmployees();
    }, [currentUser]);

    const deleteEmployeeFromFirestore = async (employeeId) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('team').doc(employeeId).delete();
            console.log('Employee deleted successfully');
            await onUpdate()
        } catch (error) {
            console.error('Error deleting employee:', error);
        }
    };

    const fillFormWithEmployeeData = (employee) => {
        setIsCollapsed(true);
        setFormData({
            name: employee.name,
            numId: employee.numId,
            position: employee.position,
            department: employee.department,
            phone: employee.phone,
            email: employee.email,
            eName: employee.eName,
            ePhone: employee.ePhone,
            eRelation: employee.eRelation,
            startDate: employee.startDate,
            payRate: employee.payRate,
            payType: employee.payType,
            type: employee.type,
        });
        setEditingId(employee.id);
        if (isCollapsed === true){
            toggleCollapse();
        }
    };

    const clearForm = () => {
        setFormData({
            name: '',
            numId: '',
            position: '',
            department: '',
            phone: '',
            email: '',
            eName: '',
            ePhone: '',
            eRelation: '',
            startDate: '',
            payRate: 0,
            payType: '',
            type: '',
        });
        setEditingId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('team');

        // If date field is empty, set it to the current date
        const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');
        const formDataWithDate = {
            ...formData,
            startDate: formData.startDate || currentDate
        };

        const parsedFormData = {
            ...formData,
            ...formDataWithDate,
            payRate: parseFloat(formData.payRate),
        };

        try {
            if (editingId) {
                await collectionRef.doc(editingId).update(parsedFormData);
                console.log('Employee updated successfully!');
                await onUpdate();
            } else {
                // eslint-disable-next-line no-unused-vars
                const docRef = await collectionRef.add(parsedFormData);
                console.log('Employee added successfully!');
                await onUpdate();
            }

            const snapshot = await collectionRef.get();
            const updatedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setEmployees(updatedData);

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating Employee: ', error);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Function to filter chems based on search query
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = employees.filter(employee =>
            employee.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredEmployees(filtered);
    };

    const filterEmployeesByPosition = (position) => {
        setSelectedPosition(position);
        const filtered = employees.filter(employee => position === '' || employee.position === position);
        setFilteredEmployees(filtered);
    };

    const calculateTotalHourly = (employee) => {
        if(employee.payType === "Hourly"){
            return (employee.payRate);
        } else {
            return 0;
        }
    };

    const calculateTotalSalary = (employee) => {
        if(employee.payType === "Salary"){
            return (employee.payRate);
        } else {
            return 0;
        }
    };

    const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');

    const downloadAsExcel = () => {
        const columnHeaders = ['department', 'email', 'name', 'numId', 'payRate', 'payType', 'phone', 'position', 'startDate', 'type', 'eName', 'ePhone', 'eRelation'];
        const worksheetData = [columnHeaders, ...employees.map(employee => [employee.department, employee.email, employee.name, employee.numId, employee.payRate, employee.payType, employee.phone, employee.position, employee.startDate, employee.type, employee.eName, employee.ePhone, employee.eRelation])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Employees');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'EmployeeData.xlsx');
    };



    const addOrUpdateButtonText = editingId ? 'Update Employee' : 'Add Employee';

    const [isCollapsed, setIsCollapsed] = useState(false); // Default value

    useEffect(() => {
        setIsCollapsed(teamSide); // Set isCollapsed to the value of chemSide from Firebase
    }, [teamSide]); // Run this effect whenever chemSide changes

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [expanded, setExpanded] = useState(false); // State to track expansion

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const calculateAverageHourly = (employees) => {
        const HourlyEmployees = employees.filter(employee => employee.payType === "Hourly");

        if (HourlyEmployees.length === 0) {
            return 0;
        }

        const totalCost = HourlyEmployees.reduce((accumulator, employee) => {
            return accumulator + employee.payRate;
        }, 0);

        const average = totalCost / HourlyEmployees.length;
        return average.toFixed(2);
    };

    const calculateAverageSalary = (employees) => {
        const SalaryEmployees = employees.filter(employee => employee.payType === "Salary");

        if (SalaryEmployees.length === 0) {
            return 0;
        }

        const totalCost = SalaryEmployees.reduce((accumulator, employee) => {
            return accumulator + employee.payRate;
        }, 0);

        const average = totalCost / SalaryEmployees.length;
        return average.toFixed(2);
    };

    const calcRates = (employees) => {
        const filteredHourEmployees = employees.filter(employee => employee.payType === "Hourly");

        return filteredHourEmployees.map(employee => ({
            name: employee.name,
            rate: employee.payRate
        }));
    };

    const calcSalaries = (employees) => {
        const filteredSalEmployees = employees.filter(employee => employee.payType === "Salary");

        return filteredSalEmployees.map(employee => ({
            name: employee.name,
            rate: employee.payRate
        }));
    };


    const rates = calcRates(filteredEmployees);
    const salaries = calcSalaries(filteredEmployees);


    const averageHourly = calculateAverageHourly(filteredEmployees);
    const averageSalary = calculateAverageSalary(filteredEmployees);

    const employeeBarChartData2 = {
        labels: salaries.map(employee => employee.name),
        datasets: [
            {
                label: 'Rate',
                backgroundColor: 'rgba(122, 197, 158, 0.2)',
                borderColor: 'rgba(122, 197, 159, 0.8)',
                borderWidth: 2,
                data: salaries.map(employee => employee.rate.toFixed(2)),
            }
        ]
    };

    const employeeBarChartData = {
        labels: rates.map(employee => employee.name),
        datasets: [
            {
                label: 'Rate',
                backgroundColor: 'rgba(85, 145, 180, 0.2)',
                borderColor: 'rgba(85, 145, 180, 1)',
                borderWidth: 2,
                data: rates.map(employee => employee.rate.toFixed(2)),
            }
        ]
    };



    return (
        <div className={`${darkMode ? "bg-bgpage text-white" : "bg-bgcolorLight text-black"} flex h-full w-full mt-16`}>


            <Dashboard/>


            <div className="w-full animate-fadeIn z-50 flex">


                <div className="content pr-3 pt-3 pb-3 w-full">


                    {/*Header*/}
                    <div className="flex flex-col items-center justify-center">

                        <div className=" flex items-center justify-center w-full flex-row">

                            <div className="w-1/6 flex items-center text-lg mr-2 tracking-widest">Employees <span
                                className={`text-xs ml-2`}>[{filteredEmployees.length}]</span>

                                <Button onClick={onUpdate}>
                                    <ArrowPathIcon
                                        className={`w-4 ml-2 text-gray-500 hover:scale-105 hover:rotate-12 hover:animate-pulse`}/>
                                </Button>

                            </div>

                            {/*Header*/}
                            <div
                                className={`relative ${darkMode ? "bg-bgDark text-white shadow-gray-900" : "bg-white text-black"} flex items-center justify-center rounded-lg p-1 w-full mr-2`}>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Total Employees:</div>
                                        <div
                                            className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>{filteredEmployees.length}</div>
                                    </div>
                                </div>


                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Total Hourly Rate:</div>
                                        <div
                                            className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>${numberWithCommas(filteredEmployees.reduce((total, employee) => total + parseFloat(calculateTotalHourly(employee)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Total Salary:</div>
                                        <div
                                            className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>${numberWithCommas(filteredEmployees.reduce((total, employee) => total + parseFloat(calculateTotalSalary(employee)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <Popover className="w-full h-full absolute z-50">
                                    <div className="w-full h-full">
                                        <div className="w-full mx-auto h-full flex items-center px-6 lg:px-8">
                                            <PopoverButton
                                                className="absolute right-0 inline-flex mr-3 items-center gap-x-1 text-xs font-semibold">
                                                <ArrowDownCircleIcon className={`w-5 ${darkMode ? '' : 'text-black'}`}/>
                                            </PopoverButton>
                                        </div>
                                    </div>

                                    <PopoverPanel
                                        transition
                                        className={`relative top-0 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} mt-3 rounded-lg shadow-xl transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in`}
                                    >
                                        <div className="mx-auto grid max-w-7xl px-6 py-6">

                                            {/*Column 1*/}

                                            <div
                                                className={`${darkMode ? "bg-bgDark shadow-gray-900" : "bg-white"} mt-2 mr-2 ml-2 p-2 flex items-center justify-center rounded-lg w-full`}>


                                                <div className="w-full h-full">
                                                    <div
                                                        className={`items-center ${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} rounded-lg mr-2 py-1 justify-center flex flex-col`}>
                                                        <div className="text-sm text-litegreen mr-2">Hourly Comparison
                                                        </div>
                                                        {/*<Bar options={chemOptions2} data={nutrientBarChartData2} className="mr-auto ml-auto"/>*/}
                                                        {/*<AgCharts options={chartOptions} className="mr-auto ml-auto"/>*/}
                                                        <Bar options={employeeOptions} data={employeeBarChartData}
                                                             className="mr-auto ml-auto"/>
                                                    </div>
                                                </div>


                                                <div className="w-full flex flex-col">

                                                    <div className="flex flex-row mb-2">

                                                        <div
                                                            className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} w-full mr-2 rounded-lg p-1`}>
                                                            <div
                                                                className="flex flex-col items-center justify-center m-1">
                                                                <div className="text-sm mb-1">Average Hourly:</div>
                                                                <div
                                                                    className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-2 py-0.5 border-solid border-green-600 bg-green-600/15`}>${numberWithCommas(averageHourly)}</div>
                                                            </div>
                                                        </div>


                                                        <div
                                                            className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} w-full mr-2 rounded-lg p-1`}>
                                                            <div
                                                                className="flex flex-col items-center justify-center m-1">
                                                                <div className="text-sm mb-1">Average Salary:</div>
                                                                <div
                                                                    className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-2 py-0.5 border-solid border-green-600 bg-green-600/15`}>${numberWithCommas(averageSalary)}</div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="flex flex-row mb-2">

                                                        <div
                                                            className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} mr-auto ml-auto rounded-lg pl-6 pr-6 pb-1 pt-1`}>
                                                            <div
                                                                className="flex flex-col items-center justify-center m-1">
                                                                <div className="text-sm mb-1">?:</div>
                                                                <div
                                                                    className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-2 py-0.5 border-solid border-green-600 bg-green-600/15`}>?
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>


                                                <div className="w-full max-w-screen-md h-full">
                                                    <div
                                                        className={`items-center ${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} rounded-lg mr-2 py-1 justify-center flex flex-col`}>
                                                        <div className="text-sm text-litegreen mr-2">Salary Comparison
                                                        </div>
                                                        <Bar options={employeeOptions2} data={employeeBarChartData2}
                                                             className="mr-auto ml-auto"/>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </PopoverPanel>
                                </Popover>


                            </div>

                            <Button onClick={downloadAsExcel}
                                    className={`${darkMode ? "text-white" : "text-gray-400"} flex font-bold rounded-lg focus:outline-none shadow-gray-900`}>
                                <DocumentArrowDownIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                            <Button className={`${darkMode ? "text-white" : "text-gray-400"} ml-2 text-3xl`}
                                    onClick={toggleCollapse}>
                                <ArrowRightCircleIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                        </div>

                    </div>

                    {/*Search Bar*/}
                    <div className={`mt-2 w-full flex flex-row`}>
                        <div className={`w-full flex flex-row items-center ${darkMode ? "bg-bgDark" : "bg-white shadow-sm"} rounded-lg px-2 mr-2`}>
                            <MagnifyingGlassIcon className={`w-4 mr-2`}/>
                            <Input
                                type="text"
                                placeholder="Search employees by name"
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                                className={`rounded-lg block w-full bg-transparent`}
                            />
                        </div>

                        <div className="w-1/4 flex flex-row items-center">
                            <Select
                                value={selectedPosition}
                                onChange={(e) => filterEmployeesByPosition(e.target.value)}
                                className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgDark dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                            >
                                <option value="">All Positions</option>

                                <option value="SuperIntendent">SuperIntendent</option>
                                <option value="GreensKeeper">GreensKeeper</option>
                                <option value="Assistant">Assistant</option>
                                <option value="Mechanic">Mechanic</option>
                                <option value="Assistant Mechanic">Assistant Mechanic</option>
                                <option value="Cart Staff">Cart Staff</option>
                                {/* Add more options as needed */}
                            </Select>

                        </div>

                    </div>

                    {/*Body*/}
                    <div className={`relative z-40 h-full mt-2 w-full rounded-lg overflow-y-auto max-h-[calc(100vh-11rem)] scrollbar-none`}>


                        {filteredEmployees.length === 0 ? (
                            // Display custom dialog if sprays array is empty
                            <div className="flex items-center justify-center h-full">
                                <div
                                    className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10`}>
                                    <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                    <p className="text-lg text-litegreen mt-2">Adding a employee</p>

                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Fill out
                                        the panel on the right hand side of the screen</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>2] Scroll
                                        down on the panel until you see the Add Chem button</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>3] Press the
                                        Add Spray button to add it to your database</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>4] If your
                                        spray does not automatically show up on the screen refresh your page and it
                                        should appear</p>

                                    <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Make sure
                                        you are connected to the Internet</p>
                                </div>
                            </div>
                        ) : (
                            // Display SprayCard components if sprays array is not empty
                            <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 auto-rows-auto`}>
                                {filteredEmployees.map((employee, index) => (
                                    <EmployeeCard key={index} employee={employee}
                                      onDelete={() => deleteEmployeeFromFirestore(employee.id)}
                                      onEdit={fillFormWithEmployeeData}
                                    />
                                ))}
                            </div>
                        )}


                    </div>


                </div>


            </div>


            <Dialog open={isCollapsed} onClose={setIsCollapsed} className={`relative z-50 ${darkMode ? 'text-white' : 'text-black'}`}>
                <div className="fixed inset-0"/>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div
                            className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 mt-[4.75rem] pb-3 pr-3">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div
                                    className={`flex h-full flex-col overflow-y-scroll ${darkMode ? 'bg-bgDark' : 'bg-white'} rounded-lg shadow-2xl`}>


                                    {/*Drawer Header*/}
                                    <div
                                        className={`${darkMode ? 'border-neutral-700' : ''} border-b-4 border-dashed px-4 py-6 sm:px-6`}>
                                        <div className="flex items-center justify-between">
                                            <Button type="button" onClick={clearForm}
                                                    className="font-bold py-1 px-1 rounded-lg mr-2 focus:outline-none focus:shadow-outline animate-fadeIn">
                                                <XCircleIcon className="w-6 hover:text-red-700 flex-none"
                                                             aria-hidden="true"/>
                                            </Button>

                                            <DialogTitle className="text-base font-semibold tracking-widest">Add
                                                Employee</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={() => setIsCollapsed(false)}
                                                    className="relative rounded-md hover:text-green-500"
                                                >
                                                    <span className="absolute -inset-2.5"/>
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon aria-hidden="true" className="size-6"/>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="mt-1">
                                            <p className="text-xs text-green-500">This is the form for adding new
                                                chemicals to your database. Once filled out you will see it appear to
                                                your left.</p>
                                        </div>
                                    </div>


                                    {/*Drawer Content*/}
                                    <div className="relative flex-1 px-4 sm:px-6">


                                        <form onSubmit={handleSubmit}
                                              className="mt-2 rounded-lg overflow-y-auto max-h-[calc(100vh-9.8rem)] scrollbar-none animate-fadeIn">

                                            <div
                                                className="text-white justify-center items-center text-center text-xs">IDENTIFIERS
                                            </div>

                                            <div className="flex items-center justify-center mt-2 mb-2">

                                                <label className="block text-white text-sm mb-2 mr-5" htmlFor="name">
                                                    Name:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5 w-1/3" htmlFor="numId">
                                                    ID Num:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="numId"
                                                    name="numId"
                                                    value={formData.numId}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div className="flex items-center justify-start mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5" htmlFor="position">
                                                    Position:
                                                </label>

                                                <select
                                                    id="position"
                                                    name="position"
                                                    value={formData.position}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bglite shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-1/2 py-1 px-3 leading-tight shadow-gray-900`}
                                                >
                                                    <option value="">Select...</option>

                                                    <option value="SuperIntendent">SuperIntendent</option>
                                                    <option value="GreensKeeper">GreensKeeper</option>
                                                    <option value="Assistant">Assistant</option>
                                                    <option value="Mechanic">Mechanic</option>
                                                    <option value="Assistant Mechanic">Assistant Mechanic</option>
                                                    <option value="Cart Staff">Cart Staff</option>

                                                </select>
                                            </div>

                                            <div className="flex items-center justify-start mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5" htmlFor="department">
                                                    Department:
                                                </label>

                                                <select
                                                    id="department"
                                                    name="department"
                                                    value={formData.department}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bglite shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-1/2 py-1 px-3 leading-tight shadow-gray-900`}
                                                >
                                                    <option value="">Select...</option>

                                                    <option value="Grounds Crew">Grounds Crew</option>
                                                    <option value="Maintance">Maintance</option>
                                                    <option value="Cart Staff">Cart Staff</option>
                                                    <option value="Admin">Admin</option>

                                                </select>
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this chemical in your
                                                database
                                            </div>


                                            <div
                                                className="text-white mt-4 justify-center items-center text-center text-xs">CONTACT
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5 w-1/3" htmlFor="phone">
                                                Phone:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="phone"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5 w-1/3" htmlFor="email">
                                                    Email:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this chemical in your
                                                database
                                            </div>


                                            <div
                                                className="text-white mt-4 justify-center items-center text-center text-xs">EMERGENCY
                                                CONTACT
                                            </div>

                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5 w-1/3" htmlFor="eName">
                                                    Name:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="eName"
                                                    name="eName"
                                                    value={formData.eName}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>

                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5 w-1/3" htmlFor="ePhone">
                                                    Phone:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="ePhone"
                                                    name="ePhone"
                                                    value={formData.ePhone}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5 w-1/3"
                                                       htmlFor="eRelation">
                                                    Relation:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="eRelation"
                                                    name="eRelation"
                                                    value={formData.eRelation}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this chemical in your
                                                database
                                            </div>


                                            <div
                                                className="text-white mt-4 justify-center items-center text-center text-xs">EMPLOYEE
                                                DATA
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5 w-1/2"
                                                       htmlFor="startDate">
                                                    Start Date:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="startDate"
                                                    name="startDate"
                                                    value={formData.startDate || currentDate}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div className="flex mb-2">
                                                <div className="w-full mr-5">
                                                    <label className="block text-white text-sm mb-2" htmlFor="payRate">
                                                        Pay Rate:
                                                    </label>

                                                    <input
                                                        type="number"
                                                        id="payRate"
                                                        name="payRate"
                                                        value={formData.payRate}
                                                        onChange={handleChange}
                                                        className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                    />
                                                </div>

                                                <div className="w-full">
                                                    <label className="block text-white text-sm mb-2" htmlFor="payType">
                                                        Type:
                                                    </label>

                                                    <select
                                                        id="payType"
                                                        name="payType"
                                                        value={formData.payType}
                                                        onChange={handleChange}
                                                        className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                    >
                                                        <option value="">Select...</option>

                                                        <option value="Salary">Salary</option>
                                                        <option value="Hourly">Hourly</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-start mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5" htmlFor="type">
                                                    Employment:
                                                </label>

                                                <select
                                                    id="type"
                                                    name="type"
                                                    value={formData.type}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                >
                                                    <option value="">Select...</option>

                                                    <option value="Full Time">Full Time</option>
                                                    <option value="Part Time">Part Time</option>
                                                    <option value="Seasonal">Seasonal</option>

                                                </select>
                                            </div>


                                            {/* Add more input fields as needed */}
                                            <div className="flex justify-center mt-4 w-full">
                                                {/* Add or Update Chem button */}
                                                <button type="submit"
                                                        className="w-full bg-darkgreen hover:bg-litegreen text-white text-sm font-bold py-1 px-4 rounded-md focus:outline-none focus:shadow-outline">
                                                    {addOrUpdateButtonText}
                                                </button>
                                            </div>


                                        </form>


                                    </div>


                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>

        </div>
    );
};

export default Team;
