export const unitConversionMap = {
    gal: { metricUnit: "L", conversionRate: 3.78541 },
    oz: { metricUnit: "mL", conversionRate: 29.5735 },
    lbs: { metricUnit: "kg", conversionRate: 0.453592 },
    ft: { metricUnit: "m", conversionRate: 0.3048 },
    in: { metricUnit: "cm", conversionRate: 2.54 },
    yd: { metricUnit: "m", conversionRate: 0.9144 },
    mi: { metricUnit: "km", conversionRate: 1.60934 }
};

/**
 * Converts a value based on the unit and metric preference.
 * @param {number} value - The numerical value to convert.
 * @param {string} unit - The unit of measurement.
 * @param {boolean} metric - Whether to convert to metric.
 * @returns {object} - An object containing the converted value and unit.
 */
export const convertUnit = (value, unit, metric) => {
    if (!value || isNaN(value)) return { value: "0.00", unit };

    if (metric && unitConversionMap[unit]) {
        const { metricUnit, conversionRate } = unitConversionMap[unit];
        return { value: (value * conversionRate).toFixed(2), unit: metricUnit };
    }

    return { value: parseFloat(value).toFixed(2), unit };
};
