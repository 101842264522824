import React, { useState, useEffect } from 'react';
import Dashboard from "./SideBar";
import {useAuth} from "../contexts/authContext";
import {db} from "../firebase/firebase";
import { Chart, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import {Pie, Bar, Doughnut} from 'react-chartjs-2';
import {useDarkMode} from "../contexts/darkModeContext";

Chart.register(ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);

export const fleetOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Machine Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Operational Usage',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

export const purchaseOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Machine Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Purchase Price',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

const FleetDash = () => {

    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    // eslint-disable-next-line
    const [fleet, setFleet] = useState([]);
    const [typeCounts, setTypeCounts] = useState({});
    const [serviceCounts, setServiceCounts] = useState({});
    const [engineCounts, setEngineCounts] = useState({});
    const [manufacturerCounts, setManufacturerCounts] = useState({});
    const [fleetBarChartData, setFleetBarChartData] = useState(null);
    const [purchaseBarChartData, setPurchaseBarChartData] = useState(null);


    useEffect(() => {
        const fetchFleet = async () => {
            if (currentUser) {
                try {
                    const fleetCollectionRef = db.collection('Users').doc(currentUser.uid).collection('fleet');
                    const snapshot = await fleetCollectionRef.get();

                    if (!snapshot.empty) {
                        const fleetData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setFleet(fleetData);

                        const barChartData = {
                            labels: fleetData.map(fleet => fleet.name), // Using document IDs as labels
                            datasets: [
                                {
                                    label: 'Operational Usage',
                                    backgroundColor: 'rgba(75, 205, 129, 0.2)',
                                    borderColor: 'rgba(75, 205, 129, 0.8)',
                                    borderWidth: 2,
                                    data: fleetData.map(machine => machine.usage)
                                }
                            ]
                        };
                        setFleetBarChartData(barChartData);

                        const purchaseChartData = {
                            labels: fleetData.map(fleet => fleet.name), // Using document IDs as labels
                            datasets: [
                                {
                                    label: 'Purchase Price',
                                    backgroundColor: 'rgba(122, 197, 158, 0.2)',
                                    borderColor: 'rgba(122, 197, 159, 0.8)',
                                    borderWidth: 2,
                                    data: fleetData.map(machine => machine.purchasePrice)
                                }
                            ]
                        };
                        setPurchaseBarChartData(purchaseChartData);

                        const counts = {};
                        fleetData.forEach(doc => {
                            const type = doc.type;
                            counts[type] = (counts[type] || 0) + 1;
                        });
                        setTypeCounts(counts);

                        const counts2 = {};
                        fleetData.forEach(doc => {
                            const services = doc.service;
                            services.forEach(service => {
                                const name = service.serviceType;
                                counts2[name] = (counts2[name] || 0) + 1;
                            });
                        });
                        setServiceCounts(counts2);

                        const counts3 = {};
                        fleetData.forEach(doc => {
                            const type = doc.engineType;
                            counts3[type] = (counts3[type] || 0) + 1;
                        });
                        setEngineCounts(counts3);

                        const counts4 = {};
                        fleetData.forEach(doc => {
                            const manu = doc.manufacturer;
                            counts4[manu] = (counts4[manu] || 0) + 1;
                        });
                        setManufacturerCounts(counts4);

                    } else {
                        console.log('No fleet found');
                    }
                } catch (error) {
                    console.error('Error fetching fleet:', error);
                }
            }
        };

        fetchFleet();
    }, [currentUser]);

    const fleetChart = {
        labels: Object.keys(typeCounts),
        datasets: [
            {
                label: '# of Machines',
                data: Object.values(typeCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],
                borderWidth: 1,
            },
        ],
    };

    const fleetChart2 = {
        labels: Object.keys(serviceCounts),
        datasets: [
            {
                label: '# of Machines',
                data: Object.values(serviceCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],
                borderWidth: 1,
            },
        ],
    };

    const fleetChart3 = {
        labels: Object.keys(engineCounts),
        datasets: [
            {
                label: '# of Machines',
                data: Object.values(engineCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],
                borderWidth: 1,
            },
        ],
    };

    const fleetChart4 = {
        labels: Object.keys(manufacturerCounts),
        datasets: [
            {
                label: '# of Machines',
                data: Object.values(manufacturerCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],
                borderWidth: 1,
            },
        ],
    };

    // const calculateTotalServiceCost = (machine) => {
    //     const totalServiceCost = machine.service.reduce((accumulator, current) => {
    //         return accumulator + current.serviceCost;
    //     }, 0).toFixed(2);
    //     return parseFloat(totalServiceCost);
    // };

    return (

        <div className={`${darkMode ? "bg-bgpage" : "bg-bgcolorLight"} flex h-screen w-full mt-16`}>

            <Dashboard/>

            <div className="flex-col w-full rounded-lg mr-3 mt-3 mb-3 overflow-y-auto max-h-[calc(100vh-5.5rem)] scrollbar-none">

                <div className="justify-center flex flex-row w-flex h-1/2 items-center ">


                    <div className="w-1/2 h-full mr-3 justify-center items-center">
                        <div className="text-litegreen w-full text-center mb-1">Operational Usage Breakdown</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full">
                                {fleet.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div
                                            className="bg-bgcolor p-4 rounded-xl shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Add Chemicals into your
                                                database!</p>
                                            <p className="text-xs text-center text-white mt-2">In order to see data
                                                displayed in these charts you must first add data to your
                                                database!</p>
                                        </div>
                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    <div className="w-full h-full items-center justify-center flex">
                                        {fleetBarChartData && <Bar options={fleetOptions} data={fleetBarChartData}/>}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Machine Type Breakdown</div>
                        <div
                            className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Pie data={fleetChart} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center">
                        <div className="text-litegreen w-full text-center mb-1">Service Instances</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Doughnut data={fleetChart2} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="justify-center flex flex-row w-flex h-1/2 items-center">


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Engine Type Breakdown</div>
                        <div
                            className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Pie data={fleetChart3} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Manufacturer Breakdown</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Doughnut data={fleetChart4} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>

                    <div className="w-1/2 h-full">
                        <div className="text-litegreen w-full text-center mb-1">Purchase Price per Machine</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full">
                                {fleet.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div
                                            className="bg-bgcolor p-4 rounded-xl shadow-lg shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Add Chemicals into your
                                                database!</p>
                                            <p className="text-xs text-center text-white mt-2">In order to see data
                                                displayed in these charts you must first add data to your
                                                database!</p>
                                        </div>
                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    <div className="w-full h-full items-center justify-center flex">
                                        {fleetBarChartData && <Bar options={purchaseOptions} data={purchaseBarChartData}/>}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                </div>


            </div>


        </div>

    );

};


export default FleetDash;
