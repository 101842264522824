import React, {useState, useEffect, useMemo, useRef} from 'react';
import { useAuth } from '../contexts/authContext';
import Dashboard from './SideBar';
import { db } from '../firebase/firebase';
import {
    faTrash,
    faEdit,
    faDownload,
    faFileExcel,
    faCaretDown,
    faCaretUp,
    faPlus,
    faMagnifyingGlass,
    faSquareCaretLeft, faSquareCaretRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { utils as xlsxUtils, write as writeXlsx } from 'xlsx';
import { saveAs } from 'file-saver';
import {Bar, Pie} from "react-chartjs-2";
import {
    ArrowUpCircleIcon,
    ArrowDownCircleIcon,
    XCircleIcon,
    TrashIcon,
    ArrowRightCircleIcon,
    DocumentArrowDownIcon,
    PlusCircleIcon,
    MagnifyingGlassCircleIcon,
    MagnifyingGlassIcon,
    BeakerIcon,
    ArrowPathIcon,
    UserGroupIcon,
    ChevronDownIcon,
    ClipboardDocumentListIcon,
    DocumentDuplicateIcon,
    PencilSquareIcon, PencilIcon
} from "@heroicons/react/24/outline";
import {AgCharts} from "ag-charts-react";
import {useDarkMode} from "../contexts/darkModeContext";
import ChemCard from "./Chems/ChemCard";
import {
    DialogPanel,
    Dialog,
    DialogTitle,
    Button,
    Select,
    Input, DialogBackdrop
} from "@headlessui/react";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

import {
    BookOpenIcon, BriefcaseIcon, CheckIcon, GlobeAltIcon,
    InformationCircleIcon,
    NewspaperIcon,
    PlusIcon, ShieldCheckIcon,
    UsersIcon, VideoCameraIcon,
    XMarkIcon
} from "@heroicons/react/16/solid";
import {doc, writeBatch} from "firebase/firestore";
import {dark} from "@mui/material/styles/createPalette";
import {Link} from "react-router-dom";
import EmployeeCard from "./Team/EmployeeCard";

const JobBoard = () => {
    const { currentUser } = useAuth();
    const { darkMode } = useDarkMode();


    const [employees, setEmployees] = useState([]);


    useEffect(() => {
        const fetchEmployees = async () => {
            if (currentUser) {
                try {
                    const storedTeamData = localStorage.getItem(`teamData_${currentUser.uid}`);
                    if (storedTeamData) {
                        const teamData = JSON.parse(storedTeamData);
                        setEmployees(teamData);
                    } else {
                        const teamCollectionRef = db.collection('Users').doc(currentUser.uid).collection('team');
                        const snapshot = await teamCollectionRef.get();
                        if (!snapshot.empty) {
                            const teamData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setEmployees(teamData);
                            localStorage.setItem(`teamData_${currentUser.uid}`, JSON.stringify(teamData));
                        } else {
                            console.log('No employees found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching employees:', error);
                }
            }
        };
        fetchEmployees();
    }, [currentUser]);



    return (
        <div className={`${darkMode ? "bg-bgpage text-white" : "bg-bgcolorLight"} flex h-screen w-full mt-16`}>


            <Dashboard/>


            {/*Body*/}
            <div className="w-full z-20 mt-3 mr-3 max-h-[calc(100vh-5.5rem)] animate-fadeIn grid grid-cols-5 gap-x-3">

                {/*Employee List*/}
                <div className={`col-span-1 rounded-lg ${darkMode ? "bg-bgDark" : "bg-white"}`}>

                    {/*Header*/}
                    <div className={`text-center flex flex-col p-1.5 border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                        <Link to={`/Team`} className={`text-xs text-white bg-green-600 hover:bg-opacity-50 hover:scale-105 w-fit rounded-md ml-auto px-2 py-0.5`}>Manage</Link>
                        <div className={`tracking-widest`}>Team</div>
                    </div>

                    {/*Array*/}
                    <div className={``}>
                        {employees.length === 0 ? (
                            // Display custom dialog if sprays array is empty
                            <div className="flex items-center justify-center h-full">
                                <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10`}>
                                    <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                    <p className="text-lg text-litegreen mt-2">Adding a employee</p>

                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Fill out the panel on the right hand side of the screen</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>2] Scroll
                                        down on the panel until you see the Add Chem button</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>3] Press the
                                        Add Spray button to add it to your database</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>4] If your
                                        spray does not automatically show up on the screen refresh your page and it
                                        should appear</p>

                                    <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Make sure
                                        you are connected to the Internet</p>
                                </div>
                            </div>
                        ) : (
                            // Display SprayCard components if sprays array is not empty
                            <div className={`grid grid-cols-1 px-3 gap-3 auto-rows-auto`}>
                                {employees.map((employee, index) => (
                                    <div key={index} className={`bg-bgcard`}>
                                        {employee.name}
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>

                </div>

                {/*Job Board*/}
                <div className={`col-span-4 rounded-lg ${darkMode ? "bg-bgDark" : "bg-white"}`}>

                    {/*Header*/}
                    <div className={`grid grid-cols-2 p-1.5 border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>

                        <div className={`flex col-span-1 flex-col`}>
                            <div className={`indent-2 flex items-center tracking-widest text-lg`}>
                                Job Board
                                <Button className={`flex ml-6 text-white hover:bg-opacity-50 items-center bg-green-600 rounded-md px-2 text-xs h-fit py-0.5`}>
                                    <PencilIcon className={`w-3 mr-2`}/>Edit
                                </Button>
                            </div>
                            <div className={`indent-8 text-gray-500 text-xs`}>Select and Assign tasks to you entire team</div>
                        </div>

                        <div className={`flex items-center col-span-1 flex-row`}>
                            <Button className={`ml-auto flex hover:bg-opacity-50 text-white items-center bg-blue-600 text-sm px-2 py-1 rounded-md h-fit`}>
                                <PlusIcon className={`w-3 mr-2`}/> Add a New Task
                            </Button>

                            <Button className={`ml-4 mr-3 flex hover:bg-opacity-50 text-white items-center bg-green-600 text-sm px-2 py-1 rounded-md h-fit`}>
                                <CheckIcon className={`w-3 mr-2`}/> Save
                            </Button>
                        </div>

                    </div>

                </div>

            </div>


        </div>
    );
};

export default JobBoard;
