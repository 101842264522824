import React, { useState } from 'react';
import Dashboard from './SideBar';
import {
    BeakerIcon,
    CalculatorIcon, CalendarDaysIcon, CalendarIcon,
    ChartBarSquareIcon, EyeIcon,
    MapIcon,
    TruckIcon,
    UserGroupIcon
} from "@heroicons/react/24/outline";
import {useDarkMode} from "../contexts/darkModeContext";

const FAQ = () => {
    const { darkMode } = useDarkMode();

    const [expandedGeneral, setExpandedGeneral] = useState({});
    const [expandedSpecific, setExpandedSpecific] = useState({});

    const toggleExpansionGeneral = (index) => {
        setExpandedGeneral((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const toggleExpansionSpecific = (index) => {
        setExpandedSpecific((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const faqChems = [
        {
            question: "How to Add a Chemical to your database?",
            answer: "Navigate to the Chemical Database and fill out the side panel to the right of the screen. Once filled out click Add Chemical to add it to your database. If the chemical does not immediately show up refresh your screen."
        },
        {
            question: "How to Edit a Chemical in your database?",
            answer: "Click on the edit button on the right hand side of the chemical card. Once pressed you will see the chemical's data fill in the fields in the side panel. Now you may edit all of the fields and when you are complete with editing the chemical you may press the Update Chem button. You should see your chemical's data now change in your database."
        },
        {
            question: "",
            answer: ""
        },
    ];

    const faqSprays = [
        {
            question: "How to Add a Spray to your database?",
            answer: "Navigate to the Spray Database and fill out the side panel to the right of the screen. Once filled out click Add Spray to add it to your database. If the Spray does not immediately show up refresh your screen."
        },
        {
            question: "How to Edit a Spray in your database?",
            answer: "Click on the edit button on the right hand side of the spray card. Once pressed you will see the spray's data fill in the fields in the side panel. Now you may edit all of the fields and when you are complete with editing the spray you may press the Update Spray button. You should see your spray's data now change in your database."
        },
        {
            question: "",
            answer: ""
        },
    ];

    return (
        <div className={`${darkMode ? "bg-bgpage" : "bg-bgcolorLight"} flex h-full w-full mt-16`}>
            <Dashboard/>

            <div className="z-20 w-full h-full flex items-center justify-center">
                {/* Add flexbox utilities to center content both horizontally and vertically */}

                <div className="w-2/3 h-1/2 flex flex-col"> {/* Set width to 2/3 and adjust height accordingly */}

                    <div className="w-full h-full flex flex-row">
                        <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-900 rounded-md w-1/4 mr-5`}>
                            <MapIcon className={`${darkMode ? "text-white" : "text-black"} w-16 mr-auto ml-auto mt-6 flex-none`} aria-hidden="true"/>
                            <div className="text-lg text-darkgreen text-center">Map</div>
                        </div>
                        <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-900 rounded-md w-1/4 mr-5`}>
                            <CalendarDaysIcon className={`${darkMode ? "text-white" : "text-black"} w-16 mr-auto ml-auto mt-6 flex-none`} aria-hidden="true"/>
                            <div className="text-lg text-darkgreen text-center">Calender</div>
                        </div>
                        <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-900 rounded-md w-1/4 mr-5`}>
                            <EyeIcon className={`${darkMode ? "text-white" : "text-black"} w-16 mr-auto ml-auto mt-6 flex-none`} aria-hidden="true"/>
                            <div className="text-lg text-darkgreen text-center">Greens Vision</div>
                        </div>
                        <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-900 rounded-md w-1/4 mr-5`}>
                            {/*<ChartBarSquareIcon className={`${darkMode ? "text-white" : "text-black"} w-16 mr-auto ml-auto mt-6 flex-none`} aria-hidden="true"/>*/}
                        </div>
                    </div>

                    <div className="w-full h-full flex flex-row mt-5">
                        <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-900 rounded-md w-1/4 mr-5`}>
                            <BeakerIcon className={`${darkMode ? "text-white" : "text-black"} w-16 mr-auto ml-auto mt-6 flex-none`} aria-hidden="true"/>
                            <div className="text-lg text-darkgreen text-center">Chemical Database</div>
                        </div>
                        <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-900 rounded-md w-1/4 mr-5`}>
                            <CalculatorIcon className={`${darkMode ? "text-white" : "text-black"} w-16 mr-auto ml-auto mt-6 flex-none`} aria-hidden="true"/>
                            <div className="text-lg text-darkgreen text-center">Spray Calculator</div>
                        </div>
                        <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-900 rounded-md w-1/4 mr-5`}>
                            <TruckIcon className={`${darkMode ? "text-white" : "text-black"} w-16 mr-auto ml-auto mt-6 flex-none`} aria-hidden="true"/>
                            <div className="text-lg text-darkgreen text-center">Fleet Management</div>
                        </div>
                        <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-900 rounded-md w-1/4 mr-5`}>
                            <UserGroupIcon className={`${darkMode ? "text-white" : "text-black"} w-16 mr-auto ml-auto mt-6 flex-none`} aria-hidden="true"/>
                            <div className="text-lg text-darkgreen text-center">Employee Management</div>
                        </div>
                    </div>

                </div>
            </div>

            {/*<div className="content p-4 flex w-full">*/}


                <div className="z-20 text-white selected-option mt-4 w-1/2 mr-5">
                    <h1 className="text-2xl font-semibold mb-4 w-full text-center">Chems FAQ</h1>
                    <div className="divide-y divide-gray-200">
                        {faqChems.map((item, index) => (
                            <div key={index} className="py-4">
                                <button
                                    className="flex justify-between items-center w-full py-2 px-4 bg-bgcolor rounded-md focus:outline-none hover:bg-bglite"
                                    onClick={() => toggleExpansionGeneral(index)}
                                >
                                    <span className="text-lg w-full text-center">{item.question}</span>
                                    <svg
                                        className={`w-5 h-5 ${expandedGeneral[index] ? 'transform rotate-180' : ''}`}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </button>
                                {expandedGeneral[index] && (
                                    <p className="mt-2">{item.answer}</p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            {/*    <div className="text-white selected-option mt-4 w-1/2">*/}
            {/*        <h1 className="text-2xl font-semibold mb-4 w-full text-center">Sprays FAQ</h1>*/}
            {/*        <div className="divide-y divide-gray-200">*/}
            {/*            {faqSprays.map((item, index) => (*/}
            {/*                <div key={index} className="py-4">*/}
            {/*                    <button*/}
            {/*                        className="flex justify-between items-center w-full py-2 px-4 bg-bgcolor rounded-md focus:outline-none hover:bg-bglite"*/}
            {/*                        onClick={() => toggleExpansionSpecific(index)}*/}
            {/*                    >*/}
            {/*                        <span className="text-lg w-full text-center">{item.question}</span>*/}
            {/*                        <svg*/}
            {/*                            className={`w-5 h-5 ${expandedSpecific[index] ? 'transform rotate-180' : ''}`}*/}
            {/*                            fill="none"*/}
            {/*                            stroke="currentColor"*/}
            {/*                            viewBox="0 0 24 24"*/}
            {/*                            xmlns="http://www.w3.org/2000/svg"*/}
            {/*                        >*/}
            {/*                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>*/}
            {/*                        </svg>*/}
            {/*                    </button>*/}
            {/*                    {expandedSpecific[index] && (*/}
            {/*                        <p className="mt-2">{item.answer}</p>*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default FAQ;
