import React, {useState, useEffect, useMemo, useRef} from 'react';
import { useAuth } from '../contexts/authContext';
import Dashboard from './SideBar';
import { db } from '../firebase/firebase';
import {
    faTrash,
    faEdit,
    faDownload,
    faFileExcel,
    faCaretDown,
    faCaretUp,
    faPlus,
    faMagnifyingGlass,
    faSquareCaretLeft, faSquareCaretRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { utils as xlsxUtils, write as writeXlsx } from 'xlsx';
import { saveAs } from 'file-saver';
import {Bar, Pie} from "react-chartjs-2";
import {
    ArrowUpCircleIcon,
    ArrowDownCircleIcon,
    XCircleIcon,
    TrashIcon,
    ArrowRightCircleIcon,
    DocumentArrowDownIcon,
    PlusCircleIcon,
    MagnifyingGlassCircleIcon,
    MagnifyingGlassIcon,
    BeakerIcon,
    ArrowPathIcon,
    UserGroupIcon,
    ChevronDownIcon,
    ClipboardDocumentListIcon,
    DocumentDuplicateIcon,
    PencilSquareIcon, ChartPieIcon, FunnelIcon
} from "@heroicons/react/24/outline";
import {AgCharts} from "ag-charts-react";
import {useDarkMode} from "../contexts/darkModeContext";
import ChemCard from "./Chems/ChemCard";
import {
    DialogPanel,
    Dialog,
    DialogTitle,
    Button,
    Select,
    Input, DialogBackdrop
} from "@headlessui/react";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

import {
    BookOpenIcon, BriefcaseIcon, CursorArrowRaysIcon, FingerPrintIcon, GlobeAltIcon,
    InformationCircleIcon,
    NewspaperIcon, PhoneIcon, PlayCircleIcon,
    PlusIcon, RectangleGroupIcon, ShieldCheckIcon, SquaresPlusIcon,
    UsersIcon, VideoCameraIcon,
    XMarkIcon
} from "@heroicons/react/16/solid";
import {doc, writeBatch} from "firebase/firestore";
import {convertUnit} from "./GlobalFunctions";

export const chemOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Occurrences',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

export const chemOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Spray Rate',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

const Chems = () => {
    const { currentUser } = useAuth();
    const [chems, setChems] = useState([]);
    const [chemSide, setChemSide] = useState(false);

    const [userName, setUserName] = useState("");

    const [filteredChems, setFilteredChems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedClassification, setSelectedClassification] = useState('');

    const [update, setUpdate] = useState(false);

    const toggleUpdate = () => {
        setUpdate(!update)
    }

    const [metric] = useState(() => {
        const storedSettings = localStorage.getItem("metric");
        return storedSettings ? JSON.parse(storedSettings).metric : false;
    });

    const { darkMode } = useDarkMode();

    const [formData, setFormData] = useState({
        name: '',
        manufacturer: '',
        classification: '',
        type: '',
        quantity: 0,
        volume: 0,
        unit: '',
        excess: 0,
        weight: 0,
        unitweight: '',
        nutrients: [],
        rate: 0,
        unitprice: 0,
    });
    const [editingChemId, setEditingChemId] = useState(null);

    const [chartOptions, setChartOptions] = useState({
        data: [],
        series: [{
            type: 'bar',
            xKey: 'name',
            yKey: 'rate',
            cornerRadius: 5,
            fill:'rgba(85, 145, 180, 0.2)',
            stroke:'rgba(85, 145, 180, 1)',
            strokeWidth:'4' }],
        height: 150,
        background: {
            visible: false,
        },
    });

    const calcRates = () => {
        return filteredChems.map(chem => ({
            name: chem.name,
            rate: chem.rate
        }));
    };

    const onUpdate = async () => {
        try {
            const chemsCollectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');
            const snapshot = await chemsCollectionRef.get();
            const chemData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            setChems(chemData);
            setFilteredChems(chemData);

            localStorage.setItem(`chemsData_${currentUser.uid}`, JSON.stringify(chemData));
        } catch (error) {
            console.error('Error updating chem data:', error);
        }
    };

    useEffect(() => {
        const fetchChems = async () => {
            if (currentUser) {
                try {
                    const storedChemsData = localStorage.getItem(`chemsData_${currentUser.uid}`);
                    if (storedChemsData) {
                        const chemsData = JSON.parse(storedChemsData);
                        setChems(chemsData);
                        setFilteredChems(chemsData);
                    } else {
                        const chemsCollectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');
                        const snapshot = await chemsCollectionRef.get();

                        if (!snapshot.empty) {
                            const chemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setChems(chemsData);
                            setFilteredChems(chemsData);
                            localStorage.setItem(`chemsData_${currentUser.uid}`, JSON.stringify(chemsData));
                        } else {
                            console.log('No chems found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching chems:', error);
                }
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setChemSide(userData.chemSide);
                        setUserName(userData.fname);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setChemSide(userData.chemSide);
                            setUserName(userData.fname);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchRates = async () => {
            const rates = calcRates();
            setChartOptions(prevOptions => ({
                ...prevOptions,
                data: rates,
            }));
        }


        fetchUserData();
        fetchChems();
        fetchRates()
    }, [currentUser]);





    const deleteChemFromFirestore = async (chemId) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('chems').doc(chemId).delete();
            console.log('Chem item deleted successfully');
            await onUpdate()
        } catch (error) {
            console.error('Error deleting chem item:', error);
        }
    };

    const duplicateChemInFirestore = async (chemId) => {
        try {
            // Get the original document
            const chemRef = db.collection('Users').doc(currentUser.uid).collection('chems').doc(chemId);
            const chemDoc = await chemRef.get();

            if (!chemDoc.exists) {
                console.error('Chem item not found');
                return;
            }

            const chemData = chemDoc.data(); // Get existing data

            // Modify the name by appending [2]
            const newName = chemData.name ? `${chemData.name} [2]` : 'Unnamed Chem [2]';

            // Create a new document with the same data but a new ID
            await db.collection('Users').doc(currentUser.uid).collection('chems').add({
                ...chemData,
                name: newName, // Update the name
            });

            console.log('Chem item duplicated successfully');
            await onUpdate(); // Refresh data if needed
        } catch (error) {
            console.error('Error duplicating chem item:', error);
        }
    };





    const fillFormWithChemData = (chem) => {
        setIsCollapsed(true);
        setFormData({
            name: chem.name,
            manufacturer: chem.manufacturer,
            classification: chem.classification,
            type: chem.type,
            quantity: chem.quantity,
            volume: chem.volume,
            unit: chem.unit,
            excess: chem.excess,
            weight: chem.weight,
            unitweight: chem.unitweight,
            nutrients: chem.nutrients,
            rate: chem.rate,
            unitprice: chem.unitprice,
        });
        setEditingChemId(chem.id);
    };

    const clearForm = () => {
        setFormData({
            name: '',
            manufacturer: '',
            classification: '',
            type: '',
            quantity: 0,
            volume: 0,
            unit: '',
            excess: 0,
            weight: 0,
            unitweight: '',
            nutrients: [],
            rate: 0,
            unitprice: 0,
        });
        setEditingChemId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');

        const parsedFormData = {
            ...formData,
            quantity: parseFloat(formData.quantity),
            volume: parseFloat(formData.volume),
            excess: parseFloat(formData.excess),
            weight: parseFloat(formData.weight),
            rate: parseFloat(formData.rate),
            unitprice: parseFloat(formData.unitprice),

        };

        try {
            if (editingChemId) {
                // Add lastUp timestamp
                parsedFormData.lastUp = new Date().getTime().toString();

                // Fetch the current document
                const docRef = collectionRef.doc(editingChemId);
                const docSnap = await docRef.get();

                if (docSnap.exists) {
                    const existingData = docSnap.data();

                    // Add to updates array or create it if it doesn't exist
                    const updates = existingData.updates || [];

                    updates.push({
                        timestamp: new Date().getTime().toString(),
                        userName: "Spencer",   // Replace with dynamic user name if needed
                        type: "Edit"
                    });

                    // Update Firestore with both the parsedFormData and the new updates array
                    await docRef.update({
                        ...parsedFormData,
                        updates
                    });

                    console.log('Chem updated successfully!');
                    await onUpdate();
                }
            } else {
                // Add initial timestamp when adding a new document
                parsedFormData.added = new Date().getTime().toString();

                // Add an empty updates array when creating a new doc
                parsedFormData.updates = [
                    {
                        timestamp: new Date().getTime().toString(),
                        userName: userName,    // Replace with dynamic user name if needed
                        type: "Created"
                    }
                ];

                // Add the new document to Firestore
                await collectionRef.add(parsedFormData);

                console.log('Chem added successfully!');
                await onUpdate();
            }

            const snapshot = await collectionRef.get();
            const updatedChemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setChems(updatedChemsData);

            setIsCollapsed(false);

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating chem: ', error);
        }
    };


    const UNIT_CONVERSIONS = {
        gal: 1,          // Gallon (no conversion needed)
        oz: 1 / 128,     // 128 oz = 1 gallon
        ml: 1 / 3785.41, // 3785.41 mL = 1 gallon
        L: 1 / 3.78541   // 3.78541 L = 1 gallon
    };

    const calculateTotalVolume = (chem) => {
        // Ensure safe number conversion and default to 0 if undefined
        const quantity = parseFloat(chem.quantity) || 0;
        const volume = parseFloat(chem.volume) || 0;
        const excess = parseFloat(chem.excess) || 0;

        // Convert volume to gallons based on the unit
        const unitConversion = UNIT_CONVERSIONS[chem.unit] || 1; // Default to 1 if unit is missing
        const volumeInGallons = volume * unitConversion;
        const excessInGallons = excess * unitConversion;

        // Calculate total volume in gallons
        return (quantity * volumeInGallons) + excessInGallons;
    };

    const calculateTotalContainer = (chem) => {
        return (chem.quantity + (chem.excess / chem.volume));
    };

    const calculateTotalExcess = (chem) => {
        return (chem.excess);
    };

    const calculateTotalPrice = (chem) => {
        return (((chem.quantity * chem.volume) + chem.excess) * chem.unitprice);
    };

    const calculateTotalWeight = (chem) => {
        return (((chem.quantity * chem.volume) + chem.excess) * chem.weight);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleNutrientChange = (index, fieldName, e) => {
        const { value } = e.target;
        // Parse the percentage value to a number
        const parsedValue = fieldName === 'percentage' ? parseFloat(value) : value;
        setFormData(prevState => ({
            ...prevState,
            nutrients: prevState.nutrients.map((nutrient, i) =>
                i === index ? { ...nutrient, [fieldName]: parsedValue } : nutrient
            )
        }));
    };



    const handleAddNutrient = () => {
        setFormData({ ...formData, nutrients: [...formData.nutrients, { name: '', percentage: '' }] });
    };

    const handleRemoveNutrient = (index) => {
        const updatedNutrients = [...formData.nutrients];
        updatedNutrients.splice(index, 1);
        setFormData({ ...formData, nutrients: updatedNutrients });
    };


    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = chems.filter(chem =>
            ['name', 'manufacturer', 'type', 'description'].some(field =>
                chem[field]?.toLowerCase().includes(query.toLowerCase())
            )
        );

        setFilteredChems(filtered);
    };



    const filterChemsByClassification = (classification) => {
        setSelectedClassification(classification);
        const filtered = chems.filter(chem => classification === '' || chem.classification === classification);
        setFilteredChems(filtered);
    };



    const downloadAsExcel = () => {
        const columnHeaders = ['Name', 'Manufacturer', 'Classification', 'Type', 'Quantity', 'Volume', 'Unit', 'Excess', 'Weight', 'Unit Weight', 'Rate', 'Unit Price', 'Total Volume', 'Total Price'];
        const worksheetData = [columnHeaders, ...chems.map(chem => [chem.name, chem.manufacturer, chem.classification, chem.type, chem.quantity, chem.volume, chem.unit, chem.excess, chem.weight, chem.unitweight, chem.rate, chem.unitprice, ((chem.quantity * chem.volume) + chem.excess).toFixed(2), (((chem.quantity * chem.volume) + chem.excess) * chem.unitprice).toFixed(2)])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Chemicals');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'ChemicalInventory.xlsx');
    };

    const addOrUpdateButtonText = editingChemId ? 'Update Chem' : 'Add Chem';

    const [isCollapsed, setIsCollapsed] = useState(false); // Default value

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const calculateNutrients = () => {
        const nutrientsMap = {};

        filteredChems.forEach(chem => {
            chem.nutrients.forEach(nutrient => {
                const { name } = nutrient;

                if (!nutrientsMap[name]) {
                    nutrientsMap[name] = 1; // Initialize count
                } else {
                    nutrientsMap[name] += 1; // Increment count
                }
            });
        });

        return Object.entries(nutrientsMap).map(([name, count]) => ({
            name,
            totalValue: count
        }));
    };





    const uniqueNutrients = calculateNutrients();

    // const rates = calcRates();
    // setChartOptions(prevOptions => ({
    //     ...prevOptions,
    //     data: rates,
    // }));


    const nutrientBarChartData = {
        labels: uniqueNutrients.map(nutrient => nutrient.name.slice(0, 1)),
        datasets: [
            {
                label: 'Occurrences',
                backgroundColor: 'rgba(122, 197, 158, 0.2)',
                borderColor: 'rgba(122, 197, 159, 0.8)',
                borderWidth: 2,
                data: uniqueNutrients.map(nutrient => nutrient.totalValue.toFixed(2)),
            }
        ]
    };

    // const nutrientBarChartData2 = {
    //     labels: rates.map(chem => chem.name.slice(0,4)),
    //     datasets: [
    //         {
    //             label: 'Rate',
    //             backgroundColor: 'rgba(85, 145, 180, 0.2)',
    //             borderColor: 'rgba(85, 145, 180, 1)',
    //             borderWidth: 2,
    //             data: rates.map(chem => chem.rate.toFixed(2)),
    //         }
    //     ]
    // };

    const handleInputChange = (id, field, value) => {
        setChems(prevChems =>
            prevChems.map(chem =>
                chem.id === id ? { ...chem, [field]: value } : chem
            )
        );
    };







    // Update Firestore for all chems at once
    const updateAllChemsInFirestore = async () => {
        if (chems.length === 0) return;

        const batch = writeBatch(db);

        try {
            chems.forEach(chem => {
                const chemRef = doc(db,"Users", currentUser.uid, "chems", chem.id);
                batch.update(chemRef, {
                    quantity: parseFloat(chem.quantity),
                    volume: parseFloat(chem.volume)
                });
            });

            await batch.commit(); // Commit all updates at once
            console.log("All chems updated successfully!");
            setUpdate(false);
            await onUpdate();
        } catch (error) {
            console.error("Error updating chems:", error);
        } finally {
            setUpdate(false);
        }
    };







    const handleChemUpdate = () => {
        console.log("Chem values updated! Refreshing parent state...");
        onUpdate();
    };

    // Store the initialNewVolumes in a ref (persists across renders)
    const initialNewVolumesRef = useRef(null);

    // Compute the initial volumes **only on first valid chems load**
    useEffect(() => {
        if (!initialNewVolumesRef.current && chems.length > 0) {
            initialNewVolumesRef.current = chems.map(chem => {
                const totalVolume = calculateTotalVolume(chem); // Convert to gallons
                return {
                    id: chem.id,
                    initialVolume: totalVolume, // Already in gallons
                    initialPrice: (((parseFloat(chem.quantity || 0) * parseFloat(chem.volume || 0)) + parseFloat(chem.excess || 0)) * parseFloat(chem.unitprice)) // Ensure unit price is a number
                };
            });
        }
    }, [chems]); // Runs when `chems` is first populated

    // Read-only initial volumes
    const initialNewVolumes = initialNewVolumesRef.current || [];
    const initialNewPrice = initialNewVolumesRef.current || [];


    // Compute Total Initial Volume (static)
    const totalInitialVolume = useMemo(() => {
        return initialNewVolumes.reduce((sum, item) => sum + item.initialVolume, 0);
    }, [initialNewVolumes]);

    const totalInitialPrice = useMemo(() => {
        return initialNewPrice.reduce((sum, item) => sum + item.initialPrice, 0);
    }, [initialNewPrice]);


    // Compute Total New Volume (dynamic, updates with user input)
    const totalNewVolume = useMemo(() => {
        return chems.reduce((sum, chem) => {
            return sum + calculateTotalVolume(chem); // Convert each to gallons before summing
        }, 0);
    }, [chems]);

    const totalNewPrice = useMemo(() => {
        return chems.reduce((sum, chem) => {
            return sum + (((parseFloat(chem.quantity || 0) * parseFloat(chem.volume || 0)) + parseFloat(chem.excess || 0)) * parseFloat(chem.unitprice || 0));
        }, 0);
    }, [chems]);

    const volumeDifference = (totalNewVolume - totalInitialVolume).toFixed(2);
    const priceDifference = (totalNewPrice - totalInitialPrice).toFixed(2);



    const solutions = [
        {
            name: 'Analytics',
            description: 'Get a better understanding of where your traffic is coming from',
            href: '#',
            icon: ChartPieIcon,
        },
        {
            name: 'Engagement',
            description: 'Speak directly to your customers with our engagement tool',
            href: '#',
            icon: CursorArrowRaysIcon,
        },
        { name: 'Security', description: "Your customers' data will be safe and secure", href: '#', icon: FingerPrintIcon },
        {
            name: 'Integrations',
            description: "Connect with third-party tools that you're already using",
            href: '#',
            icon: SquaresPlusIcon,
        },
    ]
    const callsToAction = [
        { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
        { name: 'Contact sales', href: '#', icon: PhoneIcon },
        { name: 'View all products', href: '#', icon: RectangleGroupIcon },
    ]



    return (
        <div className={`${darkMode ? "bg-bgpage text-white" : "bg-bgcolorLight"} flex h-screen w-full mt-16`}>


            <Dashboard/>


            {/*Body*/}
            <div className="w-full h-full z-20 animate-fadeIn flex">


                <div className="content pr-3 pt-3 pb-3 h-full w-full">

                    {/*Header*/}
                    <div className="flex flex-col items-center justify-center">

                        <div className=" flex items-center justify-center w-full flex-row">

                            {/*Label*/}
                            <div className="w-1/4 flex items-center text-lg mr-2 tracking-widest">Inventory

                                <Button onClick={onUpdate}>
                                    <ArrowPathIcon className={`w-4 ml-2 text-gray-500 hover:scale-105 hover:rotate-12 hover:animate-pulse`}/>
                                </Button>

                            </div>

                            {/*Header*/}
                            <div className={`relative border ${darkMode ? "bg-bgDark border-neutral-700 text-white shadow-gray-900" : "bg-white text-black"} flex items-center justify-center rounded-lg p-1 w-full mr-2`}>

                                {/*Items*/}
                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                    <div className="text-sm mr-2">Items:</div>
                                        <div className={`text-sm rounded-lg border p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>{filteredChems.length}</div>
                                    </div>
                                </div>


                                {/*Liquid Total*/}
                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Liquid:</div>
                                        <div className={`text-sm rounded-lg border p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>
                                            {numberWithCommas(
                                                filteredChems
                                                    .filter(chem => ["gal", "ml", "L", "oz"].includes(chem.unit)) // Only include valid units
                                                    .reduce((total, chem) => {
                                                        const { value } = convertUnit(parseFloat(calculateTotalVolume(chem)), chem.unit, metric);
                                                        return total + parseFloat(value);
                                                    }, 0).toFixed(2)
                                            )}
                                            <span className={`text-gray-500 ml-2 text-xs`}>
                                                {metric ? 'L' : 'gal'}
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/*Granular Total*/}
                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Granular:</div>
                                        <div className={`text-sm rounded-lg border p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>
                                            {numberWithCommas(
                                                filteredChems
                                                    .filter(chem => ["lbs"].includes(chem.unit)) // Only include valid units
                                                    .reduce((total, chem) => {
                                                        const { value } = convertUnit(parseFloat(calculateTotalVolume(chem)), chem.unit, metric);
                                                        return total + parseFloat(value);
                                                    }, 0).toFixed(2)
                                            )}
                                            <span className={`text-gray-500 ml-2 text-xs`}>
                                                {metric ? 'kg' : 'lbs'}
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/*Total Price*/}
                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Price:</div>
                                        <div className={`text-sm rounded-lg border p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>${numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalPrice(chem)), 0).toFixed(2))}</div>
                                    </div>
                                </div>



                                <Popover className="w-full h-full absolute z-50">
                                    <div className="w-full h-full">
                                        <div className="w-full mx-auto h-full flex items-center px-6 lg:px-8">
                                            <PopoverButton className="absolute right-0 inline-flex mr-3 hover:scale-110 hover:animate-heartbeat hover:text-green-600 items-center gap-x-1 text-xs font-semibold">
                                                <ArrowDownCircleIcon className={`w-5`}/>
                                            </PopoverButton>
                                        </div>
                                    </div>

                                    <PopoverPanel
                                        transition
                                        className={`relative top-0 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} mt-3 rounded-lg shadow-xl transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in`}
                                    >
                                        <div className="mx-auto grid max-w-7xl px-6 py-6">

                                            {/*Column 1*/}

                                            <div className={`${darkMode ? "bg-bgDark shadow-gray-900" : "bg-white"} mt-2 mr-2 ml-2 p-2 flex items-center justify-center rounded-lg w-full`}>


                                                <div className="w-full h-full">
                                                    <div className={`items-center ${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} rounded-lg mr-2 py-1 justify-center flex flex-col`}>
                                                        <div className="text-sm text-litegreen mr-2">Spray Rate Comparison:</div>
                                                        {/*<Bar options={chemOptions2} data={nutrientBarChartData2} className="mr-auto ml-auto"/>*/}
                                                        <AgCharts options={chartOptions} className="mr-auto ml-auto"/>

                                                    </div>
                                                </div>


                                                <div className="w-full flex flex-col">

                                                    <div className="flex flex-row mb-2">

                                                        <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} w-full mr-2 rounded-lg p-1`}>
                                                            <div className="flex flex-col items-center justify-center m-1">
                                                                <div className="text-sm mb-1">Total Containers:</div>
                                                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-2 py-0.5 border-solid border-green-600 bg-green-600/15`}>{numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalContainer(chem)), 0).toFixed(2))}</div>
                                                            </div>
                                                        </div>


                                                        <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} w-full mr-2 rounded-lg p-1`}>
                                                            <div className="flex flex-col items-center justify-center m-1">
                                                                <div className="text-sm mb-1">Total Excess:</div>
                                                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-2 py-0.5 border-solid border-green-600 bg-green-600/15`}>{numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalExcess(chem)), 0).toFixed(2))} gal</div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="flex flex-row mb-2">

                                                        <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} mr-auto ml-auto rounded-lg pl-6 pr-6 pb-1 pt-1`}>
                                                            <div className="flex flex-col items-center justify-center m-1">
                                                                <div className="text-sm mb-1">Total Weight:</div>
                                                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-2 py-0.5 border-solid border-green-600 bg-green-600/15`}>{numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalWeight(chem)), 0).toFixed(2))} lbs</div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>


                                                <div className="w-full max-w-screen-md h-full">
                                                    <div
                                                        className={`items-center ${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} rounded-lg mr-2 py-1 justify-center flex flex-col`}>
                                                        <div className="text-sm text-litegreen mr-2">Nutrient
                                                            Occurrences:
                                                        </div>
                                                        <Bar options={chemOptions} data={nutrientBarChartData}
                                                             className="ml-auto mr-auto"/>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </PopoverPanel>
                                </Popover>


                            </div>

                            <Button onClick={toggleUpdate}
                                    className={`${darkMode ? "text-white" : "text-gray-500"} hover:scale-110 hover:animate-heartbeat flex font-bold rounded-lg focus:outline-none shadow-gray-900`}>
                                <ClipboardDocumentListIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                            <Button onClick={downloadAsExcel}
                                    className={`${darkMode ? "text-white" : "text-gray-500"} hover:scale-110 hover:animate-heartbeat flex font-bold rounded-lg focus:outline-none shadow-gray-900 ml-2`}>
                                <DocumentArrowDownIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                            {/*<Button className={`${darkMode ? "text-white" : "text-gray-400"} ml-2 text-3xl`}*/}
                            {/*        onClick={toggleCollapse}>*/}
                            {/*    <ArrowRightCircleIcon className={`w-6 hover:text-green-500`}/>*/}
                            {/*</Button>*/}

                            <Button onClick={toggleCollapse}
                                className={`w-40 text-xs flex items-center text-white font-bold tracking-widest hover:bg-opacity-50 hover:scale-105 justify-center bg-green-600 rounded-md py-1 ml-2`}>
                                <PlusIcon className={`text-white w-5 mr-2`}/>
                                Add Item
                            </Button>

                        </div>

                    </div>


                    {/*Search Bar Row*/}
                    <div className={`relative w-full flex flex-row mt-2 z-20`}>

                        {/*Search Bar*/}
                        <div className={`w-full flex flex-row items-center border ${darkMode ? "bg-bgDark border-neutral-700" : "bg-white shadow-sm"} rounded-lg p-1`}>

                            <div className={`flex items-center w-full rounded-lg px-4`}>
                                <MagnifyingGlassIcon className={`w-4 mr-2`}/>
                                <Input
                                    type="text"
                                    placeholder="Search Inventory By...   Name, Manufacturer"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    className={`rounded-lg z-40 block tracking-widest py-1 text-sm w-3/4 bg-transparent focus:outline-none placeholder:text-xs`}
                                />
                            </div>


                        </div>

                        {/*Sorting Popover*/}
                        <Popover className="w-full h-full absolute z-20">
                            <div className="w-full h-full">
                                <div className="w-full mx-auto h-full flex items-center px-6 lg:px-8">
                                    <PopoverButton className="absolute hover:text-green-600 tracking-widest right-1 inline-flex mr-3 items-center gap-x-1 text-xs font-semibold">
                                        <FunnelIcon className={`w-5`}/> Filter
                                    </PopoverButton>
                                </div>
                            </div>

                            <PopoverPanel
                                transition
                                className={`z-50 relative mx-8 top-0 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} mt-2 rounded-lg shadow-xl transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in`}
                            >

                                {/*Classifications*/}
                                <div className="w-1/4 hidden flex flex-row items-center">
                                    <Select
                                        value={selectedClassification}
                                        onChange={(e) => filterChemsByClassification(e.target.value)}
                                        className={`rounded-lg block w-full p-1 border ${darkMode ? 'bg-bgDark border-neutral-700 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                    >
                                        <option value="">All Classifications</option>

                                        <option value="Fungicide">Fungicide</option>
                                        <option value="Herbicide">Herbicide</option>
                                        <option value="Pesticide">Pesticide</option>
                                        <option value="Growth Reg">Growth Reg</option>
                                        <option value="Fertilizer">Fertilizer</option>
                                        <option value="Wetting Agent">Wetting Agent</option>
                                        <option value="Micronutrient">Micronutrient</option>
                                        <option value="Biostimulants">Biostimulants</option>
                                        <option value="Soil Amends">Soil Amends</option>
                                        <option value="2nd Nutrient">2nd Nutrient</option>
                                        {/* Add more options as needed */}
                                    </Select>

                                </div>

                                {/*Top*/}
                                <div className="mx-auto grid max-w-7xl grid-cols-1 gap-2 px-6 py-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 sm:py-10 lg:grid-cols-4 lg:gap-4 lg:px-8 xl:gap-8">
                                    {solutions.map((item) => (
                                        <div
                                            key={item.name}
                                            className={`group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm/6 ${darkMode ? 'hover:bg-neutral-700' : 'hover:bg-bgcolorLight'} sm:flex-col sm:p-6`}
                                        >
                                            <div className={`flex size-11 flex-none items-center justify-center rounded-lg ${darkMode ? 'bg-bgcard' : 'bg-bgcolorLight'} group-hover:bg-white`}>
                                                <item.icon aria-hidden="true" className="size-6 text-green-600"/>
                                            </div>
                                            <div>
                                                <a href={item.href} className="font-semibold">
                                                    {item.name}
                                                    <span className="absolute inset-0"/>
                                                </a>
                                                <p className="mt-1 text-gray-600">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/*Bottom*/}
                                <div className={`rounded-b-lg ${darkMode ? 'bg-bgcard' : 'bg-bgcolorLight'}`}>
                                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                                        <div
                                            className="grid grid-cols-1 divide-y divide-gray-900/5 sm:grid-cols-3 sm:divide-x sm:divide-y-0 sm:border-x sm:border-gray-900/5">
                                            {callsToAction.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className={`flex items-center gap-x-2.5 p-3 px-6 text-sm/6 ${darkMode ? 'hover:bg-neutral-700' : 'hover:bg-neutral-300'} font-semibold rounded-lg sm:justify-center sm:px-0`}
                                                >
                                                    <item.icon aria-hidden="true"
                                                               className="size-5 flex-none text-green-600"/>
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            </PopoverPanel>

                        </Popover>

                    </div>


                    {/*Body*/}
                    <div className={` relative border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} mt-2 w-full rounded-lg h-[calc(100dvh-11.25rem)] scrollbar-none`}>


                        {/*Chemical List*/}
                        <div className={`h-full rounded-lg overflow-y-auto scrollbar-none`}>

                            {filteredChems.length === 0 ? (
                                // Display custom dialog if sprays array is empty


                                <div className="absolute flex w-full h-full justify-center items-center">
                                    <div
                                        className={`flex animate-fadeInFast flex-col items-center border-2 border-dashed border-neutral-500 ${darkMode ? "text-white" : "text-black"} w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>

                                    <BeakerIcon className={`w-12 stroke-1`}/>
                                        <h3 className="mt-2 text-sm font-semibold">No Chems</h3>
                                        <p className="mt-1 text-sm text-gray-500">Get started by creating a new Chem.</p>
                                        <div className="mt-6">
                                            <Button
                                                type="button"
                                                className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                onClick={toggleCollapse}
                                            >
                                                <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5"/>
                                                Add Chem
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                filteredChems.map((chem, index) => {
                                    return (
                                        <ChemCard
                                            key={index}
                                            chem={chem}
                                            onDelete={() => deleteChemFromFirestore(chem.id)}
                                            onDuplicate={() => duplicateChemInFirestore(chem.id)}
                                            onEdit={fillFormWithChemData}
                                            onSuccess={handleChemUpdate}
                                            userName={userName}
                                        />
                                    );
                                })
                            )}

                        </div>


                    </div>


                </div>


            </div>


            {/*Sidebar*/}
            <Dialog open={isCollapsed} onClose={setIsCollapsed} className={`relative z-50 ${darkMode ? 'text-white' : 'text-black'}`}>
                <div className="fixed inset-0" />

                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/20 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
                />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 py-3 pr-3">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className={`flex h-full flex-col overflow-y-scroll scrollbar-none border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg shadow-2xl`}>


                                    {/*Drawer Header*/}
                                    <div className={`${darkMode ? ' border-neutral-700' : ''} border-b-2 border-dashed px-4 py-4 sm:px-6`}>
                                        <div className="flex items-center justify-between">
                                            <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                                <BeakerIcon aria-hidden="true" className="size-6 text-green-600" />
                                            </div>

                                            <DialogTitle className="text-base font-semibold tracking-widest">
                                                Product Form
                                            </DialogTitle>

                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={clearForm}
                                                    className="relative rounded-md hover:text-red-500 hover:animate-heartbeat"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Clear Form</span>
                                                    <XCircleIcon aria-hidden="true" className="size-6" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>


                                    {/*Drawer Content*/}
                                    <div className="relative flex-1 px-4 sm:px-6">

                                        <form onSubmit={handleSubmit} className="mt-2 py-3 rounded-lg overflow-y-auto max-h-[calc(100vh-7rem)] scrollbar-none animate-fadeIn grid grid-cols-1 gap-x-6 gap-y-1 sm:max-w-xl sm:grid-cols-6">

                                            <div className="sm:col-span-full relative justify-center items-center text-center tracking-widest text-xs">IDENTIFIERS</div>

                                            {/*Name*/}
                                            <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Chem Name
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    placeholder="Enter Chem Name..."
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Manufacturer*/}
                                            <div className="sm:col-span-3 relative flex items-center justify-center mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Manufacturer
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="manufacturer"
                                                    name="manufacturer"
                                                    placeholder="Enter Manufacturer..."
                                                    value={formData.manufacturer}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this chemical in your
                                                database
                                            </div>

                                            <div className="sm:col-span-full justify-center items-center text-center tracking-widest text-xs">CLASSIFIERS</div>

                                            {/*Classification*/}
                                            <div className="sm:col-span-3 relative flex items-center justify-start mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Classification
                                                </label>
                                                <Select
                                                    type="text"
                                                    id="classification"
                                                    name="classification"
                                                    value={formData.classification}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select...</option>
                                                    <option value="Fungicide">Fungicide</option>
                                                    <option value="Herbicide">Herbicide</option>
                                                    <option value="Pesticide">Pesticide</option>
                                                    <option value="Growth Reg">Growth Reg</option>
                                                    <option value="Fertilizer">Fertilizer</option>
                                                    <option value="Wetting Agent">Wetting Agent</option>
                                                    <option value="Micronutrient">Micronutrient</option>
                                                    <option value="Biostimulants">Biostimulants</option>
                                                    <option value="Soil Amends">Soil Amends</option>
                                                    <option value="2nd Nutrient">2nd Nutrient</option>
                                                </Select>
                                            </div>

                                            {/*Type*/}
                                            <div className="sm:col-span-3 relative flex items-center mt-2 mb-2 justify-start">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Type
                                                </label>
                                                <Select
                                                    type="text"
                                                    id="type"
                                                    name="type"
                                                    value={formData.type}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select...</option>
                                                    <option value="Liquid">Liquid</option>
                                                    <option value="Granular">Granular</option>
                                                    <option value="Foliar">Foliar</option>
                                                    <option value="Soluable">Soluable</option>
                                                </Select>
                                            </div>

                                            <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this chemical in your
                                                database
                                            </div>

                                            <div className="sm:col-span-full justify-center items-center text-center tracking-widest text-xs">MEASUREMENTS
                                            </div>

                                            {/*Quantity*/}
                                            <div className="sm:col-span-full relative flex items-center mt-2 mb-2 justify-start">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Quantity
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="quantity"
                                                    name="quantity"
                                                    placeholder="Enter Quantity..."
                                                    value={formData.quantity}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Volume*/}
                                            <div className="sm:col-span-3 relative w-full mr-5">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Volume
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="volume"
                                                    name="volume"
                                                    placeholder="Enter Volume..."
                                                    value={formData.volume}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Unit*/}
                                            <div className="sm:col-span-3 relative w-full">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Unit
                                                </label>
                                                <Select
                                                    type="text"
                                                    id="unit"
                                                    name="unit"
                                                    value={formData.unit}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select...</option>
                                                    <option value="gal">gal</option>
                                                    <option value="oz">oz</option>
                                                    <option value="lbs">lbs</option>
                                                    <option value="ml">ml</option>
                                                    <option value="L">L</option>
                                                </Select>

                                            </div>

                                            {/*Excess*/}
                                            <div className="sm:col-span-full relative flex items-center mt-2 mb-2 justify-start">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Excess
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="excess"
                                                    name="excess"
                                                    placeholder="Enter Quantity..."
                                                    value={formData.excess}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Weight Per*/}
                                            <div className="sm:col-span-3 relative w-full mr-5">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Weight per Gal
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="weight"
                                                    name="weight"
                                                    placeholder="Enter Quantity..."
                                                    value={formData.weight}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Unit*/}
                                            <div className="sm:col-span-3 relative w-full">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Unit
                                                </label>
                                                <Select
                                                    type="text"
                                                    id="unitweight"
                                                    name="unitweight"
                                                    value={formData.unitweight}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select...</option>
                                                    <option value="lbs">lbs</option>
                                                    <option value="oz">oz</option>
                                                    <option value="kg">kg</option>
                                                    <option value="g">g</option>
                                                </Select>
                                            </div>

                                            <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This is the data that the system uses to calculate your volume</div>

                                            <div className="sm:col-span-full flex flex-row items-center justify-between">
                                                <div className="w-1/3"></div>
                                                <div className="w-1/3 text-center text-xs tracking-widest">NUTRIENTS [{formData.nutrients.length}]</div>
                                                <div className="w-1/3 flex justify-end">
                                                    <Button type="button" onClick={handleAddNutrient} className="rounded-full text-center">
                                                        <PlusCircleIcon className={`w-6 hover:text-green-500`}/>
                                                    </Button>
                                                </div>
                                            </div>

                                            {/*Nutrients*/}
                                            <div className={`sm:col-span-full min-h-32`}>


                                                {formData.nutrients.length === 0 ? (
                                                    // Display custom dialog if sprays array is empty

                                                    <div className={`w-full flex items-center justify-center`}>

                                                        <div className={`text-center flex flex-col items-center rounded-lg border-2 px-16 py-2 border-dashed ${darkMode ? 'border-neutral-700' : ''}`}>
                                                            <BeakerIcon className={`text-gray-400 w-6`}/>
                                                            <h3 className="mt-2 text-xs font-semibold">No Nutrients</h3>
                                                            <div className="mt-2">
                                                                <Button
                                                                    type="button"
                                                                    onClick={handleAddNutrient}
                                                                    className="inline-flex items-center rounded-md bg-green-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                >
                                                                    <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5"/>
                                                                    Add Nutrient
                                                                </Button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ) : (
                                                    // Display SprayCard components if sprays array is not empty
                                                    formData.nutrients.map((nutrient, index) => (
                                                        <div key={index}
                                                             className="items-center mt-3 mb-3 justify-start grid grid-cols-8 gap-x-2 gap-y-1">

                                                            {/*Name*/}
                                                            <div className={`sm:col-span-5 relative w-full`}>
                                                                <label
                                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                                >
                                                                    Nutrient
                                                                </label>
                                                                <Select
                                                                    type="text"
                                                                    value={nutrient.name}
                                                                    onChange={(e) => handleNutrientChange(index, 'name', e)}
                                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                                >
                                                                    <option value="">Select...</option>
                                                                    <option value="Potassium PolyPhosphite">Potassium
                                                                        PolyPhosphite
                                                                    </option>
                                                                    <option value="Nitrogen[n]">Nitrogen[n]</option>
                                                                    <option value="Phosphate[p2o5]">Phosphate[p2o5]
                                                                    </option>
                                                                    <option value="Potassium[k]">Potassium[k]</option>
                                                                    <option value="Calcium[ca]">Calcium[ca]</option>
                                                                    <option value="Magnesium[Mg]">Magnesium[Mg]</option>
                                                                    <option value="Sulfur[s]">Sulfur[s]</option>
                                                                    <option value="Boron[B]">Boron[B]</option>
                                                                    <option value="Cobalt[Co]">Cobalt[Co]</option>
                                                                    <option value="Copper[Cu]">Copper[Cu]</option>
                                                                    <option value="Iron[Fe]">Iron[Fe]</option>
                                                                    <option value="Manganese[Mn]">Manganese[Mn]</option>
                                                                    <option value="Molybdate[Mo]">Molybdate[Mo]</option>
                                                                    <option value="Zinc[Zn]">Zinc[Zn]</option>
                                                                    <option value="Humic Acid">Humic Acid</option>
                                                                    <option value="Carbohydrates">Carbohydrates</option>
                                                                    <option value="Amino Acid Substrates">Amino Acid
                                                                        Substrates
                                                                    </option>
                                                                    <option value="Soluble Potash">Soluble Potash
                                                                    </option>
                                                                </Select>

                                                            </div>

                                                            {/*Percent*/}
                                                            <div className={`sm:col-span-2 relative w-full`}>
                                                                <label
                                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                                >
                                                                    %
                                                                </label>
                                                                <Input
                                                                    type="number"
                                                                    placeholder="Enter %..."
                                                                    value={nutrient.percentage}
                                                                    onChange={(e) => handleNutrientChange(index, 'percentage', e)}
                                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-2 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                                />
                                                            </div>

                                                            <div className={`sm:col-span-1 flex justify-end w-full`}>
                                                                <Button type="button"
                                                                        onClick={() => handleRemoveNutrient(index)}>
                                                                    <XCircleIcon
                                                                        className={`w-6 text-red-500 hover:text-opacity-50`}/>
                                                                </Button>
                                                            </div>

                                                        </div>
                                                    ))
                                                )}

                                            </div>

                                            <div className="sm:col-span-full justify-center items-center text-center tracking-widest text-xs">APPLICATION
                                                RATES
                                            </div>

                                            {/*Rate*/}
                                            <div className="sm:col-span-full relative flex items-center mt-2 mb-2 justify-start">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Application Rate
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="rate"
                                                    name="rate"
                                                    placeholder="Enter Rate..."
                                                    value={formData.rate}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>
                                                This is the data that the system uses to Calculate your spray formulas
                                            </div>

                                            <div className="sm:col-span-full justify-center items-center text-center tracking-widest text-xs">PRICE</div>

                                            {/*Price*/}
                                            <div className="sm:col-span-full relative flex items-center mt-2 mb-2 justify-start">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Unit Price
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="unitprice"
                                                    name="unitprice"
                                                    placeholder="Enter Price..."
                                                    value={formData.unitprice}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>
                                                This is the data that the system uses to calculate your cost analysis
                                            </div>

                                            {/* Add more input fields as needed */}
                                            <div className="sm:col-span-full flex w-full justify-center mt-4">
                                                {/* Add or Update Chem button */}
                                                <Button type="submit"
                                                        className="w-full bg-green-600 tracking-widest hover:bg-opacity-50 text-white py-1 px-4 rounded-lg focus:outline-none focus:shadow-outline">
                                                    {addOrUpdateButtonText}
                                                </Button>
                                            </div>


                                        </form>

                                    </div>


                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>

            {/*Inventory Update Popup*/}
            <Dialog open={update} onClose={setUpdate} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg border ${darkMode ? 'bg-bgDark text-white border-neutral-700' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <ClipboardDocumentListIcon aria-hidden="true" className="size-6 text-green-600" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold flex items-center">
                                        Update Chemical Inventory
                                    </DialogTitle>
                                    <div className="w-full mt-6">


                                        <div className={`text-sm tracking-widest mb-1`}>Inventory [{chems.length}]</div>

                                        {/*Chems Map*/}
                                        <div className={`border-2 rounded-lg ${darkMode ? 'border-neutral-700' : ''} mb-2 p-3 space-y-4 max-h-80 overflow-y-auto scrollbar-none`}>
                                            {chems.map((chem, index) => {

                                                // Find the corresponding initial volume for this chem
                                                const initialNewVolume = initialNewVolumes.find(item => item.id === chem.id)?.initialVolume || 0;

                                                // Dynamically calculated new volume (updates when inputs change)
                                                const newVolume = calculateTotalVolume(chem);

                                                return (
                                                    <tr key={chem.id} className={`text-center border-b border-dashed ${darkMode ? 'border-neutral-700' : ''} pb-3 grid grid-cols-6 gap-x-3`}>

                                                        {/*Name*/}
                                                        <div className="col-span-1 relative flex items-center">
                                                            <div className={`text-xs`}> <span className={`text-gray-500 mr-1`}>[{index+1}]</span> {chem.name}</div>
                                                        </div>

                                                        {/*Containers*/}
                                                        <div className="col-span-1 relative flex items-center justify-center">
                                                            <label
                                                                className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                            >
                                                                Containers
                                                            </label>
                                                            <Input
                                                                type="number"
                                                                placeholder="Containers"
                                                                required
                                                                value={chem.quantity}
                                                                onChange={(e) => handleInputChange(chem.id, "quantity", e.target.value)}
                                                                className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                            />
                                                        </div>

                                                        {/*Excess*/}
                                                        <div className="col-span-1 relative flex items-center justify-center">
                                                            <label
                                                                className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                            >
                                                                Excess
                                                            </label>

                                                            <div className={`flex flex-row items-center w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                                <Input
                                                                    type="number"
                                                                    placeholder="Volume"
                                                                    required
                                                                    value={chem.excess}
                                                                    onChange={(e) => handleInputChange(chem.id, "excess", e.target.value)}
                                                                    className={`w-full ${darkMode ? 'bg-bgDark' : 'bg-white'} text-base  placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                                />

                                                                <div className={`text-xs w-fit text-gray-500`}>{chem.unit}</div>
                                                            </div>
                                                        </div>

                                                        {/*Volume*/}
                                                        <div className="col-span-1 relative flex items-center justify-center">
                                                            <label
                                                                className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                            >
                                                                Volume
                                                            </label>
                                                            <div className={`flex flex-row items-center w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                                <Input
                                                                    type="number"
                                                                    placeholder="Volume"
                                                                    required
                                                                    value={chem.volume}
                                                                    onChange={(e) => handleInputChange(chem.id, "volume", e.target.value)}
                                                                    className={`w-full ${darkMode ? 'bg-bgDark' : 'bg-white'} text-base  placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                                />

                                                                <div className={`text-xs w-fit text-gray-500`}>{chem.unit}</div>
                                                            </div>
                                                        </div>

                                                        {/*New Total*/}
                                                        <div className="col-span-1 relative flex items-center justify-center">
                                                            <label
                                                                className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                            >
                                                                Total
                                                            </label>

                                                            <div className={`flex flex-row items-center w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                                <Input
                                                                    type="number"
                                                                    placeholder="Volume"
                                                                    required
                                                                    value={parseFloat(newVolume).toFixed(2)}
                                                                    disabled={true}
                                                                    className={`w-full ${darkMode ? 'bg-bgDark' : 'bg-white'} text-base  placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                                />

                                                                <div className={`w-fit text-xs text-gray-500`}>gal</div>
                                                            </div>
                                                        </div>

                                                        {/*Change*/}
                                                        <div className="col-span-1 relative flex items-center justify-center">

                                                            <div
                                                                className={`mr-auto text-sm ${newVolume - initialNewVolume < 0 ? 'text-red-500' : 'text-green-500'}`}
                                                            >
                                                                {/*{initialNewVolume}--{newVolume}*/}
                                                                {newVolume - initialNewVolume > 0 ? `+${(newVolume - initialNewVolume).toFixed(2)}` : (newVolume - initialNewVolume).toFixed(2)} <span className={`text-xs`}>gal</span>
                                                            </div>
                                                        </div>

                                                    </tr>
                                                )
                                            })}
                                        </div>

                                        {/*Total Changes Section*/}
                                        <div className={`grid grid-cols-2 divide-x-2 divide-dashed ${darkMode ? 'divide-neutral-700' : ''}`}>

                                            {/*Totals Volume*/}
                                            <div className={`col-span-1 pr-2 w-full py-2 gap-x-3 gap-y-3 grid grid-cols-2`}>

                                                <div className={`col-span-full text-sm text-center mb-2`}>Total Volume Change</div>

                                                {/*Current Total*/}
                                                <div className="col-span-1 relative flex items-center justify-center">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Current Total
                                                    </label>
                                                    <div
                                                        className={`flex items-center w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    >
                                                        {numberWithCommas(parseFloat(totalInitialVolume.toFixed(2)))} <span className={`text-xs text-gray-500 ml-2`}>gal</span>
                                                    </div>
                                                </div>

                                                {/*New Total*/}
                                                <div className="col-span-1 relative flex items-center justify-center">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        New Total
                                                    </label>
                                                    <div
                                                        className={`flex items-center w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    >
                                                        {numberWithCommas(parseFloat(totalNewVolume.toFixed(2)))} <span className={`text-xs text-gray-500 ml-2`}>gal</span>
                                                    </div>
                                                </div>

                                                {/*Change*/}
                                                <div className="col-span-full relative flex items-center justify-center">
                                                    <p className={`text-sm tracking-widest ${volumeDifference < 0 ? "text-red-600" : "text-green-600"}`}>
                                                        {numberWithCommas(parseFloat(volumeDifference).toFixed(2))} <span className={`text-xs ml-1`}>gal</span>
                                                    </p>
                                                </div>

                                            </div>

                                            {/*Totals Price*/}
                                            <div className={`col-span-1 pl-2 w-full py-2 gap-x-3 gap-y-3 grid grid-cols-2`}>

                                                <div className={`col-span-full text-sm text-center mb-2`}>Total Price Change</div>

                                                {/*Current Total*/}
                                                <div className="col-span-1 relative flex items-center justify-center">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Current Total Price
                                                    </label>
                                                    <div
                                                        className={`flex items-center w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    >
                                                        $ {numberWithCommas(parseFloat(totalInitialPrice).toFixed(2))} <span className={`text-xs text-gray-500 ml-2`}>USD</span>
                                                    </div>
                                                </div>

                                                {/*New Total*/}
                                                <div className="col-span-1 relative flex items-center justify-center">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        New Total Price
                                                    </label>
                                                    <div
                                                        className={`flex items-center w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    >
                                                        $ {numberWithCommas(parseFloat(totalNewPrice).toFixed(2))} <span className={`text-xs text-gray-500 ml-2`}>USD</span>
                                                    </div>

                                                </div>

                                                {/*Change*/}
                                                <div className="col-span-full relative flex items-center justify-center">
                                                    <p className={`text-sm tracking-widest ${priceDifference < 0 ? "text-red-600" : "text-green-600"}`}>
                                                        $ {numberWithCommas(parseFloat(priceDifference).toFixed(2))} <span className={`text-xs ml-1`}>USD</span>
                                                    </p>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <Button
                                    type="button"
                                    onClick={updateAllChemsInFirestore}
                                    // onClick={() => updateValues(chem.id, onSuccess)}
                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                >
                                    Update All
                                </Button>
                                <Button
                                    type="button"
                                    onClick={toggleUpdate}
                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-bgpage' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>

            </Dialog>


        </div>
    );
};

export default Chems;
