import React, {useEffect, useState} from 'react'
import { Navigate, Link } from 'react-router-dom'
import { doSignInWithEmailAndPassword, doPasswordReset } from '../firebase/auth' // Import password reset function
import { useAuth } from '../contexts/authContext'
import {db} from "../firebase/firebase";
import {Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle, Input} from "@headlessui/react";
import {ArrowLongLeftIcon} from "@heroicons/react/24/outline";
import logo from "../GROLINKZ_LOGO_WHITE.png"
import course from "../photos/course.jpg";
import {ExclamationTriangleIcon} from "@heroicons/react/16/solid";
import {LockClosedIcon} from "@heroicons/react/20/solid";

const Login = () => {
    const { userLoggedIn } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [defaultPage, setDefaultPage] = useState('')

    const [isSigningIn, setIsSigningIn] = useState(false)
    const [resetEmail, setResetEmail] = useState('')
    const [showResetPopup, setShowResetPopup] = useState(false)
    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            await doSignInWithEmailAndPassword(email, password)
        }
    }

    useEffect(() => {
        const fetchUserData = async () => {
            if (userLoggedIn) {
                try {
                    const userDoc = await db.collection('Users').doc(userLoggedIn.uid).get();
                    if (userDoc.exists) {
                        const userData = userDoc.data();
                        const userDefaultPage = userData.defaultPage || '';
                        console.log('User defaultPage:', userDefaultPage);
                        setDefaultPage(userDefaultPage);
                    } else {
                        console.log('User document not found');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
    }, [userLoggedIn]);

    console.log('Default page:', defaultPage);

    const handleResetSubmit = async (e) => {
        e.preventDefault()
        try {
            await doPasswordReset(resetEmail)
            alert('Password reset email sent! Please check your inbox.')
            setShowResetPopup(false)
        } catch (error) {
            console.error('Error resetting password:', error.message)
        }
    }

    return (
        <div className={`w-full h-screen bg-gray-900/50 overflow-y-hidden`}>

            {userLoggedIn && (
                defaultPage === 'Calendar' ? <Navigate to={'/Calendar'} replace={true}/> :
                    defaultPage === 'Home' ? <Navigate to={'/Home'} replace={true}/> :
                        defaultPage === 'Chemical Database' ? <Navigate to={'/Chems'} replace={true}/> :
                            defaultPage === 'Spray Database' ? <Navigate to={'/Sprays'} replace={true}/> :
                                // Add more conditions for other default pages as needed
                                <Navigate to={'/Home'} replace={true}/> // Default to home page if defaultPage is null or empty
            )}

            <Link to={'/landing'} className="animate-slideInLeft absolute flex top-4 hover:text-green-600 text-white left-4 text-sm p-2 rounded-full transition duration-200">
                <ArrowLongLeftIcon className={`w-4 mr-2`}/>
                Return to Home
            </Link>


            <img
                alt=""
                src={course}
                className="absolute inset-0 object-cover -z-10 size-full"
            />

            <div
                aria-hidden="true"
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-green-600 to-green-400 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                />
            </div>


            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="animate-slideInTop sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        alt="Your Company"
                        src={logo}
                        className="mx-auto h-14 w-auto"
                    />
                    <h2 className="mt-6 text-center tracking-widest text-2xl/9 text-white">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 animate-slideInBottom sm:mx-auto sm:w-full sm:max-w-[480px]">

                    <div className="bg-white px-3 py-6 shadow sm:rounded-lg sm:px-6">
                        <form action="#" onSubmit={onSubmit} method="POST" className="space-y-5 mt-2">

                            {/*Email*/}
                            <div className="relative flex items-center justify-center">
                                <label
                                    className={`absolute bg-white -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                >
                                    Email
                                </label>
                                <Input
                                    type="email"
                                    id="manufacturer"
                                    name="manufacturer"
                                    placeholder="Enter Email..."
                                    autoComplete="email"
                                    required
                                    value={email} onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                    className={`block w-full bg-white outline-gray-300 rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>

                            {/*Password*/}
                            <div className="relative flex items-center justify-center">
                                <label
                                    className={`absolute bg-white -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                >
                                    Password
                                </label>
                                <Input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Enter Password..."
                                    autoComplete="current-password"
                                    required
                                    value={password} onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                    className={`block w-full bg-white outline-gray-300 rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>


                            <div className="flex items-center justify-between">

                                <div className="ml-auto text-sm/6">
                                    <Button onClick={() => setShowResetPopup(true)}
                                            className="font-semibold hover:text-gray-500">
                                        Forgot password?
                                    </Button>
                                </div>

                            </div>

                            <div>
                                <button
                                    type="submit"
                                    disabled={isSigningIn}
                                    className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Sign in
                                </button>
                            </div>
                        </form>

                    </div>

                    <p className="mt-10 text-center text-sm/6 text-white tracking-widest">
                        Dont have an account?{' '}
                        <a href="/register" className="font-semibold text-green-600 hover:text-opacity-50">
                            Sign Up Now!
                        </a>
                    </p>
                </div>
            </div>


            <Dialog open={showResetPopup} onClose={setShowResetPopup} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                                    <LockClosedIcon aria-hidden="true" className="size-6 text-red-600" />
                                </div>
                                <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                        Reset Password
                                    </DialogTitle>
                                    <div className="w-full mt-2">
                                        <input
                                            type="email"
                                            placeholder="Enter your email"
                                            value={resetEmail}
                                            onChange={(e) => setResetEmail(e.target.value)}
                                            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-red-600 sm:text-sm/6"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={handleResetSubmit}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    Reset Password
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setShowResetPopup(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>



        </div>
    )
}

export default Login
