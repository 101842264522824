import React, {useEffect, useState} from 'react';
import Dashboard from './SideBar';
import { useAuth } from "../contexts/authContext";
import { db } from "../firebase/firebase";
import {useDarkMode} from "../contexts/darkModeContext";
import {BeakerIcon, CalculatorIcon, TruckIcon, UserGroupIcon} from "@heroicons/react/24/outline";


const Home = () => {
    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    const [chems, setChems] = useState([]);
    const [sprays, setSprays] = useState([]);
    const [fleet, setFleet] = useState([]);
    const [employees, setEmployees] = useState([]);

    const [address, setAddress] = useState('');

    const [greens, setGreens] = useState('');
    const [surrounds, setSurrounds] = useState('');
    const [collars, setCollars] = useState('');
    const [fairways, setFairways] = useState('');
    const [rough, setRough] = useState('');
    const [tees, setTees] = useState('');

    const [mapCenter, setMapCenter] = useState(null);
    const mapZoom = 16;

    const mapOptions = {
        mapTypeId: 'satellite',
    };


    useEffect(() => {

        const fetchChems = async () => {
            if (currentUser) {
                try {
                    const storedChemsData = localStorage.getItem(`chemsData_${currentUser.uid}`);
                    if (storedChemsData) {
                        const chemsData = JSON.parse(storedChemsData);
                        setChems(chemsData);
                    } else {
                        setChems([]);
                    }
                } catch (error) {
                    console.error('Error fetching chems:', error);
                }
            }
        };

        const fetchEmployees = async () => {
            if (currentUser) {
                try {
                    const storedTeamData = localStorage.getItem(`teamData_${currentUser.uid}`);
                    if (storedTeamData) {
                        const teamData = JSON.parse(storedTeamData);
                        setEmployees(teamData);
                    } else {
                        setEmployees([]);
                    }
                } catch (error) {
                    console.error('Error fetching employees:', error);
                }
            }
        };

        const fetchSprays = async () => {
            if (currentUser) {
                try {
                    const storedSpraysData = localStorage.getItem(`spraysData_${currentUser.uid}`);
                    if (storedSpraysData) {
                        const spraysData = JSON.parse(storedSpraysData);
                        setSprays(spraysData);
                    } else {
                        setSprays([]);
                    }
                } catch (error) {
                    console.error('Error fetching sprays:', error);
                }
            }
        };

        const fetchFleet = async () => {
            if (currentUser) {
                try {
                    const storedFleetData = localStorage.getItem(`fleetData_${currentUser.uid}`);
                    if (storedFleetData) {
                        const fleetData = JSON.parse(storedFleetData);
                        setFleet(fleetData);
                    } else {
                        setFleet([]);
                    }
                } catch (error) {
                    console.error('Error fetching fleet:', error);
                }
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    // Fetch user document from Firestore based on the current user's UID
                    const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                    if (userDoc.exists) {
                        // Get the user data from the document
                        const userData = userDoc.data();

                        setAddress(userData.address || ""); // Default to false if metric field doesn't exist

                        setGreens(userData.greens || 0); // Default to false if metric field doesn't exist
                        setSurrounds(userData.surrounds || 0); // Default to false if metric field doesn't exist
                        setCollars(userData.collars || 0); // Default to false if metric field doesn't exist
                        setFairways(userData.fairways || 0); // Default to false if metric field doesn't exist
                        setRough(userData.rough || 0); // Default to false if metric field doesn't exist
                        setTees(userData.tees || 0); // Default to false if metric field doesn't exist


                    } else {
                        console.log('User document not found');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();

        fetchSprays();
        fetchChems();
        fetchEmployees();
        fetchFleet();
    }, [currentUser, address]);



    const projects = [
        { name: 'Product Inventory', href: '/Chems', members: chems.length, icon: BeakerIcon, label: 'Products' },
        { name: 'Spray History', href: '/Sprays', members: sprays.length, icon: CalculatorIcon, label: 'Sprays' },
        { name: 'Fleet Size', href: '/Fleet', members: fleet.length, icon: TruckIcon, label: 'Machines' },
        { name: 'Team Size', href: '/Team', members: employees.length, icon: UserGroupIcon, label: 'Employees' },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }



    return (
        <div className={`${darkMode ? "bg-bgpage text-white" : "bg-bgcolorLight text-black"} flex h-screen w-full mt-16`}>
            <Dashboard/>
            <div className="z-20 content w-full flex flex-col mr-3 animate-fadeIn">


                {/*Totals*/}
                <div className={`mb-3`}>
                    <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
                        {projects.map((project) => (
                            <li key={project.name} className="col-span-1 flex rounded-md shadow-sm">
                                <div
                                    className={'flex bg-green-600 w-16 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'}
                                >
                                    <project.icon aria-hidden="true" className="size-6 text-white"/>
                                </div>
                                <div
                                    className={`flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t ${darkMode ? 'bg-bgDark border-neutral-700 text-white' : 'bg-white text-black'}`}>
                                    <div className="flex-1 truncate px-4 py-2 text-sm">
                                        <a href={project.href}
                                           className="font-medium hover:tracking-widest">
                                            {project.name}
                                        </a>
                                        <p className="text-gray-500">{project.members} {project.label}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>


                <div className={`grid h-full max-h-[calc(100vh-9.75rem)] grid-cols-6 grid-rows-4 gap-3`}>

                    <div className={`col-span-6 col-start-1 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>

                    </div>
                    <div className={`col-span-2 row-span-3 col-start-1 row-start-2 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>2</div>
                    <div className={`col-span-4 row-span-2 col-start-3 row-start-2 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>3</div>
                    <div className={`col-span-2 col-start-3 row-start-4 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>4</div>
                    <div className={`col-span-2 col-start-5 row-start-4 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} rounded-lg`}>5</div>
                </div>


            </div>
        </div>
    );
};

export default Home;


//AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E
