import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import Header from "./Header";
import {
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
    BeakerIcon,
    ChartPieIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    UserIcon,
    CalculatorIcon,
    TruckIcon,
    UserGroupIcon,
    DocumentChartBarIcon,
    ChartBarIcon,
    UserPlusIcon,
    GlobeAmericasIcon, WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import {useDarkMode} from "../contexts/darkModeContext";
import {Button} from "@headlessui/react";
// import PFC from "../PFC_Logo.png"
import PFC from "../PlantFood.svg"
// import imageSRC from "../IconBare.png";
import imageSRC from "../GROLINKZ_ICON.png";
import imageSRCFull from "../GROLINKZ_LOGO_WHITE.png";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";


const Dashboard = () => {
    // Get the current pathname
    // eslint-disable-next-line
    const [currentPath, setCurrentPath] = useState(window.location.pathname); // Initialize currentPath

    const { darkMode } = useDarkMode();

    const { currentUser } = useAuth();

    const [company, setCompany] = useState("");

    useEffect(() => {

        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setCompany(userData.company);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setCompany(userData.company);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();

    }, [currentUser]);



    const [collapse, setCollapse] = useState(() => {
        const savedState = localStorage.getItem('collapseState');
        return savedState === null ? false : JSON.parse(savedState);
    });

    const toggleCollapse = () => {
        setCollapse(prevCollapse => {
            const newCollapse = !prevCollapse;
            localStorage.setItem('collapseState', JSON.stringify(newCollapse));
            return newCollapse;
        });
    };

    const menuItems = [
        { path: "/Home", label: "Home", Icon: HomeIcon, disabled: false },
        { path: "/Map", label: "Map", Icon: GlobeAmericasIcon, disabled: false },
        // { path: "/Calendar", label: "Calendar", Icon: CalendarDaysIcon, disabled: false },
        // { path: "/Vision", label: "Greens Vision", Icon: EyeIcon, disabled: false },
        { path: "", label: "PlantFood Connect", Icon: '', disabled: true },
        { path: "/Account", label: "Account", Icon: UserIcon, disabled: false },


        { path: "/Chems", label: "Product Inventory", Icon: BeakerIcon, disabled: false },
        { path: "/Sprays", label: "Spray Calculator", Icon: CalculatorIcon, disabled: false },
        { path: "", label: "Project Management", Icon: WrenchScrewdriverIcon, disabled: true },
        { path: "/Fleet", label: "Fleet Management", Icon: TruckIcon, disabled: false },
        { path: "/Team", label: "Team Management", Icon: UserGroupIcon, disabled: false },

        { path: "/ChemsDash", label: "Chemical Dashboard", Icon: ChartPieIcon, disabled: false },
        { path: "/SpraysDash", label: "Spray Dashboard", Icon: DocumentChartBarIcon, disabled: false },
        { path: "/FleetDash", label: "Fleet Dashboard", Icon: ChartBarIcon, disabled: false },
        { path: "/TeamDash", label: "Employee Dashboard", Icon: UserPlusIcon, disabled: false },

        { path: "/FAQ", label: "FAQ", Icon: QuestionMarkCircleIcon, disabled: false },
    ]

    const renderLink = ({ path, label, Icon, disabled }) => {
        return (
            <Link
                to={disabled ? "#" : path} // Prevent navigation if disabled
                key={label}
                className={`flex items-center p-2 rounded-r-lg hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 hover:border-l-2 hover:border-green-600 mr-auto ml-auto ${
                    currentPath === path
                        ? "bg-green-600/25 border-l-2 border-green-600 bg-opacity-10 shadow-inner shadow-gray-900"
                        : "bg-transparent"
                } ${collapse ? "justify-center" : "w-11/12 mb-1"} ${
                    disabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
            >
                <div className={`w-5 h-5 rounded bg-green-600 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${
                    collapse ? "" : "mr-2"
                }`}
                >
                    {Icon ? (
                        <Icon className="text-white w-4 flex-none" aria-hidden="true" />
                    ) : (
                        <span className="text-white flex-none">
                            <img src={PFC} alt="" className="h-5"/>
                        </span>
                    )}
                </div>
                <div className={`text-white text-xs line-clamp-1 ${collapse ? "hidden" : ""}`}>{label}</div>
            </Link>
        );
    };


    return (

        <div className={`flex flex-col justify-between ${collapse ? 'w-14 my-3 ml-3 mr-2' : 'w-80 p-3'}`}>

            <Header/>

            <div className={`w-full z-20 text-white border ${darkMode ? "bg-bgDark border-neutral-700" : "bg-bgcolorGreen"} rounded-md shadow-inner shadow-gray-750 h-full max-h-[calc(100vh-5.5rem)] scrollbar-none`}>

                <Button
                    className="mt-1.5 p-0.5 text-xl z-50 absolute left-1 text-white font-bold hover:animate-heartbeat bg-green-600 w-8 h-8 rounded-full"
                    onClick={toggleCollapse}>
                    {collapse ? <ArrowRightCircleIcon/> : <ArrowLeftCircleIcon/>}
                </Button>


                {/*Logo Section*/}
                <div className={`${collapse ? 'mt-11' : 'mt-3'} w-full flex items-center justify-center`}>
                    {collapse ? (
                        <Link className='' to={'/landing'}>
                            <img src={imageSRC} alt="" className={`mb-1 w-12 hover:animate-pulse`}/>
                        </Link>
                    ) : (
                        <div className={`w-full flex flex-col mb-1 items-center justify-center`}>
                            <Link className='' to={'/landing'}>
                                <img src={imageSRCFull} alt="" className={`mb-2 h-12 hover:animate-pulse`}/>
                            </Link>
                            <div className={`text-xs my-1 tracking-widest text-center text-white bg-green-600/25 w-full`}>{company}</div>
                        </div>
                    )}
                </div>


                {/*Links*/}
                <div className={`menu overflow-y-auto max-h-[calc(100vh-10.8rem)] scrollbar-none justify-center items-center p-1.5`}>

                    <div className={`relative text-xs text-center ml-1.5 mr-1.5 mt-1 mb-3`}>
                        <div className={`absolute inset-0 flex items-center justify-center`}>
                            <div
                                className={`${darkMode ? 'bg-bgDark' : 'bg-bgcolorGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider text-small`}>Main
                            </div>
                        </div>

                        <div className={`bg-bgcolorLiteGreen w-full rounded-full h-0.5`}></div>
                    </div>


                    <>
                        {menuItems.slice(0, 4).map(renderLink)}
                    </>


                    {/*----------------------------------------------------------------------*/}

                    <div className={`relative text-xs text-center ml-1.5 mr-1.5 mt-3 mb-3`}>
                        <div className={`absolute inset-0 flex items-center justify-center`}>
                            <div
                                className={`${darkMode ? 'bg-bgDark' : 'bg-bgcolorGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider text-small`}>Calc
                            </div>
                        </div>

                        <div className={`bg-bgcolorLiteGreen w-full rounded-full h-0.5`}></div>
                    </div>

                    <>
                        {menuItems.slice(4, 9).map(renderLink)}
                    </>


                    {/*----------------------------------------------------------------------*/}

                    <div className={`relative text-xs text-center ml-1.5 mr-1.5 mt-3 mb-3`}>
                        <div className={`absolute inset-0 flex items-center justify-center`}>
                            <div
                                className={`${darkMode ? 'bg-bgDark' : 'bg-bgcolorGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider text-small`}>Data
                            </div>
                        </div>

                        <div className={`bg-bgcolorLiteGreen w-full rounded-full h-0.5`}></div>
                    </div>


                    <>
                        {menuItems.slice(9, 13).map(renderLink)}
                    </>


                    {/*----------------------------------------------------------------------*/}

                    <div className={`relative text-xs text-center ml-1.5 mr-1.5 mt-3 mb-3`}>
                        <div className={`absolute inset-0 flex items-center justify-center`}>
                            <div
                                className={`${darkMode ? 'bg-bgDark' : 'bg-bgcolorGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider text-small`}>Tools
                            </div>
                        </div>

                        <div className={`bg-bgcolorLiteGreen w-full rounded-full h-0.5`}></div>
                    </div>


                    <>
                        {menuItems.slice(13, 18).map(renderLink)}
                    </>


                </div>


            </div>


        </div>
    );
};

export default Dashboard;

