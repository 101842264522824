import React, { useEffect, useState } from 'react';
import Dashboard from './SideBar';
import { useAuth } from '../contexts/authContext';
import { db, storage } from '../firebase/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {useDarkMode} from "../contexts/darkModeContext";
import {
    BuildingOffice2Icon,
    CheckBadgeIcon,
    ChevronDownIcon,
    MapPinIcon
} from "@heroicons/react/24/outline";
import {Button, Field, Input, Label, Switch} from "@headlessui/react";
import {PhoneIcon} from "@heroicons/react/16/solid";

const Account = () => {
    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');
    const [defaultPage, setDefaultPage] = useState('');
    const [profileImage, setProfileImage] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');


    // const [metric, setMetric] = useState(false);
    const [dark, setDark] = useState(true);

    const [chem, setChem] = useState(true);
    const [spray, setSpray] = useState(true);
    const [fleet, setFleet] = useState(true);
    const [team, setTeam] = useState(true);


    const [greens, setGreens] = useState(0);
    const [surrounds, setSurrounds] = useState(0);
    const [collars, setCollars] = useState(0);
    const [fairways, setFairways] = useState(0);
    const [rough, setRough] = useState(0);
    const [tees, setTees] = useState(0);

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    // Fetch user document from Firestore based on the current user's UID
                    const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                    if (userDoc.exists) {
                        // Get the user data from the document
                        const userData = userDoc.data();
                        setFirstName(userData.fname);
                        setLastName(userData.lname);
                        setCompany(userData.company);
                        setTitle(userData.title);
                        setProfileImage(userData.profileImage);
                        setAddress(userData.address || ""); // Default to false if metric field doesn't exist

                        setDark(userData.dark || true); // Default to false if metric field doesn't exist
                        setDefaultPage(userData.defaultPage || "");

                        setChem(userData.chemSide || false); // Default to false if metric field doesn't exist
                        setSpray(userData.spraySide || false); // Default to false if metric field doesn't exist
                        setFleet(userData.fleetSide || false); // Default to false if metric field doesn't exist
                        setTeam(userData.teamSide || false); // Default to false if metric field doesn't exist


                        setGreens(userData.greens || 0);
                        setSurrounds(userData.surrounds || 0);
                        setCollars(userData.collars || 0);
                        setFairways(userData.fairways || 0);
                        setRough(userData.rough || 0);
                        setTees(userData.tees || 0);


                        localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));

                    } else {
                        console.log('User document not found');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
    }, [currentUser]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSavePreferences = async () => {
        if (currentUser) {
            try {
                let downloadURL = null;

                // Check if a file is selected for upload
                if (file) {
                    setUploading(true);
                    const storageRef = ref(storage, `images/${file.name}`);
                    await uploadBytes(storageRef, file);
                    downloadURL = await getDownloadURL(storageRef);
                    setUploading(false);
                }

                // Update user document in Firestore with new data
                await db.collection('Users').doc(currentUser.uid).update({
                    fname: firstName,
                    lname: lastName,
                    company: company,
                    title: title,
                    address: address,
                    metric: metric, // Update metric value in Firestore
                    dark: dark,
                    defaultPage: defaultPage,
                    chemSide: chem,
                    spraySide: spray,
                    fleetSide: fleet,
                    teamSide: team,
                    greens: parseFloat(greens),
                    surrounds: parseFloat(surrounds),
                    collars: parseFloat(collars),
                    fairways: parseFloat(fairways),
                    rough: parseFloat(rough),
                    tees: parseFloat(tees),
                    // Include the image URL if a file was uploaded
                    ...(downloadURL && { profileImage: downloadURL })
                });

                console.log('User data updated successfully');
                setSuccess('User data updated successfully');
            } catch (error) {
                console.error('Error updating user data:', error);
                setError(`Error updating user data: ${error.message}`);
            }
        } else {
            setError('No user is logged in.');
        }
    };



    const localStorageKey = 'metric';

    const [metric, setMetric] = useState(() => {
        const storedSettings = localStorage.getItem(localStorageKey);
        return storedSettings ? JSON.parse(storedSettings).metric : false;
    });

    useEffect(() => {
        localStorage.setItem(
            localStorageKey,
            JSON.stringify({ metric })
        );
        console.log("Changes Saved");
    }, [metric]);


    return (
        <div className={`${darkMode ? "bg-bgpage text-white" : "bg-bgcolorLight text-black"} flex h-full w-full mt-16`}>
            <Dashboard/>


            <div className="z-20 w-full h-full grid grid-cols-3 grid-rows-1 gap-3 animate-fadeIn">


                {/*Column Left     ACCOUNT INFO*/}
                <div className={`col-start-1 mt-3 p-3 ${darkMode ? 'bg-bgDark' : 'bg-white'} overflow-y-auto overflow-x-hidden rounded-lg max-h-[calc(100vh-5.5rem)] scrollbar-none`}>
                    <div className={`w-full h-full`}>

                        <div className="justify-center h-full items-center flex flex-col">


                            {/*Image*/}
                            <div className="w-48 h-48 mt-4 rounded-full bg-bgcard flex items-center justify-center shadow-inner shadow-gray-900 mb-3">
                                {/*<FontAwesomeIcon icon={faUser} className=" text-8xl"/>*/}

                                <img
                                    src={profileImage}
                                    alt="Profile"
                                    className="object-cover w-full h-full rounded-full"
                                    style={{minWidth: '100%', minHeight: '100%'}}
                                />

                            </div>


                            <div className={`border-2 flex flex-col ${darkMode ? 'border-neutral-700' : ''} h-full rounded-md flex p-1 w-full`}>

                                <div className={`text-xl w-full flex flex-col items-center border-b-2 ${darkMode ? 'border-neutral-700' : ''} mb-3`}>
                                    <div className="tracking-widest line-clamp-1">{`${firstName} ${lastName}`}</div>
                                    <div className="font-extralight text-xs text-gray-500">{title}</div>
                                </div>

                                <div className={`w-full h-full flex flex-col p-3`}>

                                    {/*Title*/}
                                    <div className="w-full flex flex-row items-center mt-auto mb-auto">

                                        <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                            <CheckBadgeIcon className="w-5"/>
                                        </div>

                                        <div className="text-sm">{title || "- - - - -"}</div>

                                        <div className="ml-auto text-sm">Title</div>
                                    </div>

                                    {/*Company*/}
                                    <div className="w-full flex flex-row items-center mt-auto mb-auto">

                                        <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                            <BuildingOffice2Icon className="w-5"/>
                                        </div>

                                        <div className="text-sm">{company || "- - - - -"}</div>

                                        <div className="ml-auto text-sm">Company</div>
                                    </div>

                                    {/*Address*/}
                                    <div className="w-full flex flex-row items-center mt-auto mb-auto">

                                        <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                            <MapPinIcon className="w-5"/>
                                        </div>

                                        <div className="text-sm">{address || "- - - - -"}</div>

                                        <div className="ml-auto text-sm">Address</div>
                                    </div>

                                    {/*Phone*/}
                                    <div className="w-full flex flex-row items-center mt-auto mb-auto">

                                        <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                            <PhoneIcon className="w-5"/>
                                        </div>

                                        <div className="text-sm">- - - - </div>

                                        <div className="ml-auto text-sm">Phone</div>
                                    </div>

                                    {/*Title*/}
                                    <div className="w-full flex flex-row items-center mt-auto mb-auto">

                                        <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                            <CheckBadgeIcon className="w-5"/>
                                        </div>

                                        <div className="text-sm">{title || "- - - - -"}</div>

                                        <div className="ml-auto text-sm">Title</div>
                                    </div>

                                    {/*Title*/}
                                    <div className="w-full flex flex-row items-center mt-auto mb-auto">

                                        <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                            <CheckBadgeIcon className="w-5"/>
                                        </div>

                                        <div className="text-sm">{title || "- - - - -"}</div>

                                        <div className="ml-auto text-sm">Title</div>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                </div>


                {/*Column Right     SETTINGS*/}
                <div className={`col-span-2 col-start-2 mt-3 ${darkMode ? 'bg-bgDark' : 'bg-white'} overflow-y-auto overflow-x-hidden rounded-lg max-h-[calc(100vh-5.5rem)] scrollbar-none`}>
                    <div className={`w-full`}>
                        {/* Sticky search header */}

                        <main>

                            <div className={`w-full border-b-2 border-dashed py-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                <h1 className={`text-center text-xl tracking-widest`}>Account Settings</h1>
                                <h1 className={`text-center text-xs tracking-widest text-gray-500`}>This is where you may edit or change critical information about your account</h1>
                            </div>

                            {/* Settings forms */}
                            <div className={`divide-y-2 divide-dashed ${darkMode ? 'divide-neutral-700' : ''}`}>

                                {/*Personal Info*/}
                                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-8 sm:px-6 md:grid-cols-3 lg:px-8">
                                    <div>
                                        <h2 className="text-base/7 font-semibold">Account Information</h2>
                                        <p className="mt-1 text-sm/6 text-gray-500">This is the specific personal data associated with your account</p>
                                    </div>

                                    <form className="  md:col-span-2">
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:max-w-xl sm:grid-cols-6">

                                            {/*Profile Image*/}
                                            <div className="col-span-full flex items-center gap-x-8">
                                                <img
                                                    alt=""
                                                    src={profileImage}
                                                    className={`size-24 flex-none rounded-lg bg-gray-800 ${darkMode ? 'border border-gray-500' : 'border'} object-cover`}
                                                />

                                                <div>
                                                    <div className={`hover:scale-105 rounded-md hover:bg-opacity-50 ${darkMode ? 'bg-white/10' : 'bg-bgcolorLight border drop-shadow'} px-3 py-2 text-sm font-semibold  shadow-sm`}>
                                                        <Input
                                                            id="mobile-user-photo"
                                                            name="user-photo"
                                                            type="file"
                                                            onChange={handleFileChange}
                                                            className="absolute w-fit z-50 rounded-md opacity-0"
                                                        />
                                                        <label
                                                            htmlFor="mobile-user-photo"
                                                            className="text-center flex items-center justify-center"
                                                        >
                                                            <span>Change</span>
                                                        </label>
                                                    </div>
                                                    <p className="mt-2 text-xs/5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
                                                </div>
                                            </div>

                                            {/*First Name*/}
                                            <div className="sm:col-span-3 relative">
                                                <label
                                                    htmlFor="name"
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    First Name
                                                </label>
                                                <Input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    placeholder="Enter First Name..."
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-gray-500' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Last name*/}
                                            <div className="sm:col-span-3 relative">
                                                <label
                                                    htmlFor="lname"
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Last Name
                                                </label>
                                                <Input
                                                    id="lname"
                                                    name="lname"
                                                    type="text"
                                                    placeholder="Enter Last Name..."
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-gray-500' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>




                                            {/*Phone Number*/}
                                            <div className="sm:col-span-3 relative">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Phone Number
                                                </label>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Phone Number..."
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-gray-500' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Email*/}
                                            <div className="sm:col-span-3 relative">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Email
                                                </label>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Email..."
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-gray-500' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>



                                            {/*Company*/}
                                            <div className="col-span-full relative">
                                                <label
                                                    htmlFor="company"
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Company
                                                </label>
                                                <Input
                                                    id="company"
                                                    name="company"
                                                    type="email"
                                                    placeholder="Enter Company Name..."
                                                    value={company}
                                                    onChange={(e) => setCompany(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-gray-500' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Title*/}
                                            <div className="col-span-full relative">
                                                <label
                                                    htmlFor="title"
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Title
                                                </label>
                                                <Input
                                                    id="title"
                                                    name="title"
                                                    type="text"
                                                    placeholder="Enter Title"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-gray-500' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Address*/}
                                            <div className="col-span-full relative">
                                                <label
                                                    htmlFor="address"
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Address
                                                </label>
                                                <Input
                                                    id="address"
                                                    name="address"
                                                    type="email"
                                                    placeholder="Enter Address..."
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-gray-500' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            <div className="hidden col-span-full">
                                                <label htmlFor="username" className="block text-sm/6 font-medium ">
                                                    Username
                                                </label>
                                                <div className="mt-2">
                                                    <div
                                                        className="flex items-center rounded-md bg-white/5 pl-3 outline outline-1 -outline-offset-1 outline-white/10 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-500">
                                                        <div
                                                            className="shrink-0 select-none text-base text-gray-500 sm:text-sm/6">example.com/
                                                        </div>
                                                        <input
                                                            id="username"
                                                            name="username"
                                                            type="text"
                                                            placeholder="janesmith"
                                                            className="block min-w-0 grow bg-transparent py-1.5 pl-1 pr-3 text-base  placeholder:text-gray-500 focus:outline focus:outline-0 sm:text-sm/6"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="hidden col-span-full">
                                                <label htmlFor="timezone" className="block text-sm/6 font-medium ">
                                                    Timezone
                                                </label>
                                                <div className="mt-2 grid grid-cols-1">
                                                    <select
                                                        id="timezone"
                                                        name="timezone"
                                                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white/5 py-1.5 pl-3 pr-8 text-base  outline outline-1 -outline-offset-1 outline-white/10 *:bg-gray-800 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"
                                                    >
                                                        <option>Pacific Standard Time</option>
                                                        <option>Eastern Standard Time</option>
                                                        <option>Greenwich Mean Time</option>
                                                    </select>
                                                    <ChevronDownIcon
                                                        aria-hidden="true"
                                                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-400 sm:size-4"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        {/*Button*/}
                                        <div className="mt-8 flex">
                                            <Button
                                                // type="submit"
                                                onClick={handleSavePreferences}
                                                className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            >
                                                Update Information
                                            </Button>
                                        </div>
                                    </form>


                                </div>

                                {/*General Account Info*/}
                                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                                    <div>
                                        <h2 className="text-base/7 font-semibold ">Global Account Settings</h2>
                                        <p className="mt-1 text-sm/6 text-gray-400">These are global settings that control variables across the entire platform</p>
                                    </div>

                                    <form className="md:col-span-2">

                                        {/*Metric*/}
                                        <Field className="flex items-center">
                                            <Switch
                                                checked={metric}
                                                onChange={setMetric}
                                                className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                                            >
                                                <span className="sr-only">Use setting</span>
                                                <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                                                <span
                                                    aria-hidden="true"
                                                    className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full border ${darkMode ? 'bg-bgcard border-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                />
                                                <span
                                                    aria-hidden="true"
                                                    className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                />
                                            </Switch>
                                            <Label as="span" className="ml-6 text-sm">
                                                <span className="font-medium">{metric ? 'Metric' : 'Imperial'}</span>{' '}
                                                <span className="text-gray-500">(All values will be displayed in the {metric ? 'Metric' : 'Imperial'} system)</span>
                                            </Label>
                                        </Field>


                                    </form>

                                </div>

                                {/*Change Password*/}
                                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                                    <div>
                                        <h2 className="text-base/7 font-semibold ">Change password</h2>
                                        <p className="mt-1 text-sm/6 text-gray-400">Update your password associated with
                                            your account.</p>
                                    </div>

                                    <form className="md:col-span-2">
                                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                                            <div className="col-span-full">
                                                <label htmlFor="current-password"
                                                       className="block text-sm/6 font-medium ">
                                                    Current password
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="current-password"
                                                        name="current_password"
                                                        type="password"
                                                        autoComplete="current-password"
                                                        className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base  outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-span-full">
                                                <label htmlFor="new-password"
                                                       className="block text-sm/6 font-medium ">
                                                    New password
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="new-password"
                                                        name="new_password"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base  outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-span-full">
                                                <label htmlFor="confirm-password"
                                                       className="block text-sm/6 font-medium ">
                                                    Confirm password
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="confirm-password"
                                                        name="confirm_password"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base  outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-8 flex">
                                            <button
                                                type="submit"
                                                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold  shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                {/*Logout of sessions*/}
                                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                                    <div>
                                        <h2 className="text-base/7 font-semibold ">Log out other sessions</h2>
                                        <p className="mt-1 text-sm/6 text-gray-400">
                                            Please enter your password to confirm you would like to log out of your
                                            other
                                            sessions across all of
                                            your devices.
                                        </p>
                                    </div>

                                    <form className="md:col-span-2">
                                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                                            <div className="col-span-full">
                                                <label htmlFor="logout-password"
                                                       className="block text-sm/6 font-medium ">
                                                    Your password
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="logout-password"
                                                        name="password"
                                                        type="password"
                                                        autoComplete="current-password"
                                                        className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base  outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-8 flex">
                                            <button
                                                type="submit"
                                                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold  shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            >
                                                Log out other sessions
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                {/*Delete Account*/}
                                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                                    <div>
                                        <h2 className="text-base/7 font-semibold ">Delete account</h2>
                                        <p className="mt-1 text-sm/6 text-gray-400">
                                            No longer want to use our service? You can delete your account here. This
                                            action is
                                            not reversible.
                                            All information related to this account will be deleted permanently.
                                        </p>
                                    </div>

                                    <form className="flex items-start md:col-span-2">
                                        <button
                                            type="submit"
                                            className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold  shadow-sm hover:bg-red-400"
                                        >
                                            Yes, delete my account
                                        </button>
                                    </form>
                                </div>

                                {/*Golf Totals*/}
                                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                                    <div>
                                        <h2 className="text-base/7 font-semibold ">Update Area Totals</h2>
                                        <p className="mt-1 text-sm/6 text-gray-400">This is the area of the Golf Course areas in Acres</p>
                                    </div>

                                    <form className="md:col-span-2">
                                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                                            <div className="col-span-full flex items-center justify-center">
                                                <label htmlFor="greens" className="w-1/4 text-litegreen text-sm mr-2">
                                                    Greens:
                                                </label>
                                                <input
                                                    type="number"
                                                    id="greens"
                                                    autoComplete="organization"
                                                    required
                                                    value={greens}
                                                    onChange={(e) => setGreens(e.target.value)}
                                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                                />
                                            </div>

                                            <div className="col-span-full flex items-center justify-center">
                                                <label htmlFor="surrounds"
                                                       className=" w-1/4 text-litegreen text-sm mr-2">
                                                    Surrounds:
                                                </label>
                                                <input
                                                    type="number"
                                                    id="surrounds"
                                                    autoComplete="organization-title"
                                                    required
                                                    value={surrounds}
                                                    onChange={(e) => setSurrounds(e.target.value)}
                                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                                />
                                            </div>

                                            <div className="col-span-full flex items-center justify-center">
                                                <label htmlFor="collars" className=" w-1/4 text-litegreen text-sm mr-2">
                                                    Collars:
                                                </label>
                                                <input
                                                    type="number"
                                                    id="collars"
                                                    autoComplete="organization-title"
                                                    required
                                                    value={collars}
                                                    onChange={(e) => setCollars(e.target.value)}
                                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                                />
                                            </div>

                                            <div className="col-span-full flex items-center justify-center">
                                                <label htmlFor="fairways" className="w-1/4 text-litegreen text-sm mr-2">
                                                    Fairways:
                                                </label>
                                                <input
                                                    type="number"
                                                    id="fairways"
                                                    autoComplete="organization"
                                                    required
                                                    value={fairways}
                                                    onChange={(e) => setFairways(e.target.value)}
                                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                                />
                                            </div>

                                            <div className="col-span-full flex items-center justify-center">
                                                <label htmlFor="rough" className=" w-1/4 text-litegreen text-sm mr-2">
                                                    Rough:
                                                </label>
                                                <input
                                                    type="number"
                                                    id="rough"
                                                    autoComplete="organization-title"
                                                    required
                                                    value={rough}
                                                    onChange={(e) => setRough(e.target.value)}
                                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                                />
                                            </div>

                                            <div className="col-span-full flex items-center justify-center">
                                                <label htmlFor="tees" className=" w-1/4 text-litegreen text-sm mr-2">
                                                    Tees:
                                                </label>
                                                <input
                                                    type="number"
                                                    id="tees"
                                                    autoComplete="organization-title"
                                                    required
                                                    value={tees}
                                                    onChange={(e) => setTees(e.target.value)}
                                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-8 flex">
                                            <button
                                                type="submit"
                                                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold  shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </main>
                    </div>
                </div>


            </div>



        </div>
    );
};

export default Account;
