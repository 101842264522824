import React from "react";
import { useDarkMode } from "../../contexts/darkModeContext";
import { useAuth } from "../../contexts/authContext";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const ChemCard = ({ usage, index, history }) => {

    const { currentUser } = useAuth();
    const { darkMode } = useDarkMode();

    // Array of keys and corresponding display text
    const typeDescriptions = [
        { key: "Created", text: "Product Added" },
        { key: "Update", text: "Updated Stock" },
        { key: "Edit", text: "Edited Product" },
        { key: "Apply", text: "Product was applied" },
        { key: "Spray", text: "Product was sprayed" },
    ];

    // Function to get the display text by matching usage.type
    const getTypeDescription = (type) => {
        const match = typeDescriptions.find(item => item.key.toLowerCase() === type.toLowerCase());
        return match ? match.text : "Unknown action";
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(Number(timestamp));

        // Extracting the month, day, year, hours, and minutes
        const month = date.getMonth() + 1;  // getMonth() returns 0-11, so add 1
        const day = date.getDate();
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const amPm = hours >= 12 ? 'PM' : 'AM';

        // Convert to 12-hour format
        hours = hours % 12 || 12;

        return `${month}/${day}/${year} at ${hours}:${minutes} ${amPm}`;
    };

    const getTimeDifference = (timestamp) => {
        const now = new Date();
        const past = new Date(Number(timestamp));

        // Calculate the difference in milliseconds
        const diffMs = now - past;

        // Time conversions
        const msInMinute = 60 * 1000;
        const msInHour = 60 * msInMinute;
        const msInDay = 24 * msInHour;
        const msInWeek = 7 * msInDay;
        const msInMonth = 30 * msInDay;
        const msInYear = 365 * msInDay;

        // Calculate the difference in days, weeks, months, and years
        const daysDiff = Math.floor(diffMs / msInDay);
        const weeksDiff = Math.floor(diffMs / msInWeek);
        const monthsDiff = Math.floor(diffMs / msInMonth);
        const yearsDiff = Math.floor(diffMs / msInYear);

        // Return formatted string based on the difference
        if (daysDiff === 0) {
            return "Today";
        } else if (daysDiff === 1) {
            return "Yesterday";
        } else if (daysDiff < 7) {
            return `${daysDiff}d ago`;
        } else if (weeksDiff < 4) {
            return `${weeksDiff}w ago`;
        } else if (monthsDiff < 12) {
            return `${monthsDiff}m ago`;
        } else {
            return `${yearsDiff}y ago`;
        }
    };

    return (
        <li className="relative flex gap-x-4">
            <div
                className={classNames(
                    index === history.length - 1 ? 'h-6' : '-bottom-6',
                    'absolute left-0 top-0 flex w-6 justify-center',
                )}
            >
                <div className="w-px bg-gray-500" />
            </div>

            <>
                <div className="relative flex size-6 flex-none items-center justify-center">
                    {usage.type.toLowerCase() === 'paid' ? (
                        <CheckCircleIcon aria-hidden="true" className="size-6 text-indigo-600"/>
                    ) : (
                        <div className="size-1.5 rounded-full bg-green-600 ring-1 ring-green-300"/>
                    )}
                </div>
                <p className="flex-auto py-0.5 text-xs/5 text-gray-500">
                    <span className={`font-medium ${darkMode ? 'text-white' : 'text-black'}`}>
                        {usage.userName}
                    </span>{' '}
                    {getTypeDescription(usage.type)}.
                </p>
                <div className="flex-none py-0.5 text-xs/5 text-gray-500 relative group">
                    {/* Default display */}
                    {getTimeDifference(usage.timestamp)}

                    {/* Tooltip on hover */}
                    <div className={`absolute -left-16 bottom-6 w-max px-2 py-2 tracking-widest ${darkMode ? 'text-white bg-bgDark' : 'text-black bg-white'} text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300`}>
                        {formatTimestamp(usage.timestamp)} {/* Full date and time */}
                    </div>
                </div>
            </>
        </li>
    );
};

export default ChemCard;
