import React, {useEffect, useState} from 'react';
import {useAuth} from '../contexts/authContext';
import Dashboard from './SideBar';
import {db} from '../firebase/firebase';
import {utils as xlsxUtils, write as writeXlsx} from 'xlsx';
import {saveAs} from 'file-saver';
import {ArcElement, BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar, Pie} from 'react-chartjs-2';
import {
    PlusCircleIcon,
    XCircleIcon,
    ArrowUpCircleIcon,
    ArrowDownCircleIcon,
    DocumentArrowDownIcon,
    ArrowRightCircleIcon,
    ArrowPathIcon,
    CalculatorIcon,
    MagnifyingGlassIcon, TrashIcon, BeakerIcon
} from "@heroicons/react/24/outline";
import {useDarkMode} from "../contexts/darkModeContext";
import SprayCard from "./Sprays/SprayCard";
import {Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle, Input, Select} from "@headlessui/react";
import {PlusIcon} from "@heroicons/react/16/solid";

Chart.register(ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);

export const sprayOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Spray Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Occurrences',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

export const sprayOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Chem Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Cost per Acre',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};


const Sprays = () => {
    const { currentUser } = useAuth();
    const [alertMessage, setAlertMessage] = useState(null);
    const [sprays, setSprays] = useState([]);
    const [filteredSprays, setFilteredSprays] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedPurpose, setSelectedPurpose] = useState('');

    const [showAdd, setShowAdd] = useState(false);

    const { darkMode } = useDarkMode();

    const toggleAdd = () => {
        setShowAdd(!showAdd);
    };

    const [formData, setFormData] = useState({
        name: '',
        area: 0,
        areaUnit: '',
        date: '',
        purpose: '',
        rate: 0,
        tankNum: 0,
        tankVol: 0,
        target: '',
        ingredients: [],
        updated: false,
    });
    const [editingSprayId, setEditingSprayId] = useState(null);
    const [chems, setChems] = useState([]); // New state for chemicals

    const showAlert = (message) => {
        setAlertMessage(message);
        setTimeout(() => {
            setAlertMessage(null);
        }, 2000); // Display for 2 seconds
    };

    const onUpdate = async () => {
        try {
            const sprayCollectionRef = db.collection('Users').doc(currentUser.uid).collection('sprays');
            const snapshot = await sprayCollectionRef.get();
            const sprayData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setSprays(sprayData);
            setFilteredSprays(sprayData);
            localStorage.setItem(`spraysData_${currentUser.uid}`, JSON.stringify(sprayData));
            alert("Sprays Successfully Updated!");
        } catch (error) {
            console.error('Error updating fleet data:', error);
        }
    };

    useEffect(() => {
        const fetchSprays = async () => {
            if (currentUser) {
                try {
                    const storedSpraysData = localStorage.getItem(`spraysData_${currentUser.uid}`);
                    if (storedSpraysData) {
                        const spraysData = JSON.parse(storedSpraysData);
                        setSprays(spraysData);
                        setFilteredSprays(spraysData);
                    } else {
                        const spraysCollectionRef = db.collection('Users').doc(currentUser.uid).collection('sprays');
                        const snapshot = await spraysCollectionRef.get();
                        if (!snapshot.empty) {
                            const spraysData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setSprays(spraysData);
                            setFilteredSprays(spraysData);
                            localStorage.setItem(`spraysData_${currentUser.uid}`, JSON.stringify(spraysData));
                        } else {
                            console.log('No sprays found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching sprays:', error);
                }
            }
        };

        const fetchChems = async () => {
            if (currentUser) {
                try {
                    const storedChemsData = localStorage.getItem(`chemsData_${currentUser.uid}`);
                    if (storedChemsData) {
                        const chemsData = JSON.parse(storedChemsData);
                        setChems(chemsData);
                    } else {
                        const chemsRef = db.collection('Users').doc(currentUser.uid).collection('chems');
                        const snapshot = await chemsRef.get();
                        const chemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setChems(chemsData);
                        localStorage.setItem(`chemsData_${currentUser.uid}`, JSON.stringify(chemsData));
                    }
                } catch (error) {
                    console.error('Error fetching chemicals:', error);
                }
            }
        };

        fetchSprays();
        fetchChems(); // Fetch chemicals when component mounts
    }, [currentUser]);


    const deleteSprayFromFirestore = async (sprayId) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('sprays').doc(sprayId).delete();
            console.log('Spray item deleted successfully');
            await onUpdate();
        } catch (error) {
            console.error('Error deleting Spray item:', error);
        }
    };

    const fillFormWithSprayData = (spray) => {
        setIsCollapsed(true);
        setFormData({
            name: spray.name,
            area: spray.area,
            areaUnit: spray.areaUnit,
            date: spray.date,
            purpose: spray.purpose,
            rate: spray.rate,
            tankNum: spray.tankNum,
            tankVol: spray.tankVol,
            target: spray.target,
            ingredients: spray.ingredients
        });
        setEditingSprayId(spray.id);
        if (isCollapsed === true){
            toggleCollapse();
        }
    };

    const clearForm = () => {
        setFormData({
            name: '',
            area: 0,
            areaUnit: '',
            date: '',
            purpose: '',
            rate: 0,
            tankNum: 0,
            tankVol: 0,
            target: '',
            ingredients: [],
            updated: false,
        });
        setEditingSprayId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('sprays');

        // If date field is empty, set it to the current date
        const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');
        const formDataWithDate = {
            ...formData,
            date: formData.date || currentDate
        };

        // Map formDataWithDate.ingredients to include necessary fields
        const ingredientsData = await Promise.all(formDataWithDate.ingredients.map(async ingredient => {
            // Since ingredients already include additional fields from handleIngredientChange,
            // we can directly use them here without additional processing
            return {
                name: ingredient.name,
                weight: ingredient.weight,
                rate: ingredient.rate,
                unit: ingredient.unit,
                price: ingredient.price,
                nutrients: ingredient.nutrients,
                containerVol: ingredient.containerVol,
                chemID: ingredient.chemID,
            };
        }));

        const parsedFormData = {
            ...formDataWithDate,
            area: parseFloat(formDataWithDate.area),
            rate: parseFloat(formDataWithDate.rate),
            tankNum: parseFloat(formDataWithDate.tankNum),
            tankVol: parseFloat(formDataWithDate.tankVol),
            // Include ingredients data
            ingredients: ingredientsData
        };

        try {
            if (editingSprayId) {
                await collectionRef.doc(editingSprayId).update(parsedFormData);
                console.log('Spray updated successfully!');
                showAlert('Spray updated successfully!');
                await onUpdate();
            } else {
                await collectionRef.add(parsedFormData);
                console.log('Spray added successfully!');
                showAlert('Spray added successfully!');
                await onUpdate();
            }

            const snapshot = await collectionRef.get();
            const updatedSpraysData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setSprays(updatedSpraysData);

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating Spray: ', error);
            showAlert('Error adding/updating Spray: ', error);

        }
    };





    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'date') {
            // If the changed field is 'date', directly set the value
            setFormData((prevData) => ({
                ...prevData,
                date: value,
            }));
        } else {
            // For other fields, update as usual
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };



    // const handleIngredientChange = async (index, fieldName, e) => {
    //     const selectedValue = e.target.value;
    //     if (!selectedValue) {
    //         console.error('Invalid selected value:', selectedValue);
    //         return;
    //     }
    //
    //     try {
    //         // Fetch the data of the selected chem from Firestore
    //         const selectedChemData = await fetchChemData(selectedValue);
    //
    //         // Debugging: Log the selected chemical data
    //         console.log('Selected chemical data:', selectedChemData);
    //
    //         // Check if selectedChemData is null
    //         if (!selectedChemData) {
    //             console.error('Selected chemical data is null for:', selectedValue);
    //             return; // Exit the function if data is not found
    //         }
    //
    //         // Update the ingredient object with data from the selected chem
    //         const updatedIngredients = formData.ingredients.map((ingredient, i) => {
    //             if (i === index) {
    //                 return {
    //                     ...ingredient,
    //                     // Assign data from the selected chem to corresponding fields
    //                     name: selectedValue, // Update the name
    //                     weight: selectedChemData.weight,
    //                     rate: selectedChemData.rate,
    //                     unit: selectedChemData.unit,
    //                     price: selectedChemData.price, // Map unitprice to price
    //                     nutrients: selectedChemData.nutrients,
    //                     containerVol: selectedChemData.containerVol,
    //                     chemID: selectedChemData.chemID,
    //                     // Add more fields similarly for other properties
    //                 };
    //             }
    //             return ingredient;
    //         });
    //
    //         // Update the state with the updated ingredients
    //         setFormData(prevState => ({
    //             ...prevState,
    //             ingredients: updatedIngredients
    //         }));
    //     } catch (error) {
    //         console.error('Error handling ingredient change:', error);
    //         // Handle any errors that occur during the data fetching or processing
    //     }
    // };





    const fetchChemData = async (chemicalName) => {
        try {
            const chemDocs = await db.collection('Users').doc(currentUser.uid).collection('chems').where('name', '==', chemicalName).get();
            if (!chemDocs.empty) {
                const chemDoc = chemDocs.docs[0];
                const chemData = chemDoc.data();
                return {
                    chemID: chemDoc.id, // Capture the document ID
                    weight: chemData.weight,
                    rate: chemData.rate,
                    unit: chemData.unit,
                    price: chemData.unitprice,
                    nutrients: chemData.nutrients,
                    containerVol: chemData.volume,
                };
            } else {
                console.error('Chemical data not found for:', chemicalName);
                return null;
            }
        } catch (error) {
            console.error('Error fetching chemical data:', error);
            return null;
        }
    };

    // const handleAddIngredient = () => {
    //     setFormData({ ...formData, ingredients: [...formData.ingredients, { name: null }] });
    // };
    //
    // const handleRemoveIngredients = (index) => {
    //     const updatedIngredients = [...formData.ingredients];
    //     updatedIngredients.splice(index, 1);
    //     setFormData(prevState => ({
    //         ...prevState,
    //         ingredients: updatedIngredients
    //     }));
    // };

    const addOrUpdateButtonText = editingSprayId ? 'Update Spray' : 'Add Spray';

    const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');


    const calculateTotalCoverage = (spray) => {
        return (spray.area);
    };

    const calculateTotalPrice = (spray) => {

        const areaUnit = spray.areaUnit;
        const area = spray.area;

        let areaNum;

        if (areaUnit === "acres") {
            areaNum = area * 43560; // Convert acres to square feet
        } else {
            areaNum = area; // Assume the area is already in square feet
        }

        const totalChemPrice = spray.ingredients.reduce((accumulator, current) => {
            return accumulator + (current.price * (areaNum * ((current.rate / 1000) / 128)));
        }, 0).toFixed(2);

        return (totalChemPrice);
    };




    // Function to filter sprays based on search query
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = sprays.filter(spray =>
            spray.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredSprays(filtered);
    };

    const filterSpraysByPurpose = (purpose) => {
        setSelectedPurpose(purpose);
        const filtered = sprays.filter(spray => purpose === '' || spray.purpose === purpose);
        setFilteredSprays(filtered);
    };





    const downloadAsExcel = () => {
        const columnHeaders = ['Name', 'Purpose', 'Date', 'Tanks', 'Tank Volume', 'Ingredients', 'Target', 'Area', 'Area Unit'];
        const worksheetData = [columnHeaders, ...sprays.map(spray => [spray.name, spray.purpose, spray.date, spray.tankNum, spray.tankVol, spray.ingredients.length, spray.target, spray.area, spray.areaUnit])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Chemicals');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'SprayHistory.xlsx');
        showAlert('Sprays Successfully Downloaded!');

    };

    const [isCollapsed, setIsCollapsed] = useState(false); // Default value

    // useEffect(() => {
    //     setIsCollapsed(spraySide); // Set isCollapsed to the value of chemSide from Firebase
    // }, [spraySide]); // Run this effect whenever chemSide changes

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [expanded, setExpanded] = useState(false); // State to track expansion

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };


    const calculateIngredients = () => {
        const chemMap = {};

        filteredSprays.forEach(spray => {
            spray.ingredients.forEach(ingredient => {
                const { name } = ingredient;

                if (!chemMap[name]) {
                    chemMap[name] = 1; // Initialize count
                } else {
                    chemMap[name] += 1; // Increment count
                }
            });
        });

        return Object.entries(chemMap).map(([name, count]) => ({
            name,
            totalValue: count
        }));
    };

    const calcTotalChemVol = (spray) => {

        const areaUnit = spray.areaUnit;
        const area = spray.area;
        let areaNum;

        if (areaUnit === "acres") {
            areaNum = area * 43560; // Convert acres to square feet
        } else {
            areaNum = area; // Assume the area is already in square feet
        }

        return spray.ingredients.reduce((accumulator, current) => {
            return accumulator + (areaNum * ((current.rate / 1000) / 128));
        }, 0).toFixed(2);

    }

    const calculateTotalCost = (spray) => {

        const areaUnit = spray.areaUnit;
        const area = spray.area;

        let areaNum;

        if (areaUnit === "acres") {
            areaNum = area * 43560; // Convert acres to square feet
        } else {
            areaNum = area; // Assume the area is already in square feet
        }

        const totalChemPrice = spray.ingredients.reduce((accumulator, current) => {
            return accumulator + (current.price * (areaNum * ((current.rate / 1000) / 128)));
        }, 0).toFixed(2);

        return (totalChemPrice / spray.area).toFixed(2);
    };

    const calcCost = () => {
        return filteredSprays.map(spray => ({
            name: spray.name,
            cost: calculateTotalCost(spray)
        }));
    };

    const calculateAverageCostPerAcre = (sprays) => {
        const totalCost = sprays.reduce((accumulator, spray) => {
            const sprayCost = parseFloat(calculateTotalCost(spray));
            return accumulator + sprayCost;
        }, 0);

        const averageCost = totalCost / sprays.length;
        return averageCost.toFixed(2);
    };

    const calculateAverageCost = (sprays) => {
        const totalCost = sprays.reduce((accumulator, spray) => {
            const sprayCost = parseFloat(calculateTotalCost(spray));
            return accumulator + sprayCost;
        }, 0);

        return totalCost.toFixed(2);
    };

    const averageCost = calculateAverageCostPerAcre(filteredSprays);

    const averageTotalCost = calculateAverageCost(filteredSprays);

    const uniqueChems = calculateIngredients();
    const costs = calcCost();


    const chemBarChartData = {
        labels: uniqueChems.map(chem => chem.name.slice(0, 4)),
        datasets: [
            {
                label: 'Occurrences:',
                backgroundColor: 'rgba(122, 197, 158, 0.2)',
                borderColor: 'rgba(122, 197, 159, 0.8)',
                borderWidth: 2,
                data: uniqueChems.map(chem => chem.totalValue.toFixed(2)),
            }
        ]
    };

    const sprayBarChartData2 = {
        labels: costs.map(spray => spray.name.slice(0,4)),
        datasets: [
            {
                label: 'Cost',
                backgroundColor: 'rgba(85, 145, 180, 0.2)',
                borderColor: 'rgba(85, 145, 180, 1)',
                borderWidth: 2,
                data: costs.map(spray => spray.cost),
            }
        ]
    };




    // Handle ingredient changes
    const handleIngredientChange = (index, field, event) => {
        const newIngredients = [...formData.ingredients];
        newIngredients[index][field] = field === 'rate' ? parseFloat(event.target.value) || 0 : event.target.value;
        setFormData({ ...formData, ingredients: newIngredients });
    };

    // Handle area change
    const handleAreaChange = (event) => {
        setFormData({ ...formData, area: parseFloat(event.target.value) || 1 });
    };

    // Remove an ingredient
    const handleRemoveIngredients = (index) => {
        const newIngredients = [...formData.ingredients];
        newIngredients.splice(index, 1);
        setFormData({ ...formData, ingredients: newIngredients });
    };

    // Add a new ingredient
    const handleAddIngredient = () => {
        setFormData({
            ...formData,
            ingredients: [...formData.ingredients, { name: '', rate: 0 }]
        });
    };

    // Generate pie chart data based on the entered values
    const pieData = {
        labels: formData.ingredients.map((ingredient, index) => ingredient.name || `Ingredient ${index + 1}`),
        datasets: [
            {
                data: formData.ingredients.map(ingredient => ingredient.rate * formData.area),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']
            }
        ]
    };


    return (
        <div className={`${darkMode ? "bg-bgpage text-white" : "bg-bgcolorLight text-black"} z-50 flex h-full w-full mt-16`}>


            <Dashboard/>

            <div className="relative">
                {/* Your existing UI components */}
                {alertMessage && (
                    <div
                        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-bglite text-white p-4 rounded-lg shadow-inner shadow-gray-900">
                        {alertMessage}
                    </div>
                )}
            </div>


            <div className="w-full z-20 animate-fadeIn h-full flex relative">


                <div className="content pr-3 pt-3 pb-3 w-full">


                    {/*Header*/}
                    <div className="flex flex-col items-center justify-center">


                        <div className=" flex items-center justify-center w-full flex-row">

                            <div className="w-1/6 flex items-center text-lg mr-2 tracking-widest">Sprays <span className={`text-xs ml-2`}>[{filteredSprays.length}]</span>

                                <Button onClick={onUpdate}>
                                    <ArrowPathIcon className={`w-4 ml-2 text-gray-500 hover:scale-105 hover:rotate-12 hover:animate-pulse`}/>
                                </Button>

                            </div>

                            {/*Header*/}
                            <div className={`${darkMode ? "bg-bgDark text-white shadow-gray-900" : "bg-white text-black"} flex items-center justify-center rounded-lg p-1 w-full mr-2`}>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Sprays:</div>
                                        <div className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>{filteredSprays.length}</div>
                                    </div>
                                </div>


                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Total Area:</div>
                                        <div
                                            className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>{numberWithCommas(filteredSprays.reduce((total, spray) => total + parseFloat(calculateTotalCoverage(spray)), 0).toFixed(2)) + " acres"}</div>
                                    </div>
                                </div>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Total Cost:</div>
                                        <div
                                            className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>{"$" + numberWithCommas(filteredSprays.reduce((total, spray) => total + parseFloat(calculateTotalPrice(spray)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <Button
                                    className="text-xl text-white font-bold mt-auto mb-auto hover:text-litegreen mr-2"
                                    onClick={toggleExpansion}>
                                    {expanded ? <ArrowUpCircleIcon className={`w-5 ${darkMode ? '' : 'text-black'}`}/> :
                                        <ArrowDownCircleIcon className={`w-5 ${darkMode ? '' : 'text-black'}`}/>}
                                </Button>

                            </div>

                            <Button onClick={downloadAsExcel}
                                    className={`${darkMode ? "text-white" : "text-gray-400"} flex font-bold rounded-lg focus:outline-none shadow-gray-900`}>
                                <DocumentArrowDownIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                            <Button className={`${darkMode ? "text-white" : "text-gray-400"} ml-2 text-3xl`}
                                    onClick={toggleCollapse}>
                                <ArrowRightCircleIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                        </div>



                        {/* Conditionally render content based on expansion state */}
                        {expanded && (
                            <>
                                <div
                                    className={`${darkMode ? "bg-bgcolor shadow-inner shadow-gray-900" : "bg-bgcolorLiteGreen"} mt-2 mr-2 ml-2 p-2 flex items-center justify-center rounded-md w-full`}>


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Acre Cost Comparison:</div>
                                            <Bar options={sprayOptions2} data={sprayBarChartData2}
                                                 className="mr-auto ml-auto"/>
                                        </div>
                                    </div>


                                    <div className="w-full flex flex-col">

                                        <div className="flex flex-row mb-2">

                                            <div
                                                className={`${darkMode ? "bg-bglite shadow-inner shadow-gray-900" : "bg-white"} w-full mr-2 rounded-md p-1`}>
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Total Chem Volume:
                                                    </div>
                                                    <div
                                                        className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-md border-2 p-1 border-solid border-darkgreen`}>{numberWithCommas(filteredSprays.reduce((total, spray) => total + parseFloat(calcTotalChemVol(spray)), 0).toFixed(2))} gal
                                                    </div>
                                                </div>
                                            </div>


                                            <div
                                                className={`${darkMode ? "bg-bglite shadow-inner shadow-gray-900" : "bg-white"} w-full mr-2 rounded-md p-1`}>
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Avg Cost per Acre:
                                                    </div>
                                                    <div
                                                        className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-md border-2 p-1 border-solid border-darkgreen`}>${numberWithCommas(averageCost)}</div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="flex flex-row mb-2">

                                            <div
                                                className={`${darkMode ? "bg-bglite shadow-inner shadow-gray-900" : "bg-white"} mr-auto ml-auto rounded-md pl-6 pr-6 pb-1 pt-1`}>
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Total Price:
                                                    </div>
                                                    <div
                                                        className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-md border-2 p-1 border-solid border-darkgreen`}>${numberWithCommas(averageTotalCost)}</div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Chemical Occurrences:</div>
                                            <Bar options={sprayOptions} data={chemBarChartData}
                                                 className="ml-auto mr-auto"/>
                                        </div>
                                    </div>

                                </div>

                            </>
                        )}

                    </div>

                    {/*Search Bar Rows*/}
                    <div className="mt-2 w-full flex flex-row">

                        <div className={`w-full flex flex-row items-center ${darkMode ? "bg-bgDark" : "bg-white shadow-sm"} rounded-lg px-2 mr-2`}>
                            <MagnifyingGlassIcon className={`w-4 mr-2`}/>
                            <Input
                                type="text"
                                placeholder="Search sprays by name"
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                                className={`rounded-lg block w-full bg-transparent`}
                            />
                        </div>

                        <div className="w-1/4 flex flex-row items-center">
                            <Select
                                value={selectedPurpose}
                                onChange={(e) => filterSpraysByPurpose(e.target.value)}
                                className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgDark dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                            >
                                <option value="">All Purposes</option>

                                <option value="Aeration Recovery">Aeration Recovery</option>
                                <option value="Wetting Agent">Wetting Agent</option>
                                <option value="Pesticide Appplication">Pesticide Appplication</option>
                                <option value="Fertilizer Application">Fertilizer Application</option>
                                <option value="Herbicide Application">Herbicide Application</option>
                                <option value="Disease Control">Disease Control</option>
                                <option value="Insect Control">Insect Control</option>
                                <option value="Weed Control">Weed Control</option>
                                <option value="Fungicide Application">Fungicide Application</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Plant Growth Regulators">Plant Growth Regulators</option>
                                <option value="Drought Resistance">Drought Resistance</option>
                                <option value="Color Enhancement">Color Enhancement</option>
                                <option value="Sun Protection">Sun Protection</option>
                                <option value="Stress Management">Stress Management</option>
                                <option value="Algae Control">Algae Control</option>
                                <option value="pH Balancing">pH Balancing</option>
                                <option value="Seasonal Adjustments">Seasonal Adjustments</option>
                                <option value="Erosion Control">Erosion Control</option>
                                <option value="Water Quality Enhancement">Water Quality Enhancement</option>
                                <option value="Environmental Sustainability">Environmental Sustainability</option>
                                {/* Add more options as needed */}
                            </Select>
                        </div>

                    </div>

                    {/*Sprays Body*/}
                    <div className={`relative z-50 h-full mt-2 w-full rounded-lg overflow-y-auto max-h-[calc(100vh-11rem)] scrollbar-none`}>
                        {filteredSprays.length === 0 ? (
                            // Display custom dialog if sprays array is empty
                            <div className="absolute flex w-full h-full justify-center items-center">
                                <div
                                    className={`flex animate-fadeInFast flex-col items-center border-2 border-dashed border-neutral-500 ${darkMode ? "text-white" : "text-black"} w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>

                                    <CalculatorIcon className={`w-12 stroke-1`}/>
                                    <h3 className="mt-2 text-sm font-semibold">No Sprays</h3>
                                    <p className="mt-1 text-sm text-gray-500">Get started by calculating a new Spray.</p>
                                    <div className="mt-6">
                                        <Button
                                            type="button"
                                            className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            onClick={toggleAdd}
                                        >
                                            <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5"/>
                                            Add Spray
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        ) : (
                            // Display SprayCard components if sprays array is not empty
                            filteredSprays.map((spray, index) => (
                                <SprayCard key={index} spray={spray} onDelete={() => deleteSprayFromFirestore(spray.id)}
                                           onEdit={fillFormWithSprayData}/>
                            ))
                        )}
                    </div>


                </div>

            </div>




            <Dialog open={showAdd} onClose={setShowAdd} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen h-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform h-[40rem] w-[80%] overflow-hidden rounded-lg ${darkMode ? 'bg-bgDark text-white' : 'bg-white text-black'} px-4 py-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="grid grid-cols-3 grid-rows-6 gap-4 h-full">

                                {/*Header*/}
                                <div className={`flex items-center col-span-3 col-start-1`}>
                                    <div className="sm:flex sm:items-start">
                                        <div
                                            className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                            <CalculatorIcon aria-hidden="true" className="size-6 text-green-600"/>
                                        </div>
                                        <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <DialogTitle as="h3" className="text-base font-semibold">
                                                Build a Spray
                                            </DialogTitle>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    This is an interactive spray building tool
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h-fit ml-auto sm:flex sm:flex-row-reverse">
                                        <Button
                                            type="button"
                                            // onClick={handleDelete}
                                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                        >
                                            Add Spray
                                        </Button>
                                        <Button
                                            type="button"
                                            data-autofocus
                                            onClick={clearForm}
                                            className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-bgpage' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                </div>

                                {/*Left*/}
                                <div className={`col-span-2 row-span-5 col-start-1 row-start-2 border rounded-lg p-2 ${darkMode ? 'border-neutral-700' : ''}`}>

                                    {/*Grid*/}
                                    <div className="grid grid-cols-2 grid-rows-1 gap-2 h-full">

                                        {/*Fields*/}
                                        <div className={`col-span-1 border-r pr-2 ${darkMode ? 'border-neutral-700' : ''} overflow-y-auto col-start-1 scrollbar-none`}>

                                            <div className={`grid grid-cols-1 gap-x-3 sm:max-w-xl sm:grid-cols-6`}>

                                                <div className="sm:col-span-full justify-center items-center text-center text-xs mb-2 tracking-widest">IDENTIFIERS</div>

                                                {/*Name*/}
                                                <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Spray Name
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter Spray Name..."
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>

                                                {/*Date*/}
                                                <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Date
                                                    </label>
                                                    <Input
                                                        type="date"
                                                        id="date"
                                                        name="date"
                                                        placeholder="Enter Chem Name..."
                                                        value={formData.date || currentDate}
                                                        onChange={handleChange}
                                                        className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>

                                                <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 font-bold justify-center items-center text-center text-xxs`}>
                                                    This is the data that the system uses to identify this spray in your
                                                    database
                                                </div>

                                                <div className="sm:col-span-full mt-4 justify-center items-center text-center text-xs mb-2 tracking-widest">CLASSIFIERS</div>

                                                {/*Purpose*/}
                                                <div className="sm:col-span-3 relative flex items-center justify-start mt-2 mb-2">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Purpose
                                                    </label>
                                                    <Select
                                                        type="text"
                                                        id="purpose"
                                                        name="purpose"
                                                        value={formData.purpose}
                                                        onChange={handleChange}
                                                        className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    >
                                                        <option value="">Select...</option>
                                                        <option value="Aeration Recovery">Aeration Recovery</option>
                                                        <option value="Wetting Agent">Wetting Agent</option>
                                                        <option value="Pesticide Appplication">Pesticide Appplication
                                                        </option>
                                                        <option value="Fertilizer Application">Fertilizer Application
                                                        </option>
                                                        <option value="Herbicide Application">Herbicide Application</option>
                                                        <option value="Disease Control">Disease Control</option>
                                                        <option value="Insect Control">Insect Control</option>
                                                        <option value="Weed Control">Weed Control</option>
                                                        <option value="Fungicide Application">Fungicide Application</option>
                                                        <option value="Soil Amendments">Soil Amendments</option>
                                                        <option value="Plant Growth Regulators">Plant Growth Regulators
                                                        </option>
                                                        <option value="Drought Resistance">Drought Resistance</option>
                                                        <option value="Color Enhancement">Color Enhancement</option>
                                                        <option value="Sun Protection">Sun Protection</option>
                                                        <option value="Stress Management">Stress Management</option>
                                                        <option value="Algae Control">Algae Control</option>
                                                        <option value="pH Balancing">pH Balancing</option>
                                                        <option value="Seasonal Adjustments">Seasonal Adjustments</option>
                                                        <option value="Erosion Control">Erosion Control</option>
                                                        <option value="Water Quality Enhancement">Water Quality
                                                            Enhancement
                                                        </option>
                                                        <option value="Environmental Sustainability">Environmental
                                                            Sustainability
                                                        </option>
                                                    </Select>
                                                </div>

                                                {/*Target Area*/}
                                                <div className="sm:col-span-3 relative flex items-center justify-start mt-2 mb-2">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Target Area
                                                    </label>
                                                    <Select
                                                        type="text"
                                                        id="target"
                                                        name="target"
                                                        value={formData.target}
                                                        onChange={handleChange}
                                                        className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    >
                                                        <option value="">Select...</option>
                                                        <option value="Greens">Greens</option>
                                                        <option value="Surrounds">Surrounds</option>
                                                        <option value="Collars">Collars</option>
                                                        <option value="Fairways">Fairways</option>
                                                        <option value="Tees">Tees</option>
                                                        <option value="Rough">Rough</option>
                                                    </Select>
                                                </div>

                                                <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 font-bold justify-center items-center text-center text-xxs`}>This
                                                    is the data that the system uses to identify this Spray in your database
                                                </div>

                                                <div className="sm:col-span-full mt-4 justify-center items-center text-center text-xs mb-2 tracking-widest">MEASUREMENTS</div>

                                                {/*Area*/}
                                                <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Area
                                                    </label>
                                                    <Input
                                                        type="number"
                                                        id="area"
                                                        name="area"
                                                        value={formData.area}
                                                        onChange={handleChange}
                                                        className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>

                                                {/*Unit*/}
                                                <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Area Unit
                                                    </label>
                                                    <Select
                                                        type="text"
                                                        id="areaUnit"
                                                        name="areaUnit"
                                                        value={formData.areaUnit}
                                                        onChange={handleChange}
                                                        className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    >
                                                        <option value="">Select...</option>
                                                        <option value="sq. ft">sq. ft</option>
                                                        <option value="acres">acres</option>
                                                    </Select>
                                                </div>

                                                {/*Rate*/}
                                                <div className="sm:col-span-full relative flex items-center mt-2 mb-2">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Rate
                                                    </label>
                                                    <Input
                                                        type="number"
                                                        id="rate"
                                                        name="rate"
                                                        value={formData.rate}
                                                        onChange={handleChange}
                                                        className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                    <label className="block text-litegreen text-xs ml-2 text-center w-1/3"
                                                           htmlFor="rate">
                                                        Gal per Acre:
                                                    </label>
                                                </div>

                                                <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 font-bold justify-center items-center text-center text-xxs`}>
                                                    This is the data that the system uses to calculate your volume
                                                </div>

                                                <div className="sm:col-span-full mt-4 justify-center items-center text-center text-xs mb-2 tracking-widest">TANK DATA</div>

                                                {/*Tank Num*/}
                                                <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Tank Num
                                                    </label>
                                                    <Input
                                                        type="number"
                                                        id="tankNum"
                                                        name="tankNum"
                                                        value={formData.tankNum}
                                                        onChange={handleChange}
                                                        step="0.01"
                                                        className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>

                                                {/*Volume*/}
                                                <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                    <label
                                                        className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                    >
                                                        Tank Volume
                                                    </label>
                                                    <Input
                                                        type="number"
                                                        id="tankVol"
                                                        name="tankVol"
                                                        value={formData.tankVol}
                                                        onChange={handleChange}
                                                        step="0.01"
                                                        className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>

                                                <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2  font-bold justify-center items-center text-center text-xxs`}>
                                                    This is the data that the system uses to Calculate your per tank
                                                    analysis
                                                </div>



                                            </div>

                                        </div>

                                        {/*Chart*/}
                                        <div className={`col-span-1 border-l pl-2 ${darkMode ? 'border-neutral-700' : ''} flex flex-col`}>

                                            {/*Header*/}
                                            <div className="justify-center items-center text-center text-md tracking-widest">SPRAY MAKEUP</div>

                                            {/*Chart*/}
                                            <div className="flex items-center justify-center h-full">
                                                <Pie data={pieData} className="mr-auto ml-auto p-2"/>
                                            </div>

                                            {/*Footer*/}
                                            <div className={`w-full h-full border ${darkMode ? 'border-neutral-700' : ''} rounded-lg`}>

                                                <div className={`grid grid-cols-6 gap-3 p-2`}>
                                                    {/*Name*/}
                                                    <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                        <label
                                                            className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                        >
                                                            Spray Name
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            placeholder="Enter Spray Name..."
                                                            value={formData.name}
                                                            onChange={handleChange}
                                                            className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                        />
                                                    </div>

                                                    {/*Date*/}
                                                    <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                        <label
                                                            className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                        >
                                                            Date
                                                        </label>
                                                        <Input
                                                            type="date"
                                                            id="date"
                                                            name="date"
                                                            placeholder="Enter Chem Name..."
                                                            value={formData.date || currentDate}
                                                            onChange={handleChange}
                                                            className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                {/*Ingredients*/}
                                <div className={`row-span-5 overflow-y-auto scrollbar-none col-start-3 row-start-2 border rounded-lg p-2 ${darkMode ? 'border-neutral-700' : ''}`}>

                                    <div className={`flex relative mb-3 border-b-2 pb-3 border-dashed ${darkMode ? 'border-neutral-700' : ''} mt-2 flex-row items-center justify-between`}>
                                        <div className="w-full text-center text-md tracking-widest">Ingredients [{formData.ingredients.length}]</div>
                                        <Button type="button" onClick={handleAddIngredient} className="rounded-full p-0.5 hover:bg-opacity-50 flex items-center justify-center absolute right-0 text-center bg-green-600 text-white h-8 w-8">
                                            <PlusCircleIcon className={`text-white`}/>
                                        </Button>
                                    </div>

                                    <div className="grid grid-cols-1 gap-2 w-full">
                                        {formData.ingredients.length === 0 ? (
                                            // Display custom dialog if sprays array is empty

                                            <div className={`w-full flex items-center justify-center`}>

                                                <div className={`text-center flex flex-col items-center rounded-lg border-2 px-6 py-3 mt-16 border-dashed ${darkMode ? 'border-neutral-700' : ''}`}>
                                                    <BeakerIcon className={`text-gray-400 w-8`}/>
                                                    <h3 className="mt-2 text-sm font-semibold">No Ingredients</h3>
                                                    <p className="mt-1 text-xs text-gray-500">Get started by creating a new project.</p>
                                                    <div className="mt-2">
                                                        <Button
                                                            type="button"
                                                            onClick={handleAddIngredient}
                                                            className="inline-flex items-center rounded-md bg-green-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            <PlusIcon aria-hidden="true"
                                                                      className="-ml-0.5 mr-1.5 size-5"/>
                                                            Add Chem
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            formData.ingredients.map((ingredient, index) => (
                                                <div key={index}
                                                     className={`flex relative flex-col border ${darkMode ? 'border-neutral-700' : 'bg-white'} rounded-lg p-2`}>
                                                    {/*Grid*/}
                                                    <div className="mt-8 grid grid-cols-2 gap-3">

                                                        {/*Ingredient Name*/}
                                                        <div className={`sm:col-span-1 relative w-full`}>
                                                            <label
                                                                className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                            >
                                                                Ingredient Name
                                                            </label>
                                                            <Select
                                                                type="text"
                                                                id={`ingredient-${index}`}
                                                                name={`ingredient-${index}`}
                                                                value={ingredient ? ingredient.name : ''}
                                                                onChange={(e) => handleIngredientChange(index, "name", e)}
                                                                className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                            >
                                                                <option value="">Select...</option>
                                                                {chems.map(chem => (
                                                                    <option key={chem.id}
                                                                            value={chem.name}>{chem.name}</option>
                                                                ))}
                                                            </Select>

                                                        </div>

                                                        {/*Ingredient Rate*/}
                                                        <div className={`sm:col-span-1 relative w-full`}>
                                                            <label
                                                                className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                            >
                                                                Rate
                                                            </label>
                                                            <Input
                                                                type="number"
                                                                id={`rate-${index}`}
                                                                name={`rate-${index}`}
                                                                value={ingredient.rate}
                                                                onChange={(e) => handleIngredientChange(index, 'rate', e)}
                                                                className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                            />
                                                        </div>

                                                    </div>

                                                    {/*Delete Button*/}
                                                    <Button type="button" className="absolute right-0 mr-2" onClick={() => handleRemoveIngredients(index)}>
                                                        <TrashIcon className={`w-4 text-red-500`}/>
                                                    </Button>

                                                    {/*Label*/}
                                                    <div className={`flex items-center absolute`}>
                                                        <div className={`text-xs`}>Ingredient Card</div>
                                                        <div className={`bg-green-600/50 px-2 rounded-md ml-2 border border-green-600 text-xxs`}>{index + 1}</div>
                                                    </div>
                                                </div>
                                            ))
                                        )}

                                    </div>


                                </div>

                            </div>

                        </DialogPanel>
                    </div>
                </div>
            </Dialog>


            <Dialog open={isCollapsed} onClose={setIsCollapsed} className={`relative z-50 ${darkMode ? 'text-white' : 'text-black'}`}>
                <div className="fixed inset-0"/>

                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/20 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
                />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div
                            className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 pb-3 pr-3">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className={`flex h-full flex-col overflow-y-scroll ${darkMode ? 'bg-bgDark' : 'bg-white'} rounded-lg shadow-2xl`}>

                                    {/*Drawer Header*/}
                                    <div className={`${darkMode ? ' border-neutral-700' : ''} border-b-2 border-dashed px-4 py-4 sm:px-6`}>
                                        <div className="flex items-center justify-between">

                                            <Button className={``} onClick={toggleAdd}>
                                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                                    <CalculatorIcon aria-hidden="true" className="size-6 text-green-600"/>
                                                </div>
                                            </Button>

                                            <DialogTitle className="text-base font-semibold tracking-widest">
                                                Spray Calculator
                                            </DialogTitle>

                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={clearForm}
                                                    className="relative rounded-md hover:text-red-500 hover:animate-heartbeat"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Clear Form</span>
                                                    <XCircleIcon aria-hidden="true" className="size-6" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Drawer Content*/}
                                    <div className="relative flex-1 px-4 sm:px-6">


                                        <form onSubmit={handleSubmit}
                                              className="mt-2 rounded-lg overflow-y-auto max-h-[calc(100vh-7rem)] scrollbar-none animate-fadeIn grid grid-cols-1 gap-x-6 gap-y-1 sm:max-w-xl sm:grid-cols-6">

                                            <div className="sm:col-span-full text-white justify-center items-center text-center text-xs">IDENTIFIERS</div>

                                            {/*Name*/}
                                            <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Spray Name
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    placeholder="Enter Spray Name..."
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Date*/}
                                            <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Date
                                                </label>
                                                <Input
                                                    type="date"
                                                    id="date"
                                                    name="date"
                                                    placeholder="Enter Chem Name..."
                                                    value={formData.date || currentDate}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>
                                                This is the data that the system uses to identify this spray in your
                                                database
                                            </div>

                                            <div className="sm:col-span-full text-white mt-4 justify-center items-center text-center text-xs">CLASSIFIERS</div>

                                            {/*Purpose*/}
                                            <div className="sm:col-span-3 relative flex items-center justify-start mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Purpose
                                                </label>
                                                <Select
                                                    type="text"
                                                    id="purpose"
                                                    name="purpose"
                                                    value={formData.purpose}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select...</option>
                                                    <option value="Aeration Recovery">Aeration Recovery</option>
                                                    <option value="Wetting Agent">Wetting Agent</option>
                                                    <option value="Pesticide Appplication">Pesticide Appplication
                                                    </option>
                                                    <option value="Fertilizer Application">Fertilizer Application
                                                    </option>
                                                    <option value="Herbicide Application">Herbicide Application</option>
                                                    <option value="Disease Control">Disease Control</option>
                                                    <option value="Insect Control">Insect Control</option>
                                                    <option value="Weed Control">Weed Control</option>
                                                    <option value="Fungicide Application">Fungicide Application</option>
                                                    <option value="Soil Amendments">Soil Amendments</option>
                                                    <option value="Plant Growth Regulators">Plant Growth Regulators
                                                    </option>
                                                    <option value="Drought Resistance">Drought Resistance</option>
                                                    <option value="Color Enhancement">Color Enhancement</option>
                                                    <option value="Sun Protection">Sun Protection</option>
                                                    <option value="Stress Management">Stress Management</option>
                                                    <option value="Algae Control">Algae Control</option>
                                                    <option value="pH Balancing">pH Balancing</option>
                                                    <option value="Seasonal Adjustments">Seasonal Adjustments</option>
                                                    <option value="Erosion Control">Erosion Control</option>
                                                    <option value="Water Quality Enhancement">Water Quality
                                                        Enhancement
                                                    </option>
                                                    <option value="Environmental Sustainability">Environmental
                                                        Sustainability
                                                    </option>
                                                </Select>
                                            </div>

                                            {/*Target Area*/}
                                            <div className="sm:col-span-3 relative flex items-center justify-start mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Target Area
                                                </label>
                                                <Select
                                                    type="text"
                                                    id="target"
                                                    name="target"
                                                    value={formData.target}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select...</option>
                                                    <option value="Greens">Greens</option>
                                                    <option value="Surrounds">Surrounds</option>
                                                    <option value="Collars">Collars</option>
                                                    <option value="Fairways">Fairways</option>
                                                    <option value="Tees">Tees</option>
                                                    <option value="Rough">Rough</option>
                                                </Select>
                                            </div>

                                            <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this Spray in your database
                                            </div>

                                            <div className="sm:col-span-full text-white mt-4 justify-center items-center text-center text-xs">MEASUREMENTS</div>

                                            {/*Area*/}
                                            <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Area
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="area"
                                                    name="area"
                                                    value={formData.area}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Unit*/}
                                            <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Area Unit
                                                </label>
                                                <Select
                                                    type="text"
                                                    id="target"
                                                    name="target"
                                                    value={formData.target}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select...</option>
                                                    <option value="sq. ft">sq. ft</option>
                                                    <option value="acres">acres</option>
                                                </Select>
                                            </div>

                                            {/*Rate*/}
                                            <div className="sm:col-span-full relative flex items-center mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Rate
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="rate"
                                                    name="rate"
                                                    value={formData.rate}
                                                    onChange={handleChange}
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                                <label className="block text-litegreen text-xs ml-2 text-center w-1/3"
                                                       htmlFor="rate">
                                                    Gal per Acre:
                                                </label>
                                            </div>

                                            <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>
                                                This is the data that the system uses to calculate your volume
                                            </div>

                                            <div className="sm:col-span-full flex flex-row items-center justify-between">
                                                <div className="w-1/3"></div>
                                                <div className="w-1/3 text-white text-center text-xs">CHEMICALS</div>
                                                <div className="w-1/3 flex justify-end">
                                                    <Button type="button" onClick={handleAddIngredient}
                                                            className="rounded-full text-center">
                                                        <PlusCircleIcon className={`w-6 hover:text-green-500`}/>
                                                    </Button>
                                                </div>
                                            </div>

                                            {/*Ingredients*/}
                                            <div className={`sm:col-span-full`}>
                                                {formData.ingredients.map((ingredient, index) => {
                                                    console.log('Ingredient:', ingredient); // Log the value of ingredient
                                                    return (
                                                        <div key={index}
                                                             className="items-center mt-2 mb-2 justify-start grid grid-cols-8 gap-x-2 gap-y-1">
                                                            <div className={`sm:col-span-7 relative`}>
                                                                <label
                                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                                >
                                                                    Chemical Name
                                                                </label>
                                                                <Select
                                                                    type="text"
                                                                    id={`ingredient-${index}`}
                                                                    name={`ingredient-${index}`}
                                                                    value={ingredient ? ingredient.name : ''}
                                                                    onChange={(e) => handleIngredientChange(index, "name", e)}
                                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                                >
                                                                    <option value="">Select...</option>
                                                                    {chems.map(chem => (
                                                                        <option key={chem.id}
                                                                                value={chem.name}>{chem.name}</option>
                                                                    ))}
                                                                </Select>

                                                            </div>

                                                            <div className={`sm:col-span-1 flex justify-end w-full`}>
                                                                <Button type="button"
                                                                        onClick={() => handleRemoveIngredients(index)}>
                                                                    <TrashIcon
                                                                        className={`w-6 text-red-500 hover:text-opacity-50`}/>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            <div className="sm:col-span-full text-white mt-4 justify-center items-center text-center text-xs">TANKS
                                            </div>

                                            {/*Tank Num*/}
                                            <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Area
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="tankNum"
                                                    name="tankNum"
                                                    value={formData.tankNum}
                                                    onChange={handleChange}
                                                    step="0.01"
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            {/*Volume*/}
                                            <div className="sm:col-span-3 relative flex mt-2 mb-2">
                                                <label
                                                    className={`absolute ${darkMode ? 'bg-bgDark' : 'bg-white'} -top-2 left-2 inline-block rounded-lg px-1 text-xs font-medium`}
                                                >
                                                    Tank Volume
                                                </label>
                                                <Input
                                                    type="number"
                                                    id="tankVol"
                                                    name="tankVol"
                                                    value={formData.tankVol}
                                                    onChange={handleChange}
                                                    step="0.01"
                                                    className={`block w-full ${darkMode ? 'bg-bgDark outline-neutral-700' : 'bg-white outline-gray-300'} rounded-md px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>

                                            <div className={`sm:col-span-full ${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>
                                                This is the data that the system uses to Calculate your per tank
                                                analysis
                                            </div>

                                            {/* Add more input fields as needed */}
                                            <div className="sm:col-span-full mb-3 flex w-full justify-center mt-4">
                                                {/* Add or Update Chem button */}
                                                <Button type="submit"
                                                        className="w-full bg-green-600 hover:bg-opacity-50 text-white tracking-widest py-1 px-4 rounded-md focus:outline-none focus:shadow-outline">
                                                    {addOrUpdateButtonText}
                                                </Button>
                                            </div>


                                        </form>


                                    </div>


                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>


        </div>
    );
};

export default Sprays;
