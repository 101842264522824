import {useDarkMode} from "../../contexts/darkModeContext";
import React from "react";
import {convertUnit} from "../GlobalFunctions";

const NutrientCard = ({nutrient, Vol, Weight, galVol, index, metric}) => {
    const percent = nutrient.percentage / 100;
    const { darkMode } = useDarkMode();

    // Function to handle dividing values less than 0.1 by 16 and converting to ounces if necessary
    // const handleDivision = (value) => {
    //     if (value < 0.1) {
    //         const ozValue = (value * 16).toFixed(2);
    //         return ozValue + " oz";
    //     } else {
    //         return value.toFixed(2) + " lbs";
    //     }
    // };

    const handleDivision = (value, metric) => {
        if (value < 0.1) {
            const ozValue = value * 16; // Convert lbs to oz
            return convertUnit(ozValue, "oz", metric).value + " " + convertUnit(ozValue, "oz", metric).unit;
        } else {
            return convertUnit(value, "lbs", metric).value + " " + convertUnit(value, "lbs", metric).unit;
        }
    };

    return (
        <div className={`flex space-x-2 items-center justify-center rounded-lg p-1.5 shadow-gray-900 mb-2 m-0.5`}>

            <div className={`${darkMode ? "" : "drop-shadow"} flex flex-row bg-green-600/15 border-2 border-green-600 items-center justify-center shadow-gray-900 rounded px-0.5 border-solid`}>
                <div className={`${darkMode ? "text-white" : "text-black"} text-xxs line-clamp-1`}>{index + 1}</div>
            </div>

            <div className={`${darkMode ? "" : "drop-shadow"} flex flex-row bg-green-600/15 border-2 border-green-600 items-center justify-center shadow-gray-900 rounded-md p-1 border-solid w-full`}>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{nutrient.name}</div>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1 ml-auto border-l-2 border-dotted border-gray-500 px-2`}>{nutrient.percentage}%</div>
            </div>

            <div className={`${darkMode ? "bg-bgcolor border border-neutral-700" : "bg-white drop-shadow border"} flex flex-col items-center justify-center p-1 shadow-gray-900 rounded-md w-full`}>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{handleDivision(percent * Weight, metric)} <span className={`text-green-600 text-xxs font-semibold`}>per gal.</span></div>
            </div>

            <div className={`${darkMode ? "bg-bgcolor border border-neutral-700" : "bg-white drop-shadow border"} flex flex-col items-center justify-center p-1 shadow-gray-900 rounded-md w-full`}>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{handleDivision(percent * Weight * galVol, metric)} <span className={`text-green-600 text-xxs font-semibold`}>per cont.</span></div>
            </div>

            <div className={`${darkMode ? "bg-bgcolor border border-neutral-700" : "bg-white drop-shadow border"} flex flex-col items-center justify-center p-1 shadow-gray-900 rounded-md w-full`}>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{handleDivision(percent * Weight * Vol, metric)} <span className={`text-green-600 text-xxs font-semibold`}>total</span></div>
            </div>
        </div>
    );
};

export default NutrientCard;
