import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/authContext';
import Dashboard from './SideBar';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { db } from '../firebase/firebase';
import "react-big-calendar/lib/css/react-big-calendar.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretUp,
    faEdit,
    faSquareCaretLeft,
    faSquareCaretRight, faTrash
} from "@fortawesome/free-solid-svg-icons";
import {useDarkMode} from "../contexts/darkModeContext";


const localizer = momentLocalizer(moment)


const MyCalendar = () => {
    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    const [sprays, setSprays] = useState([]);
    const [services, setServices] = useState([]);
    const [purchase, setPurchase] = useState([]);
    const [employee, setEmployee] = useState([]);

    const [outings, setOutings] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [off, setOff] = useState([]);
    const [holiday, setHoliday] = useState([]);


    const [events, setEvents] = useState([]);

    const [editingId, setEditingId] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        date: '',
        type: '',
        notes: '',
     });

    const fillFormWithEventData = (event) => {
        setFormData({
            name: event.name,
            date: event.date,
            type: event.type,
            notes: event.notes,
        });
        setEditingId(event.id);
    };

    const clearForm = () => {
        setFormData({
            name: '',
            date: '',
            type: '',
            notes: '',
        });
        setEditingId(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const deleteFromFirestore = async (eventID) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('events').doc(eventID).delete();
            console.log('Event item deleted successfully');
            // await onUpdate()
        } catch (error) {
            console.error('Error deleting event item:', error);
        }
    };

    const addOrUpdateButtonText = editingId ? 'Update Event' : 'Add Event';

    const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('events');

        // If date field is empty, set it to the current date
        const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');
        const formDataWithDate = {
            ...formData,
            date: formData.date || currentDate
        };

        try {
            if (editingId) {
                await collectionRef.doc(editingId).update(formDataWithDate);
                console.log('Event updated successfully!');
                // await onUpdate();
            } else {
                await collectionRef.add(formDataWithDate);
                console.log('Event added successfully!');
                // await onUpdate();
            }

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating Event: ', error);
        }
    };



    // Fetch events from Firebase
    useEffect(() => {
        const fetchSprays = async () => {
            if (currentUser) {
                try {
                    const spraysCollectionRef = db.collection('Users').doc(currentUser.uid).collection('sprays');
                    const snapshot = await spraysCollectionRef.get();

                    if (!snapshot.empty) {
                        const sprayEvents = snapshot.docs.map(doc => {
                            const sprayData = doc.data();
                            return {
                                title: sprayData.name + "",
                                start: new Date(sprayData.date),
                                end: new Date(sprayData.date), // Assuming event is just a point in time
                            };
                        });
                        setSprays(sprayEvents);
                        console.log("Fetched sprays:", sprayEvents); // Log fetched events
                    } else {
                        console.log('No sprays found');
                    }
                } catch (error) {
                    console.error('Error fetching sprays:', error);
                }
            }
        };

        const fetchEvents = async () => {
            if (currentUser) {
                try {
                    const CollectionRef = db.collection('Users').doc(currentUser.uid).collection('events');
                    const snapshot = await CollectionRef.get();

                    if (!snapshot.empty) {


                        const Outings = snapshot.docs.map(doc => {
                            const data = doc.data();
                            if (data.type === "Outing") {
                                return {
                                    title: data.name,
                                    start: new Date(data.date),
                                    end: new Date(data.date), // Assuming event is just a point in time
                                };
                            }
                            return null; // If event is not of type "outings", return null
                        }).filter(event => event !== null); // Filter out null values
                        setOutings(Outings);

                        const Delivery = snapshot.docs.map(doc => {
                            const data = doc.data();
                            if (data.type === "Delivery") {
                                return {
                                    title: data.name,
                                    start: new Date(data.date),
                                    end: new Date(data.date), // Assuming event is just a point in time
                                };
                            }
                            return null; // If event is not of type "outings", return null
                        }).filter(event => event !== null); // Filter out null values
                        setDelivery(Delivery);

                        const Off = snapshot.docs.map(doc => {
                            const data = doc.data();
                            if (data.type === "Day Off") {
                                return {
                                    title: data.name,
                                    start: new Date(data.date),
                                    end: new Date(data.date), // Assuming event is just a point in time
                                };
                            }
                            return null; // If event is not of type "outings", return null
                        }).filter(event => event !== null); // Filter out null values
                        setOff(Off);

                        const Holiday = snapshot.docs.map(doc => {
                            const data = doc.data();
                            if (data.type === "Holiday") {
                                return {
                                    title: data.name,
                                    start: new Date(data.date),
                                    end: new Date(data.date), // Assuming event is just a point in time
                                };
                            }
                            return null; // If event is not of type "outings", return null
                        }).filter(event => event !== null); // Filter out null values
                        setHoliday(Holiday);



                        const Data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setEvents(Data);



                        console.log("Fetched events:", Outings); // Log fetched events
                    } else {
                        console.log('No events found');
                    }
                } catch (error) {
                    console.error('Error fetching events:', error);
                }
            }
        };

        const fetchServices = async () => {
            try {
                const servicesCollectionRef = db.collection('Users').doc(currentUser.uid).collection('fleet');
                const snapshot = await servicesCollectionRef.get();

                if (!snapshot.empty) {
                    const purchase = snapshot.docs.map(doc => {
                        const machineData = doc.data();
                        return {
                            title: machineData.name + " Purchased! Cost:[$" + machineData.purchasePrice + "]",
                            start: new Date(machineData.purchaseDate),
                            end: new Date(machineData.purchaseDate), // Assuming event is just a point in time
                        };
                    });
                    setPurchase(purchase);

                    const serviceData = [];
                    snapshot.docs.forEach(doc => {
                        const machineData = doc.data();
                        const machineName = machineData.name; // Accessing the machine name
                        machineData.service.forEach(service => { // Iterate over each service
                            const eventTitle = `${machineName} [${service.serviceType}]`;
                            serviceData.push({
                                title: eventTitle,
                                start: new Date(service.serviceDate),
                                end: new Date(service.serviceDate),
                            });
                        });
                    });
                    setServices(serviceData);
                    console.log("Fetched services:", serviceData); // Log fetched events
                } else {
                    console.log('No services found');
                }
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };

        const fetchTeam = async () => {
            if (currentUser) {
                try {
                    const teamCollectionRef = db.collection('Users').doc(currentUser.uid).collection('team');
                    const snapshot = await teamCollectionRef.get();

                    if (!snapshot.empty) {
                        const employeeEvents = snapshot.docs.map(doc => {
                            const employeeData = doc.data();
                            return {
                                title: employeeData.name + " Hired!",
                                start: new Date(employeeData.startDate),
                                end: new Date(employeeData.startDate), // Assuming event is just a point in time
                            };
                        });
                        setEmployee(employeeEvents);
                        console.log("Fetched employees:", employeeEvents); // Log fetched events
                    } else {
                        console.log('No employees found');
                    }
                } catch (error) {
                    console.error('Error fetching employees:', error);
                }
            }
        };




        fetchSprays();
        fetchServices();
        fetchTeam();
        fetchEvents()
    }, [currentUser]);

    const allEvents = [
        ...sprays.map(event => ({ ...event, source: 'sprays' })),
        ...services.map(event => ({ ...event, source: 'services' })),
        ...purchase.map(event => ({ ...event, source: 'purchase' })),
        ...employee.map(event => ({ ...event, source: 'employee' })),

        ...outings.map(event => ({ ...event, source: 'outings' })),
        ...delivery.map(event => ({ ...event, source: 'delivery' })),
        ...off.map(event => ({ ...event, source: 'off' })),
        ...holiday.map(event => ({ ...event, source: 'holiday' })),

    ];

    console.log(allEvents);

    // eventStyleGetter function to assign different colors based on event source
    const eventPropGetter = event => {
        let backgroundColor;

        // Check the source of the event
        if (event.source === 'sprays') {
            backgroundColor = '#5C6784';
        } else if (event.source === 'services') {
            backgroundColor = '#147E1F';
        }else if (event.source === 'purchase') {
            backgroundColor = '#4BB862';
        }else if (event.source === 'employee') {
            backgroundColor = '#FFA000';
        }else if (event.source === 'outings') {
            backgroundColor = '#B91C1C';
        }else if (event.source === 'delivery') {
            backgroundColor = '#FFC300';
        }else if (event.source === 'off') {
            backgroundColor = '#008080';
        }else if (event.source === 'holiday') {
            backgroundColor = '#FF69B4';
        }else {
            backgroundColor = '#999999';
        }

        // Return an object with style properties
        return { style: { backgroundColor } };
    };


    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };



    return (
        <div className={`${darkMode ? "bg-bgpage" : "bg-bgcolorLight"} flex h-screen w-full mt-16`}>
            <Dashboard/>

            <div className="z-20 flex flex-col w-full">

                <div className="flex items-center justify-center ">


                    <div className="flex items-center justify-center w-full mr-2 ml-2 mt-2 mb-2">

                        <div className="w-full">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-xs p-2`}>Spray:</div>
                                <div className="text-md text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-bgcard shadow-inner shadow-gray-900">{sprays.length}</div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-xs p-2`}>Employee Start:</div>
                                <div className="text-md text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-bgOrange shadow-inner shadow-gray-900">{employee.length}</div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-xs p-2`}>Equip Purchase:</div>
                                <div className="text-md text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-litegreen shadow-inner shadow-gray-900">{purchase.length}</div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-xs p-2`}>Equip Service:</div>
                                <div className="text-md text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-darkgreen shadow-inner shadow-gray-900">{services.length}</div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-xs p-2`}>Outing:</div>
                                <div className="text-md text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-red-700 shadow-inner shadow-gray-900">{outings.length}</div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-xs p-2`}>Delivery:</div>
                                <div className="text-md text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-bgYellow shadow-inner shadow-gray-900">{delivery.length}</div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-xs p-2`}>Day Off:</div>
                                <div className="text-md text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-bgTeal shadow-inner shadow-gray-900">{off.length}</div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-xs p-2`}>Holiday:</div>
                                <div className="text-md text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-bgPink shadow-inner shadow-gray-900">{holiday.length}</div>
                            </div>
                        </div>

                        <button className={`${darkMode ? "text-white" : "text-gray-400"} text-3xl`}
                                onClick={toggleCollapse}>
                            {isCollapsed ? <FontAwesomeIcon icon={faSquareCaretLeft}/> :
                                <FontAwesomeIcon icon={faSquareCaretRight}/>}
                        </button>


                    </div>

                </div>


                <div className="relative h-full flex flex-row w-full">

                    <div className={`container pb-3 h-full ${isCollapsed ? 'w-full pr-3 mr-auto ml-auto' : 'w-3/4'}`}>
                        <div className={`bg-white items-center ${darkMode ? "shadow-inner" : "drop-shadow"} shadow-gray-900 justify-center rounded-lg p-4 h-full w-full overflow-y-auto max-h-[calc(100vh-8rem)] scrollbar-none`}>
                            <Calendar
                                localizer={localizer}
                                defaultDate={new Date()}
                                defaultView="month"
                                events={allEvents}
                                eventPropGetter={eventPropGetter}
                                className="text-gray-800 h-full"
                            />
                        </div>
                    </div>

                    <div className={`absolute top-0 right-0 container pr-3 pl-3 pb-3 h-full ${isCollapsed ? 'hidden' : 'w-1/4'}`}>


                        <div className="h-full flex flex-col mb-5">


                            <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-bgcolorGreen drop-shadow"} shadow-gray-900 rounded-lg p-2 mb-3 h-full`}>


                                <div className="text-litegreen justify-center items-center text-center text-lg">Add Event </div>

                                <form onSubmit={handleSubmit} className="mt-4 w-full">

                                    <div
                                        className="text-white justify-center items-center text-center text-xs">IDENTIFIERS
                                    </div>


                                    <div className="flex items-center justify-center mt-2 mb-2">
                                        <label className="block text-white text-xs mb-2 mr-5" htmlFor="name">
                                            Name:
                                        </label>

                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-2 leading-tight shadow-gray-900`}
                                        />
                                    </div>


                                    <div className="flex items-center justify-center mt-2 mb-2">
                                        <label className="block text-white text-xs mr-5" htmlFor="date">
                                            Date:
                                        </label>

                                        <input
                                            type="text"
                                            id="date"
                                            name="date"
                                            value={formData.date || currentDate}
                                            onChange={handleChange}
                                            className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-2 leading-tight shadow-gray-900`}
                                        />
                                    </div>


                                    <div className="flex items-center justify-start mt-2 mb-2">
                                        <label className="block text-white text-xs mr-2 w-1/2" htmlFor="type">
                                            Event Type:
                                        </label>

                                        <select
                                            id="type"
                                            name="type"
                                            value={formData.type}
                                            onChange={handleChange}
                                            className={`${darkMode ? "bg-bglite shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md py-1 px-2 w-full leading-tight shadow-gray-900`}
                                        >
                                            <option value="">Select...</option>

                                            <option value="Delivery">Delivery</option>
                                            <option value="Outing">Outing</option>
                                            <option value="Holiday">Holiday</option>
                                            <option value="Day Off">Day Off</option>


                                        </select>
                                    </div>

                                    <div className="text-darkgreen mt-2 font-bold justify-center items-center text-center text-xxs">
                                        This is the data that the system uses to identify this event in your database
                                    </div>


                                    <div className="text-white mt-2 justify-center items-center text-center text-xs">NOTES</div>

                                    <div className="flex items-center justify-center mt-2 mb-2">
                                        <label className="block text-white text-xs mr-5" htmlFor="notes">
                                            Notes:
                                        </label>

                                        <input
                                            type="text"
                                            id="notes"
                                            name="notes"
                                            value={formData.notes}
                                            onChange={handleChange}
                                            className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-2 leading-tight shadow-gray-900`}
                                        />
                                    </div>

                                    <div className="flex justify-center mt-4">
                                        <button type="button" onClick={clearForm} className="bg-red-700 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-md mr-2 focus:outline-none focus:shadow-outline">
                                            Clear
                                        </button>
                                        {/* Add or Update Chem button */}
                                        <button type="submit" className="bg-darkgreen hover:bg-litegreen text-white font-bold py-1 px-2 rounded-md focus:outline-none focus:shadow-outline">
                                            {addOrUpdateButtonText}
                                        </button>
                                    </div>

                                </form>


                            </div>


                            <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-bgcolorGreen drop-shadow"} rounded-lg shadow-gray-900 h-full`}>

                                <div className="mt-4 text-white justify-center items-center text-center text-lg">Event List</div>

                                <div className="w-full overflow-y-auto max-h-[calc(32vh-0rem)] scrollbar-none">

                                    {events.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex items-center justify-center h-full">
                                            <div className="bg-bgcolor p-4 rounded-md shadow-inner shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                <p className="text-sm text-center text-darkgreen">You Have No Events</p>
                                                <p className="text-sm text-litegreen mt-2">Adding an Event</p>

                                                <p className="text-xs text-white mt-2">1] Fill out the above panel on the right of the screen</p>
                                                <p className="text-xs text-white mt-1">2] Scroll down on the panel until you see the Add Event button</p>
                                                <p className="text-xs text-white mt-1">3] Press the Add Event button to add it to your Calendar</p>
                                                <p className="text-xs text-white mt-1">4] If your event does not automatically show up on the screen refresh your page and it should appear</p>

                                                <p className="text-sm text-litegreen mt-2">Network Issues</p>

                                                <p className="text-xs text-white mt-2">1] Make sure you are connected to the Internet</p>
                                            </div>
                                        </div>
                                    ) : (
                                        // Display EventCard components if event array is not empty
                                        events.map((event, index) => (
                                            <EventCard key={index} event={event}
                                                       onDelete={() => deleteFromFirestore(event.id)}
                                                       onEdit={fillFormWithEventData}/>
                                        ))
                                    )}


                                </div>

                            </div>


                        </div>


                    </div>

                    {/*<button*/}
                    {/*    className={`absolute top-0 right-0`}*/}
                    {/*    onClick={toggleCollapse}*/}
                    {/*>*/}
                    {/*    Collapse*/}
                    {/*</button>*/}

                </div>


            </div>


        </div>
    );
};


const EventCard = ({event, onDelete, onEdit}) => {
    const [expanded, setExpanded] = useState(false); // State to track expansion

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };
    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        onDelete(event.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    return (
        <div className="bg-bgcolor pr-2 pl-2 rounded-md items-center justify-center shadow-inner shadow-gray-900 m-2 w-full">


            {/* Deletion confirmation popup */}
            {showDeleteConfirmation && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-bgcolor p-2 rounded-lg">
                        <p className="text-white">Are you sure you want to delete this Event?</p>
                        <div className="flex mt-5">
                            <button className="w-full rounded-md bg-red-700 text-white p-2 mr-5"
                                    onClick={handleDelete}>Yes
                            </button>
                            <button className="w-full rounded-md bg-darkgreen text-white p-2"
                                    onClick={toggleDeleteConfirmation}>No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex items-center justify-center">

                <div className="mr-2">
                    <button className="text-md text-white font-bold" onClick={toggleDeleteConfirmation}>
                        <FontAwesomeIcon icon={faTrash} className="text-red-700 hover:text-red-500"/>
                    </button>
                </div>

                <div className="h-full rounded-md flex flex-col items-center justify-center p-1 w-full mr-2">
                    <div className="text-xs w-full text-litegreen line-clamp-1">{event.date}</div>
                    <div className="text-md text-white text-center line-clamp-1">{event.name}</div>
                    <div className="text-xs text-litegreen text-center line-clamp-1">{event.type}</div>
                </div>

                <div className="flex flex-col items-center">

                    <button onClick={() => onEdit(event)} className="text-md text-white px-2 mb-4 hover:text-litegreen">
                        <FontAwesomeIcon icon={faEdit}/>
                    </button>

                    <button className="text-lg text-white font-bold mt-auto mb-auto hover:text-litegreen"
                            onClick={toggleExpansion}>
                        {expanded ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>}
                    </button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <>

                    <div className="text-xs text-litegreen line-clamp-1 mb-1">Event Notes:</div>
                    <div className="text-xs text-white">{event.notes}</div>

                </>
            )}

        </div>
    );
};


export default MyCalendar;

